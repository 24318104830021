import React, { useCallback, useEffect, useState } from 'react'
import LoadingArea from 'components/elements/LoadingArea'
import { RouteComponentProps } from 'react-router-dom'
import { trackPromise } from 'react-promise-tracker'
import { ApiError } from 'services/ApiService'
import Box from 'components/elements/Box'
import Stack from 'components/elements/Stack'
import styled from 'styled-components'
import { Grade } from 'interfaces/Grade'
import GradeService from 'services/GradeService'
import { ReactComponent as BackIcon } from 'assets/icons/chefron-left.svg'
import routes from 'lib/constants/routes'
import { ButtonLink } from 'components/elements/Button'
import { ControlsWrapper, ControlWrapper } from 'style'
import Meta from 'components/modules/Head'

interface Props extends RouteComponentProps<{ id: string }> {}

const loadingAreas = {
    container: 'gradeContainer',
}

const Container = styled.section`
    padding-bottom: 25px;
`

const GradeComponent = (props: Props) => {
    const [grade, setGrade] = useState<Grade | null>(null)
    const id = props.match.params.id

    const fetchData = useCallback(() => {
        if (!id) return
        trackPromise(
            GradeService.get(+id)
                .then((grade) => {
                    setGrade(grade)
                })
                .catch((error: ApiError) => {
                    error.handleUnknown('An error occurred while getting grades.')
                }),
            loadingAreas.container
        )
    }, [id])

    useEffect(() => {
        fetchData()
    }, [fetchData])

    return (
        <Container>
            <Meta title={`${grade?.longName || ''} Grade`} />
            <h2>Grade</h2>
            <ControlsWrapper>
                <ControlWrapper>
                    <ButtonLink to={routes.gradesRoute} fullWidth={false} icon={<BackIcon />}>
                        Go back to Grades
                    </ButtonLink>
                </ControlWrapper>
            </ControlsWrapper>
            {grade && (
                <Box>
                    <Stack>
                        <h3>Information</h3>
                        <LoadingArea area={loadingAreas.container}>
                            {grade && (
                                <>
                                    <p>
                                        <strong>Id:</strong>&nbsp;
                                        {grade.id}
                                    </p>
                                    <p>
                                        <strong>Short Name:</strong>&nbsp;
                                        {grade.shortName}
                                    </p>
                                    <p>
                                        <strong>Long Name:</strong>&nbsp;
                                        {grade.longName}
                                    </p>
                                    <p>
                                        <strong>Level:</strong>&nbsp;
                                        {grade.level}
                                    </p>
                                    <p>
                                        <strong>Country:</strong>&nbsp;
                                        {grade.country.localizedName}
                                    </p>
                                </>
                            )}
                        </LoadingArea>
                    </Stack>
                </Box>
            )}
        </Container>
    )
}

export default GradeComponent
