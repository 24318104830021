import { defaultStepDataPerMarketObject } from 'utils/campaign'
import { EmailCategories } from './ManualEmails'

export interface Campaign {
    uuid: string
    name: string
    isActive: boolean
    description: string
    trigger: string | null
    experimentVariantIdentifier: string | null
    steps: CampaignStep[]
    triggerEventName: string | null
    userTargetingID: number | null
    triggeringFrequencyCapInDays: number | null
}

export interface CampaignRequest {
    name: string
    isActive: boolean
    description: string
    trigger: string | null
    experimentVariantIdentifier: string | null
    steps: CampaignStep[]
    uuid?: string
    triggerEventName: string | null
    userTargetingID: number | null
    triggeringFrequencyCapInDays: number | null
}

export type CampaignStep = {
    type: CampaignStepType
    delay?: CampaignDelayStep
    uuid?: string
    pushNotification?: CampaignPushNotificationStep
    email?: CampaignEmailStep
}

export enum CampaignStepType {
    PushNotification = 'PUSH_NOTIFICATION',
    Delay = 'DELAY',
    Email = 'EMAIL',
}

export type CampaignDelayStep = {
    durationInMinutes: number | null
    untilTime: string | null
    uuid?: string
}

export type CampaignPushNotificationStep = {
    title: Partial<typeof defaultStepDataPerMarketObject> | null
    body: Partial<typeof defaultStepDataPerMarketObject> | null
    clickPath: string
    trackingType: string
    uuid?: string
    thumbnailImageFilePath: Partial<typeof defaultStepDataPerMarketObject> | null
}

export type CampaignEmailStep = {
    bodyDesign: Partial<typeof defaultStepDataPerMarketObject> | null
    bodyHtml: Partial<typeof defaultStepDataPerMarketObject> | null
    category: EmailCategories
    fromEmail: string | null
    subject: Partial<typeof defaultStepDataPerMarketObject> | null
    trackingType: string
    placeholderFillerPrompt: string
    uuid?: string
}

export enum ContentLanguage {
    de = 'de',
    fr = 'fr',
    pl = 'pl',
    'en-GB' = 'en-GB',
    it = 'it',
    es = 'es',
    en = 'en',
    'es-CO' = 'es-CO',
    tr = 'tr',
}
