import React, { useEffect, useRef, useState } from 'react'
import { FormHandles, SubmitHandler } from '@unform/core'
import { Form } from '@unform/web'
import { trackPromise } from 'react-promise-tracker'
import { toast } from 'react-toastify'
import { ApiError } from 'services/ApiService'
import { ReactComponent as SaveIcon } from 'assets/icons/save.svg'
import { badRequestFormErrors, formatScreamingSnakeCase } from 'utils'
import { ErrorTypes } from 'utils/constants/ErrorTypes'
import { useHistory } from 'react-router-dom'
import Button from 'components/elements/Button'
import { Checkbox, FormSelect, Input, SelectOption } from 'components/inputs/Input'
import routes from 'lib/constants/routes'
import CampaignService from './CampaignService'
import { Headline4, Headline5 } from 'components/elements/Text'
import Box from 'components/elements/Box'
import theme from 'lib/constants/theme'
import { Campaign, CampaignRequest, CampaignStep } from 'interfaces/Campaings'
import CampaignStepsForm from './CampaignStepsForm'
import { getCampaignStepIdentification } from 'utils/campaign'
import { useUserTargetingForm } from 'utils/hooks/useUserTargetingForm'

interface CampaignFormData {
    name: string
    isActive: boolean
    description: string
    trigger: SelectOption<string | null>
    experimentVariantIdentifier: string | null
    triggerEventName: string | null
    triggeringFrequencyCapInDays: string | null
}

interface Props {
    campaignUuid: string
    isNew: boolean
    campaign: Campaign | null
    updateCampaign: (campaign: Campaign) => void
}

const loadingAreas = {
    save: 'saveCampaign',
}

const CampaignForm = ({ campaignUuid, isNew, campaign, updateCampaign }: Props) => {
    const [triggerOptions, setTriggerOptions] = useState<{ label: string; identifier: string; data: string | null }[]>()
    const { userTargetingId, renderTargetingModal, renderTargetingButton } = useUserTargetingForm(
        campaign?.userTargetingID ?? undefined,
        isNew
    )
    const [campaignSteps, setCampaignSteps] = useState<CampaignStep[]>(campaign?.steps ?? [])
    const campaignFormRef = useRef<FormHandles>(null)
    const history = useHistory()

    useEffect(() => {
        CampaignService.getTriggers()
            .then((triggers) => {
                const options = [
                    {
                        label: 'No trigger',
                        identifier: 'no_trigger',
                        data: null,
                    },
                    ...triggers.map((trigger) => ({
                        label: formatScreamingSnakeCase(trigger),
                        identifier: trigger,
                        data: trigger,
                    })),
                ]

                setTriggerOptions(options)
            })
            .catch((error) => {
                if (error instanceof ApiError) {
                    error.handleUnknown('Unable to list triggers.')
                    return
                } else {
                    throw error
                }
            })
    }, [])

    useEffect(() => {
        if (campaign?.steps?.length) setCampaignSteps(campaign.steps)
    }, [campaign])

    const initialData = campaign
        ? {
              ...campaign,

              trigger: campaign.trigger ? { identifier: campaign.trigger, data: campaign.trigger } : undefined,
          }
        : undefined

    const handleCreateCampaignSubmit: SubmitHandler<CampaignFormData> = (data) => {
        campaignFormRef.current!.setErrors({})

        const { trigger, triggerEventName, ...rest } = data

        if (!data.name) {
            campaignFormRef.current!.setFieldError('name', 'This field is required')
            return
        }

        if (!data.description) {
            campaignFormRef.current!.setFieldError('description', 'This field is required')
            return
        }

        if (!trigger.data && !data.triggerEventName) {
            campaignFormRef.current!.setFieldError('trigger', 'This field is required')
            return
        }

        const stepsWithUuids = campaignSteps.map((step) => {
            const stepUuid = getCampaignStepIdentification(step)

            if (!stepUuid || stepUuid?.startsWith('000000')) return step
            return {
                ...step,
                uuid: stepUuid,
            }
        })

        let body: CampaignRequest = {
            trigger: trigger.data,
            steps: campaignSteps,
            uuid: campaignUuid,
            triggerEventName: triggerEventName ? triggerEventName : null,
            ...rest,
            userTargetingID: userTargetingId,
            triggeringFrequencyCapInDays: data.triggeringFrequencyCapInDays
                ? Number.parseInt(data.triggeringFrequencyCapInDays)
                : null,
        }

        if (!isNew) {
            body = {
                ...body,
                steps: stepsWithUuids,
                uuid: campaignUuid,
            }
        }

        const handleFunction = isNew ? CampaignService.create(body) : CampaignService.update(campaignUuid, body)

        trackPromise(
            handleFunction
                .then((campaign) => {
                    toast.success(`Campaign has been ${isNew ? 'created' : 'updated'}`)
                    isNew && history.push(routes.campaignRoute(campaign.uuid))
                    updateCampaign(campaign)
                })
                .catch((error) => {
                    if (error instanceof ApiError) {
                        if (error.type === ErrorTypes.FormValidation) {
                            badRequestFormErrors(error, campaignFormRef.current!)
                        } else {
                            error.handleUnknown('An error occurred while changing campaign data.')
                        }
                    } else {
                        throw error
                    }
                }),
            loadingAreas.save
        )
    }

    return (
        <>
            <Form ref={campaignFormRef} onSubmit={handleCreateCampaignSubmit} initialData={initialData}>
                <br />
                <Headline4 color={theme.colors.knowunityBlue} marginBottom={20}>
                    Update form
                </Headline4>
                <Box>
                    <Headline5 color={theme.colors.white}>General info</Headline5>
                    <Input name="name" placeholder="Name" />
                    <Input name="description" placeholder="Description" />
                    {triggerOptions && <FormSelect name="trigger" options={triggerOptions} placeholder="Trigger" />}
                    <Input name="experimentVariantIdentifier" placeholder="Experiment Variant Identifier" />
                    <Input
                        name="triggerEventName"
                        placeholder="Trigger event name (you can only use trigger event name or generic trigger above)"
                    />
                    <Input
                        name="triggeringFrequencyCapInDays"
                        type="number"
                        placeholder="Triggering Frequency Cap (in days)"
                    />
                    {renderTargetingButton()}
                    <Checkbox name="isActive" placeholder="Is active" />
                </Box>

                <CampaignStepsForm
                    steps={campaignSteps}
                    setCampaignSteps={setCampaignSteps}
                    isNew={isNew}
                    campaignUuid={campaignUuid}
                />
                <Button fullWidth icon={<SaveIcon />} loadingArea={loadingAreas.save}>
                    {`${isNew ? 'Create' : 'Save'} Campaign`}
                </Button>
            </Form>
            {renderTargetingModal()}
        </>
    )
}

export default CampaignForm
