import React from 'react'
import Stack from 'components/elements/Stack'
import { Body2 } from 'components/elements/Text'
import { Country } from 'interfaces/Country'
import { countryToLanguageCode } from 'lib/country'
import { Region } from 'interfaces/Region'
import theme from 'lib/constants/theme'
import { useEffect, useState } from 'react'
import { trackPromise } from 'react-promise-tracker'
import { ApiError } from 'services/ApiService'
import RegionService from 'services/RegionService'
import styled from 'styled-components'

const SelectionCard = styled.div`
    width: 100%;
    cursor: pointer;
    border: 2px solid ${theme.colors.accentBlue};
    border-radius: ${theme.borderRadius.small};
    height: 50px;
    padding: 10px;

    & p {
        margin: 0;
    }

    &:hover {
        border: 2px solid ${theme.colors.knowunityBlue};
    }
`

interface Props {
    handleRegionSelect: (region: Region) => void
    selectedCountry: Country
}

const GradeSelectionRegion = ({ handleRegionSelect, selectedCountry }: Props) => {
    const [regions, setRegions] = useState<Region[]>([])

    useEffect(() => {
        const languageCode = countryToLanguageCode(selectedCountry.code)
        if (!selectedCountry?.code || !languageCode) return
        trackPromise(
            RegionService.listRegionsByCountry(selectedCountry.code, languageCode)
                .then((regions) => {
                    if (regions?.length === 1 && regions[0].isDefault) {
                        handleRegionSelect(regions[0])
                    }
                    setRegions(regions)
                })
                .catch((error) => {
                    if (error instanceof ApiError) {
                        error.handleUnknown('An error occurred while fetching the regions.')
                    } else {
                        throw error
                    }
                })
        )
    }, [handleRegionSelect, selectedCountry.code])

    return (
        <Stack>
            <Body2>{`Choose region`}</Body2>
            {regions.map((region) => (
                <SelectionCard key={region.id} onClick={() => handleRegionSelect(region)}>
                    <p>{region.regionName}</p>
                </SelectionCard>
            ))}
        </Stack>
    )
}

export default GradeSelectionRegion
