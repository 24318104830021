import { Campaign, CampaignRequest, CampaignStep } from 'interfaces/Campaings'
import ApiService from 'services/ApiService'

const CampaignService = {
    list(page: number, isActive?: boolean | null, search?: string | null) {
        return ApiService.get(
            `/campaigns?page=${page}${isActive !== null ? `&isActive=${isActive}` : ''}${search ? `&search=${search}` : ''}`
        ).then((response) => {
            return {
                competitions: response.data.content as Campaign[],
                page: response.data.number as number,
                totalPages: response.data.totalPages as number,
                totalElements: response.data.totalElements as number,
            }
        })
    },

    get(uuid: string) {
        return ApiService.get(`/campaigns/${uuid}`).then((response) => {
            return response.data as Campaign
        })
    },

    getCampaignStep(campaignUuid: string, stepUuid: string) {
        return ApiService.get(`/campaigns/${campaignUuid}/steps/${stepUuid}`).then((response) => {
            return response.data as CampaignStep
        })
    },

    create(data: CampaignRequest) {
        return ApiService.post(`/campaigns`, data).then((response) => {
            return response.data as Campaign
        })
    },

    update(uuid: string, data: CampaignRequest) {
        return ApiService.patch(`/campaigns/${uuid}`, data).then((response) => {
            return response.data as Campaign
        })
    },

    sendDraftStep(campaignUuid: string, stepUuid: string) {
        return ApiService.post(`/campaigns/${campaignUuid}/steps/${stepUuid}/trigger-draft`)
    },

    getTriggers() {
        return ApiService.get(`/campaigns/triggers`).then((response) => {
            return response.data.content as string[]
        })
    },
}

export default CampaignService
