import React, { useState } from 'react'
import Box from 'components/elements/Box'
import Row from 'components/elements/Row'
import Stack from 'components/elements/Stack'
import { Body1 } from 'components/elements/Text'
import InformationCard from 'components/features/InformationCard'
import { Input } from 'components/inputs/Input'
import InputElement from 'components/inputs/InputElement'
import { ContentLanguage } from 'interfaces/Campaings'
import theme from 'lib/constants/theme'
import Tabs from 'components/modules/Tabs'
import { ContentTypes } from 'interfaces/Know'
import ContentPreview from 'components/modules/ContentPreview/ContentPreview'
import UploadWrapper from 'components/FileUpload/UploadWrapper'
import { toast } from 'react-toastify'
import { trackPromise } from 'react-promise-tracker'
import { ApiError } from 'services/ApiService'
import FileUploadService from 'services/FileUploadService'
import { ContentPrefixes } from 'utils/constants/ContentPrefix'
import { ErrorTypes } from 'utils/constants/ErrorTypes'
import config from 'utils/config'

const loadingAreas = {
    imageUpload: 'thumbnailImageUpload',
}

interface Props {
    updateNotification: (key: 'title' | 'body' | 'thumbnail', language: ContentLanguage, value: string) => void
    getPushNotificationDataByLabel: (type: 'title' | 'body' | 'thumbnail', label: ContentLanguage) => string
}

const PushNotificationStepForm = ({ updateNotification, getPushNotificationDataByLabel }: Props) => {
    const [selectedLanguage, setSelectedLanguage] = useState<ContentLanguage>(ContentLanguage.de)

    const isNotificationBodyFilled = (language: ContentLanguage) => {
        const title = getPushNotificationDataByLabel('title', language)

        const body = getPushNotificationDataByLabel('body', language)

        return body && title
    }

    const onChangeFile = (event: React.ChangeEvent<HTMLInputElement>) => {
        const innerFile = event.target.files![0]
        if (!innerFile) {
            toast.error('You need to upload an asset image!')
            return
        }
        const maxFileSize = 25 * 1000000

        if (!allowedFileFormats.includes(innerFile.type)) {
            toast.error('Please upload your file as image or video!')
            return
        }

        if (innerFile.size > maxFileSize) {
            toast.error('Sorry, the file is too big. The maximum file size is 25 MB.')
            return
        }

        trackPromise(
            FileUploadService.upload(innerFile, ContentPrefixes.CampaignStepPushNotificationThumbnailImage)
                .then(({ url, filename }) => {
                    updateNotification('thumbnail', selectedLanguage, filename)
                })
                .catch((error) => {
                    if (error instanceof ApiError) {
                        if (error.type === ErrorTypes.InvalidMIMEType) {
                            toast.error('Please choose a different file format.')
                        } else {
                            error.handleUnknown('An error occurred while uploading the image')
                        }
                    } else {
                        throw error
                    }
                }),
            loadingAreas.imageUpload
        )
    }

    const allowedFileFormats = ['image/png', 'image/jpg', 'image/gif']

    return (
        <InformationCard title={<Body1 color={theme.colors.knowunityBlue}>Push Notification</Body1>}>
            <Tabs onLabelClicked={(language) => setSelectedLanguage(language as ContentLanguage)} deactivateUrlParam>
                {Object.values(ContentLanguage).map((language) => (
                    <div
                        key={language}
                        data-label={language}
                        data-title={`${language} ${isNotificationBodyFilled(language) ? '✔️' : ''}`}
                    />
                ))}
            </Tabs>
            <div className="notification-data">
                <Stack gutter={10}>
                    <Box>
                        <Body1 fontWeigth={400} color={theme.colors.knowunityBlue}>
                            Notification Title
                        </Body1>
                        <Stack gutter={0}>
                            {Object.keys(ContentLanguage).map((language, i) => (
                                <div key={i} hidden={selectedLanguage !== language}>
                                    <Row key={i} hidden={selectedLanguage !== language}>
                                        <Body1 color={theme.colors.white} style={{ width: 55 }}>{`${language}:`}</Body1>
                                        <InputElement
                                            label={''}
                                            noMargin
                                            defaultValue={getPushNotificationDataByLabel(
                                                'title',
                                                language as ContentLanguage
                                            )}
                                            onInput={(value) =>
                                                updateNotification('title', language as ContentLanguage, value)
                                            }
                                        />
                                    </Row>
                                </div>
                            ))}
                        </Stack>
                    </Box>
                </Stack>
                <Stack gutter={10}>
                    <Box>
                        <Body1 fontWeigth={400} color={theme.colors.knowunityBlue}>
                            Notification Body
                        </Body1>

                        {Object.keys(ContentLanguage).map((language, i) => (
                            <div key={i} hidden={selectedLanguage !== language}>
                                <Row key={i}>
                                    <Body1 color={theme.colors.white} style={{ width: 55 }}>{`${language}:`}</Body1>
                                    <InputElement
                                        label={''}
                                        noMargin
                                        defaultValue={getPushNotificationDataByLabel('body', language as ContentLanguage)}
                                        onInput={(value) => updateNotification('body', language as ContentLanguage, value)}
                                    />
                                </Row>
                            </div>
                        ))}
                    </Box>
                </Stack>
            </div>
            <Body1
                color={theme.colors.white}
                fontWeigth={400}
            >{`Thumbnail for language: ${selectedLanguage} (optional)`}</Body1>

            <UploadWrapper
                handleFileUpload={onChangeFile}
                area={loadingAreas.imageUpload}
                acceptRules={allowedFileFormats.join(', ')}
            >
                <p>Click here to select an image or video.</p>
                <ContentPreview
                    contentType={ContentTypes.image}
                    previewUrl={
                        `${config.getContentBaseUrl()}/${
                            ContentPrefixes.CampaignStepPushNotificationThumbnailImage
                        }/${getPushNotificationDataByLabel('thumbnail', selectedLanguage as ContentLanguage)
                            .split('/')
                            .pop()}` ?? ''
                    }
                />
            </UploadWrapper>

            <Input name="clickPath" placeholder="Click path" />
            <Input name="trackingType" placeholder="Tracking type" />
        </InformationCard>
    )
}

export default PushNotificationStepForm
