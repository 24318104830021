import React, { useCallback, useEffect, useState } from 'react'
import LoadingArea from 'components/elements/LoadingArea'
import { Link, RouteComponentProps, withRouter } from 'react-router-dom'
import { toast } from 'react-toastify'
import { trackPromise } from 'react-promise-tracker'
import { ApiError } from 'services/ApiService'
import { KnowDemand as KnowDemandInterface } from 'interfaces/KnowDemand'
import KnowDemandService from 'services/KnowDemandService'
import KnowDemandForm from './KnowDemandForm'
import Box from 'components/elements/Box'
import Stack from 'components/elements/Stack'
import routes from 'lib/constants/routes'
import { formatDate, formatDateTime } from 'utils'
import styled from 'styled-components'
import CollapsibleCard from 'components/features/CollapsibleCard'
import Meta from 'components/modules/Head'
import GoBackButton from 'components/elements/GoBackButton'

interface Props extends RouteComponentProps<{ id: string }> {}

const ReservationList = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
    @media (max-width: 750px) {
        grid-template-columns: 1fr;
    }
`

const loadingAreas = {
    container: 'knowDemandContainer',
}

const KnowDemandComponent = (props: Props) => {
    const [knowDemand, setKnowDemand] = useState<KnowDemandInterface | null>(null)
    const id = props.match.params.id

    const isNew = !id
    const fetchData = useCallback(() => {
        if (id) {
            trackPromise(
                KnowDemandService.get(id)
                    .then((knowDemand) => setKnowDemand(knowDemand))
                    .catch((error: ApiError) => {
                        error.handleUnknown('An error occurred while getting know demand.')
                    }),
                loadingAreas.container
            )
        }
    }, [id])

    useEffect(() => {
        fetchData()
    }, [fetchData])

    const handleSaved = (knowDemand: KnowDemandInterface) => {
        setKnowDemand(knowDemand)
        toast.success('Know demand has been saved')
        props.history.push(routes.knowDemandRoute(knowDemand.uuid))
    }

    return (
        <section>
            <Meta title="Know Demand" />
            <GoBackButton route={routes.knowDemandsRoute} routeName={'Know demands'} />
            <h2>Know Demand</h2>
            <br />
            {!isNew && (
                <Box>
                    <Stack>
                        <h3>Information</h3>
                        <LoadingArea area={loadingAreas.container}>
                            {knowDemand && (
                                <>
                                    <p>
                                        <strong>Title:</strong>&nbsp;
                                        {knowDemand.title}
                                    </p>
                                    <p>
                                        <strong>Description:</strong>&nbsp;
                                        {knowDemand.description}
                                    </p>
                                    <p>
                                        <strong>Country:</strong>&nbsp;
                                        {knowDemand.country.name}
                                    </p>
                                    <p>
                                        <strong>Subject:</strong>&nbsp;
                                        {knowDemand.subject.name}
                                    </p>
                                    <p>
                                        <strong>Grade:</strong>&nbsp;
                                        {knowDemand.grades.map((x) => x.longName ?? x.shortName)}
                                    </p>
                                    <p>
                                        <strong>School Type:</strong>&nbsp;
                                        {knowDemand.schoolType.name}
                                    </p>
                                    <p>
                                        <strong>Type:</strong>&nbsp;
                                        {knowDemand.knowType.name}
                                    </p>
                                    <p>
                                        <strong>Format:</strong>&nbsp;
                                        {knowDemand.format}
                                    </p>
                                    <p>
                                        <strong>Expires on:</strong>&nbsp;
                                        {knowDemand.expiresOn ? formatDate(knowDemand.expiresOn) : 'not set'}
                                    </p>
                                    <p>
                                        <strong>Payout tier:</strong>&nbsp;
                                        {knowDemand.payoutTier ?? 'none'}
                                    </p>
                                    <p>
                                        <strong>Reservation count:</strong>&nbsp;
                                        {`${knowDemand.reservationsCount}/${knowDemand.maxReservationsCount}`}
                                    </p>
                                    <h3>Reservations List</h3>
                                    <ReservationList>
                                        {knowDemand?.knowDemandReservations?.length
                                            ? knowDemand.knowDemandReservations.map((reservation, i) => (
                                                  <CollapsibleCard
                                                      title={`Reservation ${i + 1}`}
                                                      key={`Reservation ${i}`}
                                                      openByDefault={i === 0}
                                                  >
                                                      <p>
                                                          <strong>Knower:</strong>&nbsp;
                                                          {reservation.knowerUuid ? (
                                                              <Link to={routes.knowerRoute(reservation.knowerUuid)}>
                                                                  {reservation.knowerUuid}
                                                              </Link>
                                                          ) : (
                                                              'None'
                                                          )}
                                                      </p>
                                                      <p>
                                                          <strong>Know:</strong>&nbsp;
                                                          {reservation.knowUuid ? (
                                                              <Link to={routes.knowRoute(reservation.knowUuid)}>
                                                                  {reservation.knowUuid}
                                                              </Link>
                                                          ) : (
                                                              'None'
                                                          )}
                                                      </p>
                                                      <p>
                                                          <strong>Due date:</strong>&nbsp;
                                                          {reservation?.dueDate
                                                              ? formatDateTime(reservation.dueDate)
                                                              : 'No due date'}
                                                      </p>
                                                  </CollapsibleCard>
                                              ))
                                            : 'No reservations were made'}
                                    </ReservationList>
                                </>
                            )}
                        </LoadingArea>
                    </Stack>
                </Box>
            )}
            <KnowDemandForm knowDemand={knowDemand} isNew={isNew} onSave={handleSaved} />
        </section>
    )
}

export default withRouter(KnowDemandComponent)
