import React, { useCallback, useState } from 'react'
import Button, { ButtonLink } from 'components/elements/Button'
import Search from 'components/elements/Search'
import { ApiError } from 'services/ApiService'
import KnowDemandService from 'services/KnowDemandService'
import { ReactComponent as AddIcon } from 'assets/icons/add.svg'
import { trackPromise } from 'react-promise-tracker'
import RefactoredTable from 'components/modules/RefactoredTable'
import { FilterFormGrid } from 'style'
import SelectElement from 'components/inputs/SelectElement'
import { CountryCode } from 'interfaces/Country'
import Stack from 'components/elements/Stack'
import routes from 'lib/constants/routes'
import { useToggleState } from 'utils/hooks/useToggleState'
import KnowDemandsBatchUploadModal from './KnowDemandsBatchUpload'
import Meta from 'components/modules/Head'

const loadingAreas = {
    container: 'knowDemandsContainer',
}

const KnowDemands = () => {
    const [searchQuery, setSearchQuery] = useState<string>()
    const [country, setCountry] = useState<CountryCode | null>(null)
    const [refetch, setRefetch] = useState(false)
    const [showKnowDemandBatchModal, toggleKnowDemandBatchModal] = useToggleState(false)

    const fetchData = useCallback(
        (page: number) => {
            return trackPromise(
                KnowDemandService.list(page, country, searchQuery)
                    .then((data) => {
                        setRefetch(false)

                        if (page !== data.page) return
                        return {
                            totalPages: data.totalPages,
                            totalElements: data.totalElements,
                            elements: data.knowDemands.map((kd) => ({
                                id: kd.uuid,
                                columns: [
                                    kd.uuid,
                                    kd.title,
                                    kd.subject.name + ' (' + kd.language.name + ')',
                                    kd.knowType.name,
                                    kd.payoutTier ? 'Yes' : 'No',
                                    `${kd.reservationsCount}/${kd.maxReservationsCount}`,
                                ],
                                onClick: `/knowdemands/${kd.uuid}`,
                            })),
                        }
                    })
                    .catch((error) => {
                        if (error instanceof ApiError) {
                            error.handleUnknown('An error occurred while getting know demands.')
                        } else {
                            throw error
                        }
                    }),
                loadingAreas.container
            )
        },
        [searchQuery, country]
    )

    const countryOptions = [
        {
            label: 'No filter',
            data: null,
        },
        ...Object.keys(CountryCode).map((countryKey) => ({
            label: countryKey,
            // eslint-disable-next-line
            // @ts-ignore
            data: CountryCode[countryKey],
        })),
    ]

    const refetchData = () => setRefetch(true)

    const handleUpdateCountry = (value: CountryCode | null) => {
        setCountry(value)
    }

    return (
        <section>
            <Meta title="Know Demands" />
            <Stack>
                <h2>Know Demands</h2>
                <FilterFormGrid gridAmount={4}>
                    <ButtonLink to={routes.knowDemandCreateRoute} fullWidth icon={<AddIcon />} noMargin>
                        Create know demand
                    </ButtonLink>
                    <Button onClick={toggleKnowDemandBatchModal} fullWidth icon={<AddIcon />} noMargin>
                        Upload know demand batch
                    </Button>
                    <SelectElement label="Country" noMargin options={countryOptions} onUpdate={handleUpdateCountry} />
                    <Search onSearch={setSearchQuery} fullWidth label="⠀" />
                </FilterFormGrid>

                <RefactoredTable
                    columns={['#', 'Title', 'Subject', 'Type', 'Has Payout', 'Reservation Count']}
                    loadData={fetchData}
                    loadingArea={loadingAreas.container}
                    refetchKey={`${searchQuery}_${refetch}`}
                />
                <KnowDemandsBatchUploadModal
                    show={showKnowDemandBatchModal}
                    onClose={toggleKnowDemandBatchModal}
                    onSuccess={refetchData}
                />
            </Stack>
        </section>
    )
}

export default KnowDemands
