import React, { useCallback, useEffect, useState } from 'react'
import LoadingArea from 'components/elements/LoadingArea'
import { Link, RouteComponentProps, withRouter } from 'react-router-dom'
import { trackPromise } from 'react-promise-tracker'
import { ApiError } from 'services/ApiService'
import { formatDateTime, formatDateTimeUTC, formatTimeDifference, humanReadableTimeAgoLabel } from 'utils'
import Box from 'components/elements/Box'
import Stack from 'components/elements/Stack'
import CopyIcon from 'components/elements/CopyIcon'
import Tabs from 'components/modules/Tabs'
import { useSelector } from 'react-redux'
import { AppState } from 'redux/reducer'
import ManualChatMessagesService from 'services/ManualChatMessagesService'
import { ManualChatMessage } from 'interfaces/ManualChatMessage'
import ManualChatMessageForm from './ManualChatMessageForm'
import routes from 'lib/constants/routes'
import ManualChatMessageAction from './ManualChatMessageAction'
import ExplanationBanner from 'components/elements/ExplanationBanner'
import { useUserTargetingForm } from 'utils/hooks/useUserTargetingForm'
import ProgressBar from 'components/elements/ProgressBar'
import GoBackButton from 'components/elements/GoBackButton'
import Meta from 'components/modules/Head'
import { ManualCommmunicationStatus } from 'interfaces/ManualCommunicationPlaceholder'

interface Props extends RouteComponentProps<{ id: string }> {}

const loadingAreas = {
    container: 'manualChatMessageContainer',
    save: 'saveManualChatMessage',
}

const ManualChatMessageComponent = (props: Props) => {
    const [manualChatMessage, setManualChatMessage] = useState<ManualChatMessage | null>(null)
    const { renderTargetingInformation } = useUserTargetingForm(manualChatMessage?.userTargetingId)
    const authenticatedUser = useSelector((state: AppState) => state.user)

    const id = props.match.params.id
    const isNew = !id

    const fetchData = useCallback(() => {
        if (id) {
            trackPromise(
                ManualChatMessagesService.get(id)
                    .then((chatMessage) => {
                        setManualChatMessage(chatMessage)
                    })
                    .catch((error: ApiError) => {
                        error.handleUnknown('An error occurred while getting manual chat message.')
                    }),
                loadingAreas.container
            )
        }
    }, [id])

    useEffect(() => {
        fetchData()
    }, [fetchData])

    if (!authenticatedUser) return null

    const updateManualChatMessage = (chatMessage: ManualChatMessage) => setManualChatMessage(chatMessage)

    const isOwner = manualChatMessage && manualChatMessage.user.uuid === authenticatedUser?.uuid
    const isApproved = manualChatMessage && !!manualChatMessage.approvedOn && !!manualChatMessage.approvedByUser
    const canUpdate = isOwner
    const showProgress = manualChatMessage?.actualUsersCount && manualChatMessage.expectedUsersCount

    const manualChatMessagesWorkflowSteps = [
        {
            label: '1. Fill in all the relevant information',
            target: routes.manualChatMessageRoute(id, 'update'),
        },
        {
            label: '2. Ask someone from the team to approve it',
            target: routes.manualChatMessageRoute(id, 'actions'),
        },
        {
            label: '3. Send it out yourself',
            target: routes.manualChatMessageRoute(id, 'actions'),
        },
    ]

    return (
        <section>
            <Meta title="Manual Chat Message" />
            <h2>Manual Chat Message</h2>
            <GoBackButton route={routes.manualChatMessagesRoute} routeName="Manual Chat Messages" />
            {!isNew && manualChatMessage && (
                <>
                    <ExplanationBanner
                        title="How does Manual Chat Messages work?"
                        text="In order to issue a manual chat message following steps have to be taken beforehand:"
                        options={manualChatMessagesWorkflowSteps}
                    />
                    <Tabs>
                        <div data-label="General">
                            <Box>
                                <Stack>
                                    <h3>Information</h3>
                                    <LoadingArea>
                                        <p>
                                            <strong>Uuid:</strong>&nbsp;
                                            {manualChatMessage.uuid}
                                            <CopyIcon text={manualChatMessage.uuid} />
                                        </p>
                                        <p>
                                            <strong>Added by:</strong>&nbsp;
                                            <Link
                                                to={routes.userRoute(manualChatMessage.user.uuid)}
                                            >{`${manualChatMessage.user.name} (${manualChatMessage.user.uuid})`}</Link>
                                            <CopyIcon text={manualChatMessage.user.uuid} />
                                        </p>
                                        <p>
                                            <strong>Sender User:</strong>&nbsp;
                                            <Link
                                                to={routes.userRoute(manualChatMessage.senderUserUuid)}
                                            >{`${manualChatMessage.senderUserUuid}`}</Link>
                                            <CopyIcon text={manualChatMessage.senderUserUuid} />
                                        </p>
                                        <p>
                                            <strong>Created on:</strong>&nbsp;
                                            {manualChatMessage.createdOn
                                                ? formatDateTime(manualChatMessage.createdOn)
                                                : 'Not yet sent'}
                                        </p>
                                        <p>
                                            <strong>Scheduled on:</strong>&nbsp;
                                            {manualChatMessage.scheduledOn
                                                ? formatDateTimeUTC(manualChatMessage.scheduledOn)
                                                : 'Not scheduled'}
                                        </p>
                                        <p>
                                            <strong>Approved by:</strong>&nbsp;
                                            {manualChatMessage.approvedByUser ? (
                                                <>
                                                    {`${manualChatMessage.approvedByUser.name} (${manualChatMessage.approvedByUser.uuid})`}
                                                    <CopyIcon text={manualChatMessage.approvedByUser.uuid} />
                                                </>
                                            ) : (
                                                'Not yet approved'
                                            )}
                                        </p>
                                        <p>
                                            <strong>Approved on:</strong>&nbsp;
                                            {manualChatMessage.approvedOn
                                                ? formatDateTime(manualChatMessage.approvedOn)
                                                : 'Not yet approved'}
                                        </p>
                                        <p>
                                            <strong>Sent on:</strong>&nbsp;
                                            {manualChatMessage.sentOn
                                                ? formatDateTime(manualChatMessage.sentOn)
                                                : 'Not yet sent'}
                                        </p>
                                        <p>
                                            <strong>Language:</strong>&nbsp;
                                            {`${manualChatMessage.language.name} (${manualChatMessage.language.code})`}
                                        </p>
                                        <p>
                                            <strong>Message:</strong>&nbsp;
                                            {manualChatMessage.message}
                                        </p>
                                        <p>
                                            <strong>Attached know:</strong>&nbsp;
                                            {manualChatMessage.knowUuid ? (
                                                <Link to={routes.knowRoute(manualChatMessage.knowUuid)}>
                                                    {manualChatMessage.knowUuid}
                                                </Link>
                                            ) : (
                                                'none'
                                            )}
                                        </p>
                                        {showProgress ? (
                                            <p>
                                                <strong>{`Send Progress: (${manualChatMessage.status})`}</strong>&nbsp;
                                                <ProgressBar
                                                    max={manualChatMessage.expectedUsersCount!}
                                                    currentValue={manualChatMessage!.actualUsersCount!}
                                                />
                                                {manualChatMessage.status === ManualCommmunicationStatus.Processing &&
                                                manualChatMessage.lastProcessedOn ? (
                                                    <p>
                                                        <strong>{`Last processed on:`}</strong>&nbsp;
                                                        {formatTimeDifference(manualChatMessage.lastProcessedOn, true)}
                                                        <br />
                                                        {humanReadableTimeAgoLabel(manualChatMessage.lastProcessedOn)}
                                                    </p>
                                                ) : null}
                                            </p>
                                        ) : null}
                                    </LoadingArea>
                                </Stack>
                            </Box>
                            {renderTargetingInformation()}
                        </div>
                        <div data-label="Update">
                            {canUpdate ? (
                                <Box>
                                    {!isApproved ? (
                                        <ManualChatMessageForm
                                            chatMessage={manualChatMessage}
                                            isNew={isNew}
                                            updateManualChatMessage={updateManualChatMessage}
                                            senderUserUuid={authenticatedUser.uuid}
                                        />
                                    ) : (
                                        'It is not permitted to update manual chat messages that are already approved.'
                                    )}
                                </Box>
                            ) : (
                                <p>Only the owner is allowed to update it.</p>
                            )}
                        </div>
                        <div data-label="Actions">
                            <ManualChatMessageAction chatMessage={manualChatMessage} fetchData={fetchData} />
                        </div>
                    </Tabs>
                </>
            )}
            {isNew && (
                <ManualChatMessageForm
                    chatMessage={manualChatMessage}
                    isNew={isNew}
                    updateManualChatMessage={updateManualChatMessage}
                    senderUserUuid={authenticatedUser.uuid}
                />
            )}
        </section>
    )
}

export default withRouter(ManualChatMessageComponent)
