import React, { useCallback, useEffect, useState } from 'react'

import Box from 'components/elements/Box'
import CopyIcon from 'components/elements/CopyIcon'
import Stack from 'components/elements/Stack'
import routes from 'lib/constants/routes'
import { Link } from 'react-router-dom'
import { KnowDemand } from 'interfaces/KnowDemand'
import { ApiError } from 'services/ApiService'
import KnowDemandService from 'services/KnowDemandService'
import { ErrorTypes } from 'utils/constants/ErrorTypes'
import { trackPromise } from 'react-promise-tracker'
import { loadingAreas } from 'lib/constants/LoadingAreas'

interface KnowDemandProps {
    knowUuid: string
}

export const KnowDemandSection = ({ knowUuid }: KnowDemandProps) => {
    const [knowDemand, setKnowDemand] = useState<KnowDemand>()

    const getKnowDemand = useCallback(() => {
        trackPromise(
            KnowDemandService.getByKnow(knowUuid)
                .then((knowDemand) => {
                    setKnowDemand(knowDemand)
                })
                .catch((error: ApiError) => {
                    setKnowDemand(undefined)
                    if (error instanceof ApiError) {
                        if (error.type === ErrorTypes.NotFound) {
                            return
                        } else {
                            error.handleUnknown('An error occurred while getting know demand by know.')
                        }
                    } else {
                        throw error
                    }
                }),
            loadingAreas.knowDemand
        )
    }, [knowUuid])

    useEffect(() => {
        getKnowDemand()
    }, [getKnowDemand])

    if (!knowDemand) return <></>
    return (
        <Box style={{ marginTop: 10 }}>
            <Stack>
                <h3>Know Demand</h3>
                <p>
                    <strong>ID:</strong>&nbsp;
                    <Link to={routes.knowDemandRoute(knowDemand.uuid)}>{knowDemand.uuid}</Link>
                    <CopyIcon text={knowDemand.uuid} />
                </p>
                <p>
                    <strong>Title:</strong>&nbsp;
                    {knowDemand.title}
                </p>
                <p>
                    <strong>Description:</strong>&nbsp;
                    {knowDemand.description}
                </p>
                <p>
                    <strong>Grades:</strong>&nbsp;
                    {knowDemand.grades.map((x) => x.shortName).join(' ')}
                </p>
                <p>
                    <strong>Subject:</strong>&nbsp;
                    {knowDemand.subject.name}
                </p>
                <p style={{ color: 'var(--primary-color-dark)' }}>
                    <strong>Payout Tier:</strong>&nbsp;
                    {knowDemand.payoutTier || 'none'}
                </p>
                <p>
                    <strong>Format:</strong>&nbsp;
                    {knowDemand.format || 'none'}
                </p>
            </Stack>
        </Box>
    )
}
