import React, { useCallback, useEffect, useRef, useState } from 'react'
import { User } from 'interfaces/User'
import { trackPromise } from 'react-promise-tracker'
import { ApiError } from 'services/ApiService'
import { formatDateTime } from 'utils'
import UserService from 'services/UserService'
import { ReactComponent as SaveIcon } from 'assets/icons/save.svg'
import Table, { TableData } from 'components/modules/Table'
import Box from 'components/elements/Box'
import { useToggleState } from 'utils/hooks/useToggleState'
import Modal from 'components/modules/Modal'
import { FormHandles, SubmitHandler } from '@unform/core'
import { Form } from '@unform/web'
import { Input } from 'components/inputs/Input'
import Button from 'components/elements/Button'
import { toast } from 'react-toastify'
import { ReactComponent as AddIcon } from 'assets/icons/add.svg'
import { Body1 } from 'components/elements/Text'
import theme from 'lib/constants/theme'
import Stack from 'components/elements/Stack'
import styled from 'styled-components'
import { ErrorTypes } from 'utils/constants/ErrorTypes'

const loadingAreas = {
    adminNotes: 'userAdminNotes',
    add: 'addAdminNote',
}

const TitleRow = styled.div`
    display: flex;
    justify-content: space-between;
`

type FormData = {
    comment: string
}

const userAdminNotesColumns = ['Date', 'Employee', 'Employee comment / Reason']

interface Props {
    user: User
}

const UserAdminNotes = ({ user }: Props) => {
    const [userAdminNotes, setUserAdminNotes] = useState<TableData[]>([])
    const [wasUserNotFound, setWasUserNotFound] = useState(false)
    const [showAddAdminNoteModal, toggleAddAdminNoteModal] = useToggleState(false)
    const formRef = useRef<FormHandles>(null)

    const userUuid = user.uuid

    const fetchData = useCallback(() => {
        trackPromise(
            UserService.getAdminNotes(userUuid)
                .then((adminNotes) => {
                    const notes = adminNotes.map((history, id) => ({
                        id,
                        columns: [formatDateTime(history.createdOn), history.employeeUser.name, history.employeeComment],
                    }))

                    setUserAdminNotes(notes)
                })
                .catch((error: ApiError) => {
                    if (error instanceof ApiError) {
                        if (error.type === ErrorTypes.NotFound) {
                            setWasUserNotFound(true)
                        } else {
                            error.handleUnknown('An error occurred while saving know demand.')
                        }
                    } else {
                        throw error
                    }
                }),
            loadingAreas.adminNotes
        )
    }, [userUuid])

    useEffect(() => {
        fetchData()
    }, [fetchData])

    const handleSubmit: SubmitHandler<FormData> = async (data) => {
        formRef.current!.setErrors({})

        trackPromise(
            UserService.addAdminNotes(userUuid, data.comment)
                .then(() => {
                    toast.success('An admin note was added to the user')
                    toggleAddAdminNoteModal()
                    fetchData()
                })
                .catch((error: ApiError) => {
                    if (error instanceof ApiError) {
                        if (error.type === ErrorTypes.NotFound) {
                            setWasUserNotFound(true)
                        } else {
                            error.handleUnknown('An error occurred while saving know demand.')
                        }
                    } else {
                        throw error
                    }
                }),
            loadingAreas.add
        )
    }

    return (
        <Box>
            <Stack>
                <TitleRow>
                    <h3>User admin notes</h3>
                    <Button fullWidth={false} icon={<AddIcon />} padding="10px" onClick={toggleAddAdminNoteModal}>
                        Add Admin Note
                    </Button>
                </TitleRow>

                {userAdminNotes?.length > 0 ? (
                    <Table
                        columns={userAdminNotesColumns}
                        data={userAdminNotes}
                        page={0}
                        totalPages={1}
                        totalElements={userAdminNotes.length}
                    />
                ) : (
                    <Body1 color={theme.colors.white} textAlign="center" marginBottom={10}>
                        {wasUserNotFound ? 'User was not found' : 'The are no notes for this user'}
                    </Body1>
                )}
                <Modal show={showAddAdminNoteModal} title="Add admin note" onClose={toggleAddAdminNoteModal}>
                    <Form ref={formRef} onSubmit={handleSubmit}>
                        <Input name="comment" placeholder="Note text" />
                        <Button fullWidth icon={<SaveIcon />} loadingArea={loadingAreas.add}>
                            Update statuses
                        </Button>
                    </Form>
                </Modal>
            </Stack>
        </Box>
    )
}

export default UserAdminNotes
