import React from 'react'
import { CampaignStep, CampaignStepType, ContentLanguage } from 'interfaces/Campaings'
import Stack from 'components/elements/Stack'
import { Body2 } from 'components/elements/Text'
import theme from 'lib/constants/theme'
import CopyIcon from 'components/elements/CopyIcon'

export const defaultStepDataPerMarketObject = {
    [`${ContentLanguage.de}`]: '',
    [`${ContentLanguage['en-GB']}`]: '',
    [`${ContentLanguage.fr}`]: '',
    [`${ContentLanguage.pl}`]: '',
    [`${ContentLanguage.es}`]: '',
    [`${ContentLanguage.it}`]: '',
    [`${ContentLanguage.en}`]: '',
}

export const getCampaignStepIdentification = (step: CampaignStep) => {
    switch (step.type) {
        case CampaignStepType.Delay:
            return step.delay?.uuid
        case CampaignStepType.PushNotification:
            return step.pushNotification?.uuid
        case CampaignStepType.Email:
            return step.email?.uuid
        default:
            return undefined
    }
}

function isEmptyObject(obj: unknown) {
    return JSON.stringify(obj) === '{}'
}

export const renderCampaignStep = (step: CampaignStep) => {
    return (
        <Stack gutter={10}>
            {step.type === CampaignStepType.PushNotification ? (
                <div>
                    {step.pushNotification?.uuid ? (
                        <Body2 fontWeigth={400} color={theme.colors.white} marginBottom={10}>
                            {`Step Uuid: ${step.pushNotification?.uuid}`}{' '}
                            <CopyIcon text={step.pushNotification?.uuid ?? ''} />
                        </Body2>
                    ) : null}
                    <Body2 fontWeigth={400} color={theme.colors.white}>{`Click path: ${
                        step.pushNotification?.clickPath ?? ''
                    }`}</Body2>
                    <Body2 fontWeigth={400} color={theme.colors.white}>{`Tracking type: ${
                        step.pushNotification?.trackingType ?? ''
                    }`}</Body2>
                    <Body2 fontWeigth={400} color={theme.colors.knowunityBlue} marginBottom={10}>
                        Title
                    </Body2>
                    <Stack gutter={0}>
                        {step.pushNotification?.title && !isEmptyObject(step.pushNotification?.title)
                            ? Object.entries(step.pushNotification.title).map(([k, v]) => (
                                  <Body2 key={`title-${k}`} fontWeigth={400} color={theme.colors.white}>
                                      {`${k}: ${v}`}
                                  </Body2>
                              ))
                            : ''}
                    </Stack>
                    <Body2 fontWeigth={400} color={theme.colors.knowunityBlue} marginBottom={10}>
                        Body
                    </Body2>
                    <Stack gutter={0}>
                        {step.pushNotification?.body && !isEmptyObject(step.pushNotification?.body)
                            ? Object.entries(step.pushNotification.body).map(([k, v]) => (
                                  <Body2 key={`title-${k}`} fontWeigth={400} color={theme.colors.white}>
                                      {`${k}: ${v}`}
                                  </Body2>
                              ))
                            : ''}
                    </Stack>
                </div>
            ) : null}
            {step.type === CampaignStepType.Delay ? (
                <>
                    {step.delay?.uuid ? (
                        <Body2 fontWeigth={400} color={theme.colors.white} marginBottom={10}>
                            {`Step Uuid: ${step.delay?.uuid}`} <CopyIcon text={step.delay?.uuid ?? ''} />
                        </Body2>
                    ) : null}
                    {step.delay?.untilTime ? (
                        <Body2 fontWeigth={400} color={theme.colors.white}>{`Wait until: ${step.delay.untilTime.slice(
                            11,
                            16
                        )}`}</Body2>
                    ) : null}

                    {step.delay?.durationInMinutes ? (
                        <Body2 fontWeigth={400} color={theme.colors.white}>{`Duration in minutes: ${
                            step.delay?.durationInMinutes ?? 0
                        }`}</Body2>
                    ) : null}
                </>
            ) : null}
            {step.type === CampaignStepType.Email ? (
                <div>
                    {step.email?.uuid ? (
                        <Body2 fontWeigth={400} color={theme.colors.white} marginBottom={10}>
                            {`Step Uuid: ${step.email?.uuid}`} <CopyIcon text={step.email?.uuid ?? ''} />
                        </Body2>
                    ) : null}
                    <Body2 fontWeigth={400} color={theme.colors.white}>{`Category: ${step.email?.category}`}</Body2>
                    <Body2 fontWeigth={400} color={theme.colors.white}>{`Tracking type: ${
                        step.email?.trackingType ?? ''
                    }`}</Body2>
                    {step.email?.fromEmail ? (
                        <Body2 fontWeigth={400} color={theme.colors.white}>{`From email: ${step.email.fromEmail}`}</Body2>
                    ) : null}
                    <Body2 fontWeigth={400} color={theme.colors.knowunityBlue} marginBottom={10}>
                        Email Subject
                    </Body2>
                    <Stack gutter={0}>
                        {step.email?.subject && !isEmptyObject(step.email.subject)
                            ? Object.entries(step.email.subject).map(([k, v]) => (
                                  <Body2 key={`title-${k}`} fontWeigth={400} color={theme.colors.white}>
                                      {`${k}: ${v}`}
                                  </Body2>
                              ))
                            : ''}
                    </Stack>
                </div>
            ) : null}
        </Stack>
    )
}
