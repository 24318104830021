import { Country } from './Country'
import { Grade } from './Grade'
import { LanguageInterface } from './Language'
import { SchoolType } from './SchoolType'

export enum KnowDemandsStatus {
    NotProcessedYet = 'NOT_PROCESSED_YET',
    SearchPerformanceIssue = 'SEARCH_PERFORMANCE_ISSUE',
    Typo = 'TYPO',
    VeryGeneric = 'VERY_GENERIC',
    KnowerName = 'KNOWER_NAME',
    Duplicate = 'DUPLICATE',
    GenericTopic = 'GENERIC_TOPIC',

    SupplyIssue = 'SUPPLY_ISSUE',
    KnowDemandCreated = 'KNOW_DEMAND_CREATED',
    Review = 'REVIEW',
    Done = 'DONE',
}

export enum EditableKnowDemandsStatus {
    NotProcessedYet = 'NOT_PROCESSED_YET',
    SearchPerformanceIssue = 'SEARCH_PERFORMANCE_ISSUE',
    Typo = 'TYPO',
    VeryGeneric = 'VERY_GENERIC',
    KnowerName = 'KNOWER_NAME',
    SupplyIssue = 'SUPPLY_ISSUE',
    Duplicate = 'DUPLICATE',
    GenericTopic = 'GENERIC_TOPIC',
}

export interface AutoKnowDemand {
    id: number
    country: Country
    grade: Grade
    lastStatusUpdatedOn: string
    schoolType: SchoolType
    searchCount: number
    searchFillRate: number
    status: KnowDemandsStatus
    term: string
    userContentLanguage: LanguageInterface
}

export interface AutoKnowDemandBasicData {
    id: number
    status: KnowDemandsStatus
}
