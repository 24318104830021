import ApiService from 'services/ApiService'
import { CreateKnowDemand, KnowDemand } from 'interfaces/KnowDemand'
import { CountryCode } from 'interfaces/Country'

const KnowDemandService = {
    list(page: number, countryCode?: CountryCode | null, search?: string | null) {
        let url = `knowdemands?page=${page}`
        if (countryCode) {
            url += `&countryCode=${countryCode}`
        }
        if (search) {
            url += `&title=${search}`
        }
        return ApiService.get(url).then((response) => {
            return {
                knowDemands: response.data.content as KnowDemand[],
                page: response.data.number as number,
                totalPages: response.data.totalPages as number,
                totalElements: response.data.totalElements as number,
            }
        })
    },

    create(newKnowDemand: CreateKnowDemand) {
        return ApiService.post('knowdemands', newKnowDemand).then((response) => {
            return response.data as KnowDemand
        })
    },

    createBatch(batch: CreateKnowDemand[]) {
        return ApiService.post('knowdemands/batch', batch).then((response) => {
            return {
                knowDemands: response.data.content as KnowDemand[],
                page: response.data.number as number,
                totalPages: response.data.totalPages as number,
                totalElements: response.data.totalElements as number,
            }
        })
    },

    get(uuid: string) {
        return ApiService.get(`knowdemands/${uuid}/admin`).then((response) => {
            return response.data as KnowDemand
        })
    },

    getByKnow(uuid: string) {
        return ApiService.get(`knows/${uuid}/knowdemand`).then((response) => {
            return response.data as KnowDemand
        })
    },

    update(uuid: string, knowDemand: CreateKnowDemand) {
        return ApiService.patch(`knowdemands/${uuid}`, knowDemand).then((response) => {
            return response.data as KnowDemand
        })
    },
}

export default KnowDemandService
