import React, { useCallback, useEffect, useState } from 'react'
import LoadingArea from 'components/elements/LoadingArea'
import { RouteComponentProps } from 'react-router-dom'
import { trackPromise } from 'react-promise-tracker'
import { ApiError } from 'services/ApiService'
import Box from 'components/elements/Box'
import Stack from 'components/elements/Stack'
import styled from 'styled-components'
import GoBackButton from 'components/elements/GoBackButton'
import routes from 'lib/constants/routes'
import { SeoSubTopic } from 'interfaces/SeoSubTopic'
import { EditButton, MetaBox } from 'style'
import { useToggleState } from 'utils/hooks/useToggleState'
import Modal from 'components/modules/Modal'
import CopyIcon from 'components/elements/CopyIcon'
import HtmlContentPreview from '../HtmlContentPreview'
import SeoSubTopicService from './SeoSubTopicService'
import SeoSubTopicForm from './SeoSubTopicForm'
import Meta from 'components/modules/Head'

interface Props extends RouteComponentProps<{ uuid: string; subjectUuid: string; topicUuid: string }> {
    seoSubjectUuid: string
}

const loadingAreas = {
    container: 'subjectContainer',
}

const Container = styled.section`
    padding-bottom: 25px;
`

const SeoSubTopicComponent = (props: Props) => {
    const [seoSubtopic, setSeoSubTopic] = useState<SeoSubTopic | null>(null)
    const [showEditForm, toggleEditForm] = useToggleState(false)
    const { uuid, subjectUuid, topicUuid } = props.match.params

    const isNew = !uuid || uuid === 'create'
    const updateSeoSubTopic = (seoSubtopic: SeoSubTopic) => setSeoSubTopic(seoSubtopic)

    const handleFormUpdate = (seoSubtopic: SeoSubTopic) => {
        updateSeoSubTopic(seoSubtopic)
        showEditForm && toggleEditForm()
    }

    const fetchData = useCallback(() => {
        if (!uuid || isNew) return

        trackPromise(
            SeoSubTopicService.get(uuid)
                .then((seoSubtopic) => {
                    updateSeoSubTopic(seoSubtopic)
                })
                .catch((error: ApiError) => {
                    error.handleUnknown('An error occurred while getting subtopic.')
                }),
            loadingAreas.container
        )
    }, [uuid, isNew])

    useEffect(() => {
        fetchData()
    }, [fetchData])

    return (
        <Container>
            <Meta title="SEO Subtopic" />
            <h2>SEO Subtopic</h2>
            <GoBackButton route={routes.seoTopicRoute(subjectUuid, topicUuid)} routeName="SEO Topic" />
            {!isNew ? (
                <>
                    <Box>
                        <Stack>
                            <MetaBox>
                                <h3>Information</h3>
                                <EditButton onClick={toggleEditForm} />
                            </MetaBox>
                            <LoadingArea area={loadingAreas.container}>
                                {seoSubtopic && (
                                    <>
                                        <p>
                                            <strong>Uuid:</strong>&nbsp;
                                            {seoSubtopic.uuid}
                                            <CopyIcon text={seoSubtopic.uuid} />
                                        </p>
                                        <p>
                                            <strong>Slug:</strong>&nbsp;
                                            {seoSubtopic.slug}
                                        </p>
                                        <p>
                                            <strong>Is published:</strong>&nbsp;
                                            {seoSubtopic.isPublished ? 'Yes' : 'No'}
                                        </p>
                                        <p>
                                            <strong>Meta Title:</strong>&nbsp;
                                            {seoSubtopic.title}
                                        </p>
                                        <p>
                                            <strong>Keywords:</strong>&nbsp;
                                            {seoSubtopic.description}
                                        </p>
                                        <p>
                                            <strong>Link to live version:</strong>&nbsp;
                                            <a
                                                href={routes.seoSubtopicLiveRoute(
                                                    seoSubtopic.seoTopic.seoSubject.country.code,
                                                    seoSubtopic.seoTopic.seoSubject.slug,
                                                    seoSubtopic.seoTopic.slug,
                                                    seoSubtopic.slug
                                                )}
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                {routes.seoSubtopicLiveRoute(
                                                    seoSubtopic.seoTopic.seoSubject.country.code,
                                                    seoSubtopic.seoTopic.seoSubject.slug,
                                                    seoSubtopic.seoTopic.slug,
                                                    seoSubtopic.slug
                                                )}
                                            </a>
                                            &nbsp;
                                        </p>
                                    </>
                                )}
                            </LoadingArea>
                        </Stack>
                        <Modal show={showEditForm} title="Edit SEO Subtopic" onClose={toggleEditForm}>
                            <SeoSubTopicForm
                                seoSubTopic={seoSubtopic}
                                isNew={false}
                                updateSeoSubTopic={handleFormUpdate}
                                seoTopicUuid={topicUuid}
                                seoSubjectUuid={subjectUuid}
                            />
                        </Modal>
                    </Box>
                    {seoSubtopic ? (
                        <Box>
                            <Stack>
                                <h3>Html Content</h3>
                                <HtmlContentPreview htmlContent={seoSubtopic.contentHtml} showDisplay />
                            </Stack>
                        </Box>
                    ) : null}
                </>
            ) : (
                <SeoSubTopicForm
                    seoSubTopic={seoSubtopic}
                    isNew={isNew}
                    updateSeoSubTopic={updateSeoSubTopic}
                    seoTopicUuid={topicUuid}
                    seoSubjectUuid={subjectUuid}
                />
            )}
        </Container>
    )
}

export default SeoSubTopicComponent
