import React, { useEffect, useRef, useState } from 'react'
import { FormHandles, SubmitHandler } from '@unform/core'
import { Form } from '@unform/web'
import { trackPromise } from 'react-promise-tracker'
import { toast } from 'react-toastify'
import { ApiError } from 'services/ApiService'
import { ReactComponent as SaveIcon } from 'assets/icons/save.svg'
import { badRequestFormErrors, formatUTCDateForRequest } from 'utils'
import { ErrorTypes } from 'utils/constants/ErrorTypes'
import { useHistory } from 'react-router-dom'
import Button from 'components/elements/Button'
import { Input, Checkbox, FormSelect } from 'components/inputs/Input'
import routes from 'lib/constants/routes'
import { useUserTargetingForm } from 'utils/hooks/useUserTargetingForm'
import { SelectOption } from 'components/inputs/Input'
import CompetitionService from './CompetitionService'
import {
    Competition,
    CompetitionContentType,
    CompetitionFAQ,
    CompetitionPrize,
    CompetitionRequest,
    CompetitionType,
} from 'interfaces/Competition'
import { Headline4, Headline5 } from 'components/elements/Text'
import Box from 'components/elements/Box'
import theme from 'lib/constants/theme'
import CountriesService from 'services/CountriesService'
import CompetitionFAQForm from './CompetitionFAQForm'
import CompetitionPrizeForm from './CompetitionPrizeForm'

interface CompetitionFormData {
    name: string
    customKey: string | null
    startsOn: string
    endsOn: string
    visibleFrom: string
    visibleUntil: string
    countryId: SelectOption<number>
    contentType: SelectOption<CompetitionContentType> | null
    type: SelectOption<CompetitionType>
    unit: string

    // Details Page
    detailsPageHeading: string
    detailsPageText: string | null
    detailsPageTextHeading: string | null
    detailsPageButtonText: string
    detailsPageButtonLink: string

    // Preview Card
    previewCardHeading: string | null
    previewCardText: string | null
    previewCardButtonText: string
    previewCardLink: string

    // Checkboxes
    enableLeaderboard: boolean
    showContentInHomeScreen: boolean
    showTop3: boolean
    weeklyReset: boolean
}

interface Props {
    competitionUuid: string
    isNew: boolean
    competition: Competition | null
    updateCompetition: (competition: Competition) => void
}

const loadingAreas = {
    save: 'saveCompetition',
}

const CompetitionForm = ({ competitionUuid, isNew, competition, updateCompetition }: Props) => {
    const {
        userTargetingId: targeting,
        renderTargetingModal,
        renderTargetingButton,
    } = useUserTargetingForm(competition?.userTargetingId ?? undefined, isNew)
    const competitionFormRef = useRef<FormHandles>(null)
    const [competitionFAQs, setCompetitionFAQs] = useState<CompetitionFAQ[]>(competition?.faqs ?? [])
    const [competitionPrizes, setCompetitionPrize] = useState<CompetitionPrize[]>(competition?.prizes ?? [])
    const [countryOptions, setCountryOptions] = useState<{ label: string; identifier: string; data: number }[]>([])
    const history = useHistory()

    useEffect(() => {
        CountriesService.list()
            .then((countries) => {
                if (!countries) return
                setCountryOptions(countries.map((c) => ({ label: c.name, identifier: c.id.toString(), data: c.id })))
            })
            .catch((error) => {
                if (error instanceof ApiError) {
                    error.handleUnknown('Unable to list countries.')
                    return
                } else {
                    throw error
                }
            })
    }, [])

    useEffect(() => {
        if (competition?.faqs) setCompetitionFAQs(competition.faqs)
        if (competition?.prizes) setCompetitionPrize(competition.prizes)
    }, [competition])

    const initialData: CompetitionFormData | undefined = competition
        ? {
              name: competition.name,
              customKey: competition.customKey,
              startsOn: competition.startsOn.slice(0, -1),
              endsOn: competition.endsOn.slice(0, -1),
              visibleFrom: competition.visibleFrom.slice(0, -1),
              visibleUntil: competition.visibleUntil.slice(0, -1),
              countryId: { data: competition.countryId, identifier: competition.countryId.toString() },
              contentType: competition?.contentType
                  ? { data: competition.contentType, identifier: competition.contentType }
                  : null,
              type: { data: competition.type, identifier: competition.type },
              unit: competition?.unit,

              // Details Page
              detailsPageHeading: competition.detailsPageHeading,
              detailsPageText: competition.detailsPageText,
              detailsPageTextHeading: competition.detailsPageTextHeading,
              detailsPageButtonText: competition.detailsPageButtonText,
              detailsPageButtonLink: competition.detailsPageButtonLink,

              // Preview Card
              previewCardHeading: competition.previewCardHeading,
              previewCardText: competition.previewCardText,
              previewCardButtonText: competition.previewCardButtonText,
              previewCardLink: competition.previewCardLink,

              // Checkboxes
              enableLeaderboard: competition.enableLeaderboard,
              showContentInHomeScreen: competition.showContentInHomeScreen,
              showTop3: competition.showTop3,
              weeklyReset: competition.weeklyReset,
          }
        : undefined

    const competitionContentTypeOptions = Object.keys(CompetitionContentType).map((type) => ({
        // eslint-disable-next-line
        // @ts-ignore
        label: CompetitionContentType[type],
        // eslint-disable-next-line
        // @ts-ignore
        identifier: CompetitionContentType[type],
        // eslint-disable-next-line
        // @ts-ignore
        data: CompetitionContentType[type],
    }))

    const competitionTypeOptions = Object.keys(CompetitionType).map((type) => ({
        // eslint-disable-next-line
        // @ts-ignore
        label: CompetitionType[type],
        // eslint-disable-next-line
        // @ts-ignore
        identifier: CompetitionType[type],
        // eslint-disable-next-line
        // @ts-ignore
        data: CompetitionType[type],
    }))

    const handleCreateFeedbackSubmit: SubmitHandler<CompetitionFormData> = (data) => {
        competitionFormRef.current!.setErrors({})

        if (isNaN(new Date(data.startsOn).getTime())) {
            competitionFormRef.current!.setFieldError('startsOn', 'Please specify the correct date')
            return
        }

        if (isNaN(new Date(data.endsOn).getTime())) {
            competitionFormRef.current!.setFieldError('endsOn', 'Please specify the correct date')
            return
        }

        if (isNaN(new Date(data.visibleFrom).getTime())) {
            competitionFormRef.current!.setFieldError('visibleFrom', 'Please specify the correct date')
            return
        }

        if (isNaN(new Date(data.visibleUntil).getTime())) {
            competitionFormRef.current!.setFieldError('visibleFrom', 'Please specify the correct date')
            return
        }

        if (!data.name) {
            competitionFormRef.current!.setFieldError('name', 'This field is required')
            return
        }

        if (!data.detailsPageHeading) {
            competitionFormRef.current!.setFieldError('detailsPageHeading', 'This field is required')
            return
        }

        if (!data.detailsPageButtonText) {
            competitionFormRef.current!.setFieldError('detailsPageButtonText', 'This field is required')
            return
        }

        if (!data.detailsPageButtonLink) {
            competitionFormRef.current!.setFieldError('detailsPageButtonLink', 'This field is required')
            return
        }

        if (!data.previewCardButtonText) {
            competitionFormRef.current!.setFieldError('previewCardButtonText', 'This field is required')
            return
        }

        const { startsOn, endsOn, visibleFrom, visibleUntil, type, contentType, countryId, ...rest } = data

        const body: CompetitionRequest = {
            startsOn: formatUTCDateForRequest(startsOn),
            endsOn: formatUTCDateForRequest(endsOn),
            visibleFrom: formatUTCDateForRequest(visibleFrom),
            visibleUntil: formatUTCDateForRequest(visibleUntil),
            contentType: contentType?.data || null,
            userTargetingID: targeting,
            countryId: countryId.data,
            faqs: competitionFAQs,
            prizes: competitionPrizes?.length
                ? competitionPrizes.map((prize) => ({ ...prize, rankingPosition: +prize.rankingPosition }))
                : [],
            type: type.data,
            winners: competition?.winners ?? [],
            ...rest,
        }

        const handleFunction = isNew ? CompetitionService.create(body) : CompetitionService.update(competitionUuid, body)

        trackPromise(
            handleFunction
                .then((competition) => {
                    toast.success(`Competition has been ${isNew ? 'created' : 'updated'}`)
                    isNew && history.push(routes.competitionRoute(competition.uuid))
                    updateCompetition(competition)
                })
                .catch((error) => {
                    if (error instanceof ApiError) {
                        if (error.type === ErrorTypes.FormValidation) {
                            badRequestFormErrors(error, competitionFormRef.current!)
                        } else {
                            error.handleUnknown('An error occurred while changing competition data.')
                        }
                    } else {
                        throw error
                    }
                }),
            loadingAreas.save
        )
    }

    return (
        <>
            <Form ref={competitionFormRef} onSubmit={handleCreateFeedbackSubmit} initialData={initialData}>
                <br />
                <Headline4 color={theme.colors.knowunityBlue} marginBottom={20}>
                    Update form
                </Headline4>
                <Box>
                    <Headline5 color={theme.colors.white}>General info</Headline5>
                    <Input name="name" placeholder="Name (for internal purpose only)" />
                    <Input name="customKey" placeholder="Custom Key (defines leaderboard ranking generation)" />
                    <Input name="startsOn" placeholder="Start date (UTC time)" type="datetime-local" />
                    <Input name="endsOn" placeholder="End date (UTC time)" type="datetime-local" />
                    <Input name="visibleFrom" placeholder="Visible From (UTC time)" type="datetime-local" />
                    <Input name="visibleUntil" placeholder="Visible Until (UTC time)" type="datetime-local" />
                    <FormSelect name="contentType" placeholder="Content Type" options={competitionContentTypeOptions} />
                    <FormSelect name="type" placeholder="Type" options={competitionTypeOptions} />
                    <Input name="unit" placeholder="Unit (shown next to amount on competition table)" />
                    {countryOptions && <FormSelect name="countryId" options={countryOptions} placeholder="Country" />}
                    {renderTargetingButton()}
                </Box>

                {!isNew && (
                    <>
                        <CompetitionFAQForm faqs={competitionFAQs} setCompetitionFAQs={setCompetitionFAQs} />
                        <CompetitionPrizeForm prizes={competitionPrizes} setCompetitionPrizes={setCompetitionPrize} />
                    </>
                )}
                <Box>
                    <Headline5 color={theme.colors.white}>Details Page</Headline5>
                    <Input name="detailsPageHeading" placeholder="Heading" />
                    <Input name="detailsPageTextHeading" placeholder="Text Heading" />
                    <Input name="detailsPageText" placeholder="Text (supports Markdown)" />
                    <Input name="detailsPageButtonText" placeholder="Button Text" />
                    <Input name="detailsPageButtonLink" placeholder="Button Link" />
                </Box>

                <Box>
                    <Headline5 color={theme.colors.white}>Preview Card</Headline5>
                    <Input name="previewCardHeading" placeholder="Heading" />
                    <Input name="previewCardText" placeholder="Card Text" />
                    <Input name="previewCardButtonText" placeholder="Button Text" />
                    <Input name="previewCardLink" placeholder="Card Link" />
                </Box>

                <Box>
                    <Headline5 color={theme.colors.white}>Options</Headline5>
                    <Checkbox name="enableLeaderboard" placeholder="Enable Leaderboard" />
                    <Checkbox name="showContentInHomeScreen" placeholder="Show Content on HomeScreen" />
                    <Checkbox name="showTop3" placeholder="Show Top 3" />
                    <Checkbox name="weeklyReset" placeholder="Reset weekly" />
                </Box>

                <Button fullWidth icon={<SaveIcon />} loadingArea={loadingAreas.save}>
                    {`${isNew ? 'Create' : 'Save'} Competition`}
                </Button>
            </Form>
            {renderTargetingModal()}
        </>
    )
}

export default CompetitionForm
