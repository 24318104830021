import React from 'react'
import { ButtonLink } from 'components/elements/Button'
import { ReactComponent as AddIcon } from 'assets/icons/add.svg'
import { ApiError } from 'services/ApiService'
import { trackPromise } from 'react-promise-tracker'
import { useCallback } from 'react'
import RefactoredTable from 'components/modules/RefactoredTable'
import routes from 'lib/constants/routes'
import Stack from 'components/elements/Stack'
import { FilterFormGrid } from 'style'
import ManualPushNotificationsService from 'services/ManualPushNotificationsService'
import { formatDateTime } from 'utils'
import Meta from 'components/modules/Head'
import { formatManualCommunicationProgress } from 'utils/manualCommunication'

const loadingAreas = {
    container: 'manualPushNotificationsContainer',
}

const ManualPushNotifications = () => {
    const formatTitle = (title: string, maxLength: number) => (title.length > maxLength ? `${title.slice(0, 30)}...` : title)

    const fetchData = useCallback((page: number) => {
        return trackPromise(
            ManualPushNotificationsService.list(page)
                .then((data) => {
                    if (page !== data.page) return

                    return {
                        totalPages: data.totalPages,
                        totalElements: data.totalElements,

                        elements: data.notifications.map((n) => ({
                            id: n.uuid,
                            columns: [
                                n.uuid,
                                formatTitle(n.title, 30),
                                n.clickPath,
                                n.type,
                                n.user.username,
                                n?.sentOn ? formatDateTime(n.sentOn) : 'Not sent yet',
                                formatManualCommunicationProgress(n.expectedUsersCount, n.actualUsersCount),
                            ],
                            onClick: routes.manualPushNotificationRoute(n.uuid),
                        })),
                    }
                })
                .catch((error: ApiError) => {
                    error.handleUnknown('An error occurred while getting manual push notifications.')
                }),
            loadingAreas.container
        )
    }, [])

    return (
        <section>
            <Meta title="Manual Push Notifications" />
            <Stack gutter={5}>
                <h2>Manual Push Notifications</h2>
                <FilterFormGrid gridAmount={2}>
                    <ButtonLink to={routes.manualPushNotificationsCreateRoute} fullWidth={false} icon={<AddIcon />}>
                        Add Manual push Notification
                    </ButtonLink>
                </FilterFormGrid>
                <RefactoredTable
                    columns={['#', 'Title', 'Click Path', 'Tracking Type', 'Creator', 'Sent On', 'Progress']}
                    loadData={fetchData}
                    loadingArea={loadingAreas.container}
                    refetchKey={undefined}
                />
            </Stack>
        </section>
    )
}

export default ManualPushNotifications
