import React, { useEffect, useState } from 'react'
import { trackPromise } from 'react-promise-tracker'
import { ApiError } from 'services/ApiService'
import Modal from 'components/modules/Modal'
import { useToggleState } from 'utils/hooks/useToggleState'
import SearchService from 'services/SearchService'
import LoadingArea from 'components/elements/LoadingArea'
import Table, { TableData } from 'components/modules/Table'
import routes from 'lib/constants/routes'
import Button from 'components/elements/Button'
import { AutoKnowDemand } from 'interfaces/AutoKnowDemand'

const loadingAreas = {
    container: 'searchResultsContainer',
}

interface Props {
    autoKnowDemand: AutoKnowDemand
}

type SearchResult = {
    totalElements: number
    elements: TableData[]
}

const AutoKnowDemandSearchResults = ({ autoKnowDemand }: Props) => {
    const [showAutoKnowDemandModal, toggleAutoKnowDemandModal] = useToggleState(false)
    const [amountOfResults, setAmountOfResults] = useState<number | null>(null)
    const [searchContents, setSearchContents] = useState<SearchResult>()

    const { userContentLanguage, grade, country, schoolType } = autoKnowDemand

    useEffect(() => {
        trackPromise(
            SearchService.getKnows(
                autoKnowDemand.term,
                'automatedKnowDemandsOnAdmin',
                undefined,
                userContentLanguage.code,
                grade.level,
                schoolType.name,
                country.code
            )
                .then((data) => {
                    setAmountOfResults(data.totalElements)
                    const tableData = {
                        totalElements: data.totalElements,
                        elements: data?.content?.length
                            ? data.content
                                  .filter((x) => !!x.know)
                                  .map((content) => ({
                                      id: content.know!.uuid,
                                      columns: [
                                          content.know!.uuid,
                                          content.know!.title,
                                          content.know!.knowType.name,
                                          content.know!.status,
                                          content.know!.subjects.map((s) => s.name).join(', '),
                                          content.know!.grades.map((g) => g.shortName).join(', '),
                                          `@${content.know!.knower.user.username}`,
                                      ],
                                      onClick: routes.knowRoute(content.know!.uuid),
                                  }))
                            : [],
                    }
                    tableData && setSearchContents(tableData)
                })
                .catch((error) => {
                    if (error instanceof ApiError) {
                        error.handleUnknown('An error occurred while listing search results.')
                    } else {
                        throw error
                    }
                }),
            loadingAreas.container
        )
    }, [autoKnowDemand, country.code, grade.level, schoolType.name, showAutoKnowDemandModal, userContentLanguage.code])

    return (
        <LoadingArea area={loadingAreas.container}>
            <div>
                {amountOfResults ? (
                    <Button fullWidth={false} onClick={toggleAutoKnowDemandModal}>
                        {amountOfResults}
                    </Button>
                ) : (
                    'No results'
                )}
            </div>
            <Modal show={showAutoKnowDemandModal} onClose={toggleAutoKnowDemandModal} title="Create Know Demand" fullWidth>
                {searchContents && searchContents?.elements?.length && (
                    <Table
                        columns={['#', 'Title', 'Type', 'Status', 'Subjects', 'Grades', 'Knower']}
                        data={searchContents?.elements}
                        page={0}
                        totalPages={1}
                        totalElements={searchContents?.totalElements}
                    />
                )}
            </Modal>
        </LoadingArea>
    )
}

export default AutoKnowDemandSearchResults
