import React, { useCallback, useEffect, useState } from 'react'
import Table, { TableData } from 'components/modules/Table'
import SubjectService from 'pages/Subjects/SubjectService'
import { ApiError } from 'services/ApiService'
import { trackPromise } from 'react-promise-tracker'
import { ButtonLink } from 'components/elements/Button'
import { CountryCode } from 'interfaces/Country'
import Stack from 'components/elements/Stack'
import { ControlWrapper, FilterFormGrid } from 'style'
import { ReactComponent as AddIcon } from 'assets/icons/add.svg'
import SelectElement from 'components/inputs/SelectElement'
import routes from 'lib/constants/routes'
import { getColorIndicator } from 'utils/subjects'
import Meta from 'components/modules/Head'

const loadingAreas = {
    container: 'subjectsContainer',
}

const Subjects = () => {
    const [data, setTableData] = useState<TableData[]>([])
    const [country, setCountry] = useState<CountryCode>(CountryCode.Germany)

    const fetchData = useCallback(() => {
        trackPromise(
            SubjectService.list(country)
                .then((subjects) => {
                    const tableData = subjects
                        .sort((s1, s2) => s1.id - s2.id)
                        .map((s) => ({
                            id: s.id,
                            columns: [
                                s.id,
                                s.name,
                                s.color ? getColorIndicator(s.color) : 'No color selected',
                                <img src={s.iconUrl} alt="Icon" key={s.id} />,
                                s.sortId,
                            ],
                            onClick: routes.subjectRoute(s.id),
                        }))
                    setTableData(tableData)
                })
                .catch((error: ApiError) => {
                    error.handleUnknown('An error occurred while getting subjects.')
                }),
            loadingAreas.container
        )
    }, [country])

    useEffect(() => {
        fetchData()
    }, [fetchData])

    const countryOptions = [
        ...Object.keys(CountryCode).map((countryKey) => ({
            label: countryKey,
            // eslint-disable-next-line
            // @ts-ignore
            data: CountryCode[countryKey],
        })),
    ]

    const handleUpdateCountry = (value: CountryCode) => {
        setCountry(value)
    }

    return (
        <section>
            <Meta title="Subjects" />
            <Stack gutter={10}>
                <h2>Subjects</h2>
                <FilterFormGrid gridAmount={2}>
                    <ControlWrapper>
                        <ButtonLink to={routes.subjectsCreateRoute} fullWidth={false} icon={<AddIcon />}>
                            Add Subject
                        </ButtonLink>
                    </ControlWrapper>
                    <SelectElement label="Country" noMargin options={countryOptions} onUpdate={handleUpdateCountry} />
                </FilterFormGrid>
                <Table
                    columns={['#', 'Name', 'Color', 'Subject Icon', 'Sorting Number']}
                    data={data}
                    page={0}
                    totalPages={1}
                    totalElements={1}
                    setPage={() => null}
                    area={loadingAreas.container}
                />
            </Stack>
        </section>
    )
}

export default Subjects
