/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { ApiError } from 'services/ApiService'
import { trackPromise } from 'react-promise-tracker'
import RefactoredTable, { RefactoredTableData } from 'components/modules/RefactoredTable'
import { FilterFormGrid, NoWrapText } from 'style'
import AutoKnowDemandsService from 'services/AutoKnowDemandsService'
import { AutoKnowDemand, AutoKnowDemandBasicData, KnowDemandsStatus } from 'interfaces/AutoKnowDemand'
import { useToggleState } from 'utils/hooks/useToggleState'
import Search from 'components/elements/Search'
import { formatDateTime } from 'utils'
//import GradeService from 'services/GradeService'
import { Grade } from 'interfaces/Grade'
import { toast } from 'react-toastify'
import Modal from 'components/modules/Modal'
import KnowDemandForm from 'pages/KnowDemands/KnowDemandForm'
import { KnowDemand } from 'interfaces/KnowDemand'
import Button from 'components/elements/Button'
import useQuery from 'utils/hooks/useQuery'
import { useHistory } from 'react-router-dom'
import routes from 'lib/constants/routes'
import { FormHandles } from '@unform/core'
import { Form } from '@unform/web'
import { FormSelect } from 'components/inputs/Input'
import AutoKnowDemandStatusForm from './AutoKnowDemandStatusForm'
import styled from 'styled-components'
import AutoKnowDemandSearchResults from './AutoKnowDemandsSearchResults'
import { Country } from 'interfaces/Country'
import CountriesService from 'services/CountriesService'
import { returnGradeLabel } from 'lib/features/grades'
import { URLs } from 'lib/constants/urls'
import { Body1 } from 'components/elements/Text'
import theme from 'lib/constants/theme'
import SelectElement from 'components/inputs/SelectElement'
import { returnDefaultKnowDemandData } from 'lib/features/knowdemands'
import GradeSelection from 'components/forms/GradeSelection/GradeSelection'
import { SchoolType } from 'interfaces/SchoolType'
import Meta from 'components/modules/Head'

const loadingAreas = {
    container: 'customersContainer',
}

const JoinTab = styled.div`
    display: flex;
    gap: 10px;
    justify-content: space-between;
    align-items: center;
`

const AutoKnowDemands = () => {
    const query = useQuery()
    const queryStatus = useMemo(() => query.get('status'), [query])
    const queryCountry = useMemo(() => query.get('country'), [query])

    const [countryOptions, setCountryOptions] = useState<{ label: string; identifier: string; data: Country | null }[]>([])
    const [refetch, setRefetch] = useState<string>()
    const [searchTerm, setSearchTerm] = useState<string>()
    const [maxFillRate, setMaxFillRate] = useState<string>()
    const [resultsCount, setResultsCount] = useState<string>()
    const [status, setStatus] = useState<KnowDemandsStatus | null>((queryStatus as KnowDemandsStatus) ?? null)
    const [grade, setGrade] = useState<Grade | null>(null)
    const [country, setCountry] = useState<Country | null>(null)
    const [showAutoKnowDemandModal, toggleAutoKnowDemandModal] = useToggleState(false)
    const [showKnowDemandStatusModal, toggleKnowDemandStatusModal] = useToggleState(false)
    const [initialKnowData, setInitialKnowData] = useState<KnowDemand>()
    const [autoKnowBasicData, setAutoKnowBasicData] = useState<AutoKnowDemandBasicData[]>()
    const [sortBy, setSortBy] = useState<string | null>(null)
    const history = useHistory()
    const formRef = useRef<FormHandles>(null)

    useEffect(() => {
        CountriesService.list()
            .then((countries) => {
                const options = [
                    { label: 'No Filter', identifier: 'no-filter', data: null },
                    ...countries.map((c) => ({ label: c.name, identifier: c.id.toString(), data: c })),
                ]
                setCountryOptions(options)

                const selectedCountry = options.find(
                    (countryOption) => countryOption?.data?.id === Number(queryCountry)
                )?.data

                selectedCountry && setCountry(selectedCountry)
            })
            .catch((error) => {
                if (error instanceof ApiError) {
                    error.handleUnknown('Unable to list countries.')
                    return
                } else {
                    throw error
                }
            })

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleUpdateGrades = useCallback(
        (grade: Grade | null) => {
            setGrade(grade)
            history.push({
                pathname: routes.autoKnowDemandsRoute,
                search: returnFiltersQueryParams(status, grade, country),
            })
        },
        [country, history, status]
    )

    const handleGradeSelectionConfirm = useCallback(
        (_: SchoolType[], grades: Grade[]) => {
            handleUpdateGrades(grades?.length ? grades[0] : null)
        },
        [handleUpdateGrades]
    )

    const updateSearchTermWithKnowDemandId = (searchTermId: string, knowDemandUuid: string) => {
        AutoKnowDemandsService.updateId(searchTermId, knowDemandUuid)
            .then(() => {
                setRefetch('created')
            })
            .catch((error) => {
                if (error instanceof ApiError) {
                    error.handleUnknown('An error occurred while updating Automated Know Demand.')
                } else {
                    throw error
                }
            })
    }

    const onKnowDemandCreated = (knowDemand: KnowDemand) => {
        if (!initialKnowData) return
        updateSearchTermWithKnowDemandId(initialKnowData.uuid, knowDemand.uuid)
        toggleAutoKnowDemandModal()
    }

    const populateInitialData = (t: AutoKnowDemand) => {
        setInitialKnowData(returnDefaultKnowDemandData(t))
    }

    const handleClick = useCallback((t: AutoKnowDemand) => {
        switch (t.status) {
            case KnowDemandsStatus.SupplyIssue:
                populateInitialData(t)
                toggleAutoKnowDemandModal()
                break
            default:
                toast.warn(`You can only add Know Demand when the status is "SUPPLY_ISSUE"!`)
                break
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleStatusClick = useCallback((t: AutoKnowDemand) => {
        setAutoKnowBasicData([t])
        toggleKnowDemandStatusModal()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleBulkStatusClick = useCallback((elements: RefactoredTableData[]) => {
        setAutoKnowBasicData(
            elements.map(({ id, columns }) => ({
                id: +id,
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                status: (columns[0] as any).props.children[0],
            }))
        )

        toggleKnowDemandStatusModal()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    function isNumber(value: string | number | undefined): value is number {
        return Number.isNaN(Number(value)) === false
    }

    const fetchData = useCallback(
        (page: number, limit?: number) => {
            return trackPromise(
                AutoKnowDemandsService.list(
                    page,
                    limit,
                    status,
                    grade?.id,
                    searchTerm,
                    country?.id,
                    isNumber(maxFillRate) ? maxFillRate : null,
                    isNumber(resultsCount) ? resultsCount : null,
                    sortBy
                )
                    .then((data) => {
                        setRefetch(undefined)
                        if (page !== data.page) return
                        return {
                            totalPages: data.totalPages,
                            totalElements: data.totalElements,
                            elements: data.terms.map((t, i) => ({
                                id: t.id,
                                columns: [
                                    <JoinTab key={`${t.status}-${i}-status-cell`}>
                                        <NoWrapText>{t.status}</NoWrapText>
                                        <Button onClick={() => handleStatusClick(t)} fullWidth={false}>
                                            Update
                                        </Button>
                                    </JoinTab>,
                                    t.term,
                                    t.userContentLanguage.englishName,
                                    t.country.englishName,
                                    t.schoolType.name,
                                    <AutoKnowDemandSearchResults
                                        autoKnowDemand={t}
                                        key={`${t.status}-${i}-search-results-cell`}
                                    />,
                                    t.grade.level,
                                    t.searchCount,
                                    t.searchFillRate,
                                    formatDateTime(t.lastStatusUpdatedOn),
                                    <Button key={`${t.status}-${i}-button`} onClick={() => handleClick(t)} fullWidth={false}>
                                        Create
                                    </Button>,
                                ],
                                selected: false,
                            })),
                        }
                    })
                    .catch((error) => {
                        if (error instanceof ApiError) {
                            error.handleUnknown('An error occurred while getting customers.')
                        } else {
                            throw error
                        }
                    }),
                loadingAreas.container
            )
        },

        [status, grade?.id, searchTerm, country?.id, maxFillRate, resultsCount, sortBy, handleStatusClick, handleClick]
    )

    const onSuccess = () => {
        setAutoKnowBasicData(undefined)
        toggleKnowDemandStatusModal()
        setRefetch('status')
    }

    const statusTypeOptions = [
        {
            label: 'No Filter',
            identifier: 'no-filter',
            data: null,
        },
        ...Object.keys(KnowDemandsStatus).map((typeKey) => ({
            // eslint-disable-next-line
            // @ts-ignore
            label: KnowDemandsStatus[typeKey],
            identifier: typeKey,
            // eslint-disable-next-line
            // @ts-ignore
            data: KnowDemandsStatus[typeKey],
        })),
    ]

    const returnFiltersQueryParams = (status: KnowDemandsStatus | null, grade: Grade | null, country: Country | null) =>
        `?${status ? `status=${status}` : ''}${grade ? `&grade=${grade.id}` : ''}${country ? `&country=${country.id}` : ''}`

    const handleUpdateStatus = (value: KnowDemandsStatus | null) => {
        setStatus(value)
        history.push({
            pathname: routes.autoKnowDemandsRoute,
            search: returnFiltersQueryParams(value, grade, country),
        })
    }

    const handleUpdateCountryId = (value: Country | null) => {
        setCountry(value)
        handleUpdateGrades(null)
        history.push({
            pathname: routes.autoKnowDemandsRoute,
            search: returnFiltersQueryParams(status, grade, value),
        })
    }

    const handleSortBy = (sortBy: string) => setSortBy(sortBy)

    const sortByOptions = [
        {
            label: 'Search count',
            data: 'searchCount',
        },
        {
            label: 'Search term',
            data: 'searchTerm',
        },
        {
            label: 'Search fill rate',
            data: 'searchFillRate',
        },
    ]

    const initialData = {
        status: {
            label: status ?? 'No Filter',
            data: status ?? null,
        },
        gradeId: {
            label: grade ? returnGradeLabel(grade) : '',
            data: grade,
        },
        country: {
            label: country?.name,
            data: country,
        },
    }

    return (
        <section>
            <Meta title="Automatic Know Demands" />
            <h2>Automatic Know Demands</h2>
            <Form ref={formRef} onSubmit={() => {}} initialData={initialData}>
                <FilterFormGrid gridAmount={3}>
                    <FormSelect
                        placeholder="Country"
                        name="country"
                        options={countryOptions}
                        onUpdate={handleUpdateCountryId}
                        noMargin
                    />
                    <GradeSelection
                        label="Grade"
                        name="gradeId"
                        onConfirm={handleGradeSelectionConfirm}
                        limitToOneSelectedItem
                    />
                    <FormSelect
                        placeholder="Status"
                        name="status"
                        options={statusTypeOptions}
                        onUpdate={handleUpdateStatus}
                        noMargin
                    />
                </FilterFormGrid>
                <FilterFormGrid gridAmount={4}>
                    <Search onSearch={setSearchTerm} fullWidth label="Search with term..." />
                    <Search onSearch={setMaxFillRate} fullWidth label="Maxmimum fill rate..." type="number" />
                    <Search onSearch={setResultsCount} fullWidth label="Maximum results count..." type="number" />
                    <SelectElement label="Sort by" noMargin options={sortByOptions} onUpdate={handleSortBy} />
                </FilterFormGrid>
            </Form>
            <a href={URLs.automaticKnowDemandsGuidelines}>
                <Body1 color={theme.colors.knowunityBlue} marginBottom={5}>
                    Automatic Know Demands Guidelines
                </Body1>
            </a>
            <RefactoredTable
                columns={[
                    'Status',
                    'Term',
                    'User Language',
                    'Country',
                    'School Type',
                    'Search Results',
                    'Grade',
                    'Search Count',
                    'Search Fill Rate',
                    'Updated on',
                    'Action',
                ]}
                loadData={fetchData}
                allEntriesButtonText="Update status of selected entries"
                onAllEntries={(elements) => handleBulkStatusClick(elements)}
                loadingArea={loadingAreas.container}
                allowTableLimitChange
                refetchKey={`${grade}_${status}_${searchTerm}_${refetch}_${maxFillRate}_${resultsCount}_${sortBy}`}
            />
            <Modal show={showAutoKnowDemandModal} onClose={toggleAutoKnowDemandModal} title="Create Know Demand">
                {initialKnowData && (
                    <KnowDemandForm
                        isNew
                        knowDemand={initialKnowData}
                        onSave={onKnowDemandCreated}
                        hideAnotherCheckbox
                        isAuto
                    />
                )}
            </Modal>
            <Modal
                show={showKnowDemandStatusModal}
                onClose={toggleKnowDemandStatusModal}
                title="Update Auto Know Demand Status"
            >
                {autoKnowBasicData && (
                    <AutoKnowDemandStatusForm autoKnowDemandBasicData={autoKnowBasicData} onSuccess={onSuccess} />
                )}
            </Modal>
        </section>
    )
}

export default AutoKnowDemands
