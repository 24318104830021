/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useCallback, useEffect, useState } from 'react'
import Box from 'components/elements/Box'
import Stack from 'components/elements/Stack'
import Meta from 'components/modules/Head'
import TaxonomyService from 'services/TaxonomyService'
import { ApiError } from 'services/ApiService'
import { TopicActionRequest, TopicCellsRow } from 'interfaces/Taxonomy'
import styled from 'styled-components'
import theme from 'lib/constants/theme'
import { ButtonText } from 'components/elements/Text'
import { FilterFormGrid } from 'style'
import SelectElement from 'components/inputs/SelectElement'
import { CountryCode } from 'interfaces/Country'
import SubjectService from 'pages/Subjects/SubjectService'
import { SelectOptionWithLabel } from 'components/inputs/Input'
import { Subject } from 'interfaces/Subject'
import Button from 'components/elements/Button'
import TopicCell from 'components/features/taxonomy/TopicCell'
import { toast } from 'react-toastify'
import { Prompt } from 'react-router-dom'
import TaxonomyCSVUpdate from './TaxonomyCSVUpdate'

const Section = styled.section`
    position: relative;
`

const ActionButtonContainer = styled.div`
    position: fixed;
    bottom: 10px;
    width: 250px;
    padding: 10px;
    right: 15px;
    border-radius: ${theme.borderRadius.card};
    background-color: ${theme.colors.violet};
`

const CellsContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 10px;
`

const TaxonomyComponent = () => {
    const [topicCellsRows, setTopicCellsRows] = useState<TopicCellsRow[]>([])
    const [updatedTopicCells, setUpdatedTopicCells] = useState<TopicActionRequest[]>([])
    const [selectedCountry, setSelectedCountry] = useState<CountryCode>(CountryCode.Germany)
    const [selectedSubject, setSelectedSubject] = useState<Subject>()
    const [subjectsOptions, setSubjectOptions] = useState<SelectOptionWithLabel<Subject>[]>()
    const tableColumns = ['Topic', 'Subtopic', 'Subtopic 1']

    const showPromptAboutUnsavedChanges = updatedTopicCells?.length > 0

    const fetchSubjects = useCallback(() => {
        SubjectService.list(selectedCountry)
            .then((subjects) => {
                const subjectOptions = subjects
                    .sort((a, b) => (a.name < b.name ? -1 : 1))
                    .map((s) => ({ label: s.name, identifier: s.id.toString(), data: s }))

                setSelectedSubject(subjectOptions[0].data)
                setSubjectOptions(subjectOptions)
            })
            .catch((error: ApiError) => {
                error.handleUnknown('An error occurred while getting subjects.')
            })
    }, [selectedCountry])

    useEffect(() => {
        fetchSubjects()
    }, [fetchSubjects])

    const fetchTaxonomyCells = useCallback(() => {
        if (!selectedSubject) return

        TaxonomyService.listBySubject(selectedSubject.id)
            .then((data) => {
                setTopicCellsRows(data)
            })
            .catch((error) => {
                if (error instanceof ApiError) {
                    error.handleUnknown('An error occurred while getting taxonomy topics by subject.')
                } else {
                    throw error
                }
            })
    }, [selectedSubject])

    useEffect(() => {
        fetchTaxonomyCells()
    }, [fetchTaxonomyCells])

    const countryTypeOptions = Object.keys(CountryCode).map((typeKey) => ({
        label: typeKey,
        // eslint-disable-next-line
        // @ts-ignore
        data: CountryCode[typeKey],
    }))

    const updateCountryCode = (countryCode: CountryCode) => setSelectedCountry(countryCode)

    const updateSubjectOption = (subject: Subject) => {
        setSelectedSubject(subject)
        setUpdatedTopicCells([])
    }

    const addTopicCellToRequestArray = (topicCell: TopicActionRequest) =>
        setUpdatedTopicCells((prevTopicCells) => [...prevTopicCells, topicCell])

    const removeTopicCellFromRequestArray = (topicCell: TopicActionRequest) =>
        setUpdatedTopicCells((prevTopicCells) => prevTopicCells.filter((prevTopicCell) => prevTopicCell !== topicCell))

    const findMatchingUpdatedTopicCell = (topicCellUuid: string) =>
        updatedTopicCells.find((cell) => cell.uuid === topicCellUuid)

    const handleSubmitChangesButtonClick = () => {
        if (!updatedTopicCells.length) {
            toast.warning('You need to apply changes first')
            return
        }

        const formTopics = updatedTopicCells.map((topic) => {
            if (topic.action === 'DELETE') delete topic.newTopicName

            return topic
        })

        TaxonomyService.batchUpdateTopics(formTopics)
            .then(() => {
                setUpdatedTopicCells([])
                toast.success(
                    'The changes were added to the queue and should be applied within couple of hours. Please be patient 😊'
                )
            })
            .catch((error) => {
                if (error instanceof ApiError) {
                    error.handleUnknown('An error occurred while applying topic changes.')
                } else {
                    throw error
                }
            })
    }

    return (
        <Section>
            <Meta title="Taxonomy" />
            <Stack>
                <h2>Taxonomy</h2>
                <FilterFormGrid gridAmount={3}>
                    <TaxonomyCSVUpdate />
                    <SelectElement label="Country code" noMargin options={countryTypeOptions} onUpdate={updateCountryCode} />
                    {subjectsOptions && (
                        <SelectElement options={subjectsOptions} label="Subject" onUpdate={updateSubjectOption} noMargin />
                    )}
                </FilterFormGrid>
                <Box>
                    <CellsContainer>
                        {topicCellsRows ? (
                            <>
                                {tableColumns.map((column) => (
                                    <ButtonText key={column} fontWeigth={400}>
                                        {column}
                                    </ButtonText>
                                ))}
                                {topicCellsRows.map((topicCellRow) => (
                                    <>
                                        {topicCellRow.map((topicCell, i) => (
                                            <TopicCell
                                                topicLevel={++i}
                                                topicCell={topicCell}
                                                key={topicCell.uuid}
                                                addTopicCellToRequestArray={addTopicCellToRequestArray}
                                                removeTopicCellFromRequestArray={removeTopicCellFromRequestArray}
                                                updatedTopicCell={findMatchingUpdatedTopicCell(topicCell.uuid)}
                                            />
                                        ))}
                                    </>
                                ))}
                            </>
                        ) : (
                            'No taxonomy topics found'
                        )}
                    </CellsContainer>
                    <ActionButtonContainer>
                        <Button
                            fullWidth
                            color={theme.colors.deepSeaBlue}
                            hoverColor={theme.colors.deepSeaBlue}
                            noMargin
                            onClick={handleSubmitChangesButtonClick}
                        >
                            <ButtonText color={theme.colors.white}>Submit</ButtonText>
                        </Button>
                    </ActionButtonContainer>
                </Box>
            </Stack>
            {showPromptAboutUnsavedChanges ? (
                <Prompt
                    message={`Do you want to leave without saving the changes? You can save by clicking "Submit" in the bottom right corner`}
                />
            ) : null}
        </Section>
    )
}

export default TaxonomyComponent
