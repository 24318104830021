import { Country } from './Country'
import { Grade } from './Grade'
import { KnowType } from './Know'
import { SchoolType } from './SchoolType'
import { Subject } from './Subject'
import { Currency } from './Transaction'
import { LanguageInterface } from './Language'

export enum KnowDemandPayoutOptions {
    Tier0 = 'KNOW_DEMAND_TIER_0',
    Tier1 = 'KNOW_DEMAND_TIER_1',
    Tier2 = 'KNOW_DEMAND_TIER_2',
    Tier3 = 'KNOW_DEMAND_TIER_3',
    Tier4 = 'KNOW_DEMAND_TIER_4',
    Tier5 = 'KNOW_DEMAND_TIER_5',
    Tier6 = 'KNOW_DEMAND_TIER_6',
}

export enum KnowDemandFormat {
    PDF = 'PDF',
    FlashcardsBasic = 'FLASHCARDS_BASIC',
    FlashcardsQuiz = 'FLASHCARDS_QUIZ',
}

interface KnowDemandsReservation {
    dueDate: string | null
    knowDemandUuid: string
    knowUuid: string
    knowerUuid: string
}

export interface KnowDemand {
    uuid: string
    createdOn: string
    title: string
    description: string
    subject: Subject
    grades: Grade[]
    schoolType: SchoolType
    knowType: KnowType
    payoutTier: KnowDemandPayoutOptions | null
    country: Country
    price: number | null
    currency: Currency | null
    maxReservationsCount: number
    reservationsCount: number
    knowDemandReservations: KnowDemandsReservation[]
    format: KnowDemandFormat
    expiresOn: string | null
    language: Pick<LanguageInterface, 'id' | 'name' | 'code'>
}

export interface CreateKnowDemand {
    title: string
    description: string
    subjectId: number
    gradeIds: number[]
    knowTypeId: number
    schoolTypeId: number
    countryId: number
    maxReservationCount: number
    format: KnowDemandFormat
    expiresOn: string
    payoutTier?: KnowDemandPayoutOptions | null
}

export interface NotifyKnowDemands {
    countryId: number
}

export type NotifyFormData = { country: Country }
