export enum LanguageCode {
    German = 'de',
    French = 'fr',
    BritishEnglish = 'en-GB',
    Polish = 'pl',
    Italian = 'it',
    AmericanEnglish = 'en',
    Spanish = 'es',
    Catalan = 'ca',
    SpanishColombia = 'es-CO',
    Turkish = 'tr',
    Mexico = 'es-MX',
}

export interface LanguageInterface {
    id: number
    name: string
    code: LanguageCode
    localizedName: string
    englishName: string
}
