export enum ContentPrefixes {
    AD = 'AD',
    Content = 'CONTENT',
    CareerPartner = 'CAREER-PARTNER',
    ProfileBanner = 'PROFILE-BANNER',
    ManualPushNotificationThumbnailImage = 'MANUAL-PUSH-NOTIFICATION-THUMBNAIL-IMAGE',
    CampaignStepPushNotificationThumbnailImage = 'CAMPAIGN-STEP-PUSH-NOTIFICATION-THUMBNAIL-IMAGE',
    ADCustomerLogo = 'AD-CUSTOMER-LOGO',
    CustomerPostPreviewImage = 'CUSTOMER-POST-PREVIEW-IMAGE',
}
