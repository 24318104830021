import React, { useRef } from 'react'
import { Form } from '@unform/web'
import { AutoKnowDemandBasicData, EditableKnowDemandsStatus, KnowDemandsStatus } from 'interfaces/AutoKnowDemand'
import { FormHandles, SubmitHandler } from '@unform/core'
import Button from 'components/elements/Button'
import { FormSelect } from 'components/inputs/Input'
import LoadingArea from 'components/elements/LoadingArea'
import AutoKnowDemandsService from 'services/AutoKnowDemandsService'
import { trackPromise } from 'react-promise-tracker'
import { toast } from 'react-toastify'
import { ApiError } from 'services/ApiService'
import { badRequestFormErrors } from 'utils'
import { ErrorTypes } from 'utils/constants/ErrorTypes'
import { ReactComponent as SaveIcon } from 'assets/icons/save.svg'

const loadingAreas = {
    save: 'saveKnowDemand',
    form: 'knowDemandForm',
}

interface Props {
    onSuccess: () => void
    autoKnowDemandBasicData: AutoKnowDemandBasicData[]
}

type FormData = {
    status: KnowDemandsStatus
}

const AutoKnowDemandStatusForm = ({ autoKnowDemandBasicData, onSuccess }: Props) => {
    const formRef = useRef<FormHandles>(null)

    const handleSubmit: SubmitHandler<FormData> = async (data) => {
        formRef.current!.setErrors({})

        await autoKnowDemandBasicData.map(({ id }) =>
            trackPromise(
                AutoKnowDemandsService.updateStatus(id, data.status).catch((error: ApiError) => {
                    if (error.type === ErrorTypes.FormValidation) {
                        badRequestFormErrors(error, formRef.current!)
                    } else {
                        error.handleUnknown('An error occurred while updating auto know demand status.')
                    }
                }),
                loadingAreas.save
            )
        )
        toast.success('Auto Know demands data has been changed!')
        onSuccess()
    }

    const statusTypeOptions = Object.keys(EditableKnowDemandsStatus).map((typeKey) => ({
        // eslint-disable-next-line
        // @ts-ignore
        label: EditableKnowDemandsStatus[typeKey],
        identifier: typeKey,
        // eslint-disable-next-line
        // @ts-ignore
        data: EditableKnowDemandsStatus[typeKey],
    }))

    const isEditable =
        (autoKnowDemandBasicData[0] &&
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            Object.values(EditableKnowDemandsStatus).includes(autoKnowDemandBasicData[0].status as any)) ||
        autoKnowDemandBasicData?.length > 1

    return (
        <div>
            {isEditable ? (
                <LoadingArea area={loadingAreas.form}>
                    <Form
                        ref={formRef}
                        initialData={{
                            status: {
                                label: autoKnowDemandBasicData[0].status,
                                data: autoKnowDemandBasicData[0].status,
                            },
                        }}
                        onSubmit={handleSubmit}
                    >
                        {statusTypeOptions && <FormSelect name="status" options={statusTypeOptions} placeholder="Status" />}

                        <Button fullWidth icon={<SaveIcon />} loadingArea={loadingAreas.save}>
                            Save
                        </Button>
                    </Form>
                </LoadingArea>
            ) : (
                <div>
                    <p>You can only update the status of Auto Know Demands with the status of</p>
                    <ul>
                        {Object.values(EditableKnowDemandsStatus).map((value) => (
                            <li key={value}>{value}</li>
                        ))}
                    </ul>
                </div>
            )}
        </div>
    )
}

export default AutoKnowDemandStatusForm
