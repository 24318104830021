import React, { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import Button from './Button'
import InputElement from '../inputs/InputElement'
import { ReactComponent as Icon } from 'assets/icons/search.svg'
import useDebounce from 'utils/hooks/useDebounce'

interface ContainerProps {
    fullWidth?: boolean
    hideButton?: boolean
    noMargingTop?: boolean
}

const Container = styled.div<ContainerProps>`
    display: grid;
    grid-template-columns: ${({ fullWidth }) => (fullWidth ? '1fr auto' : '3fr 2fr auto')};
    grid-gap: ${({ hideButton }) => (hideButton ? 'undefined' : '10px')};
    position: relative;

    .icon-button {
        text-indent: 20px;
        background: #fff !important;
    }
    .buttons {
        display: flex;
        gap: 10px;
    }
    input {
        text-indent: 20px;
        ${({ hideButton }) =>
            hideButton
                ? css`
                      height: 50px;
                  `
                : ''}
    }
    ${({ hideButton }) =>
        hideButton
            ? css`
                  input {
                      height: 50px;
                  }
                  margin-top: -20px;
                  padding: 0 px;
              `
            : ''}
    margin-top:   ${({ noMargingTop }) => (noMargingTop ? '0' : undefined)};
`

const StyledButton = styled(Button)`
    display: grid;
    place-items: center;
    place-self: end;
`

const Spacer = styled.div`
    height: 32px;
`

const SearchIcon = styled(Icon)`
    position: absolute;
    left: 15px;
    top: 50px;
    fill: #353c56 !important;
`

interface Props {
    onSearch(value: string): void
    fullWidth?: boolean
    label?: string
    defaultValue?: string | null
    type?: 'number' | 'text'
    addResetButton?: boolean
    placeholder?: string
    onDebounce?: (query: string) => void
    hideButton?: boolean
    noMarginTop?: boolean
}

const Search = (props: Props) => {
    const [value, setValue] = useState<string>(props.defaultValue ?? '')
    const debouncedSearchValue = useDebounce(value, 500)

    useEffect(() => {
        if (!props.onDebounce) return

        if (debouncedSearchValue || debouncedSearchValue.length === 0) {
            props.onDebounce(debouncedSearchValue)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debouncedSearchValue, props.onDebounce])

    const handleChange = (value: string) => {
        setValue(encodeURIComponent(value))
    }

    const handleSubmit = () => {
        props.onSearch(value)
    }

    const resetQuery = () => {
        setValue('')
        props.onSearch('')
        ;(document.querySelector('.reset-search-input') as HTMLInputElement).value = ''
    }

    return (
        <Container fullWidth={!!props.fullWidth} hideButton={props.hideButton} noMargingTop={props.noMarginTop}>
            <InputElement
                label={props.label ?? ''}
                onInput={handleChange}
                onEnter={handleSubmit}
                noMargin
                defaultValue={value}
                type={props.type ?? 'text'}
                className={props.addResetButton ? 'reset-search-input' : ''}
                placeholder={props.placeholder}
                autoFocus
            />
            <SearchIcon />
            {!props.hideButton && (
                <div>
                    <Spacer />
                    <div className="buttons">
                        <StyledButton onClick={handleSubmit} fullWidth={false}>
                            Search
                        </StyledButton>
                        {props.addResetButton ? (
                            <StyledButton onClick={resetQuery} fullWidth={false}>
                                Reset
                            </StyledButton>
                        ) : null}
                    </div>
                </div>
            )}
        </Container>
    )
}

export default Search
