import React, { useEffect, useRef, useState } from 'react'
import Spinner from 'components/elements/Spinner'
import { User } from 'interfaces/User'
import { FormHandles, SubmitHandler } from '@unform/core'
import { Form } from '@unform/web'
import Button from 'components/elements/Button'
import { Input, SelectMultiple } from 'components/inputs/Input'
import { trackPromise } from 'react-promise-tracker'
import { toast } from 'react-toastify'
import { ApiError } from 'services/ApiService'
import { badRequestFormErrors, isUUID } from 'utils'
import { ErrorTypes } from 'utils/constants/ErrorTypes'
import UserService from 'services/UserService'
import Box from 'components/elements/Box'
import { useHistory } from 'react-router-dom'
import routes from 'lib/constants/routes'
import { Body1, Body2 } from 'components/elements/Text'
import theme from 'lib/constants/theme'
import SubjectService from 'pages/Subjects/SubjectService'
import { formatSubjectLabel } from 'lib/features/subjects'

interface Props {
    user: User
    fetchData: () => void
}

interface EngagementFormData {
    type: string
    points: number
}

const loadingAreas = {
    delete: 'deleteUser',
    engagementPoint: 'engagementPoint',
    expertSubmit: 'expertSubmit',
}

const UserAction = ({ user, fetchData }: Props) => {
    const engagementPointsFormRef = useRef<FormHandles>(null)
    const subjectExpertFormRef = useRef<FormHandles>(null)
    const [subjectOptions, setSubjectOptions] = useState<{ label: string; identifier: string; data: number }[]>([])
    const history = useHistory()
    const userUuid = user.uuid

    const handleUSEngagementPointsSubmit: SubmitHandler<EngagementFormData> = ({ points, type }) => {
        engagementPointsFormRef.current!.setErrors({})

        trackPromise(
            UserService.addEngagementPoints(userUuid, { points: +points, type })
                .then(() => {
                    fetchData()
                    toast.success(`${points} engagement points were added to the user`)
                })
                .catch((error) => {
                    if (error instanceof ApiError) {
                        if (error.type === ErrorTypes.FormValidation) {
                            badRequestFormErrors(error, engagementPointsFormRef.current!)
                        } else {
                            error.handleUnknown('An error occurred while adding user engagement points.')
                        }
                    } else {
                        throw error
                    }
                }),
            loadingAreas.engagementPoint
        )
    }

    const handleUserAssignExpertSubmit: SubmitHandler<{
        subjectsIds: { label: string; identifier: string; data: number }[]
    }> = ({ subjectsIds }) => {
        subjectExpertFormRef.current!.setErrors({})

        if (!userUuid || !isUUID(userUuid)) {
            subjectExpertFormRef.current?.setFieldError('userUuid', 'Please enter a valid user uuid')
            return
        }

        const subjectIdsArray = subjectsIds.map((s) => s.data)

        trackPromise(
            UserService.assignAsSubjectExpert(userUuid, subjectIdsArray)
                .then(() => {
                    fetchData()
                    toast.success(`User was assigned as subject expert`)
                })
                .catch((error) => {
                    if (error instanceof ApiError) {
                        if (error.type === ErrorTypes.FormValidation) {
                            badRequestFormErrors(error, subjectExpertFormRef.current!)
                        } else {
                            error.handleUnknown('An error occurred while adding user engagement points.')
                        }
                    } else {
                        throw error
                    }
                }),
            loadingAreas.expertSubmit
        )
    }

    const deleteUser = () => {
        if (!window.confirm('Do you really want to delete the user?')) return

        trackPromise(
            UserService.delete(userUuid)
                .then(() => {
                    history.push(routes.usersRoute)
                })
                .catch((error) => {
                    if (error instanceof ApiError) {
                        if (error.type === ErrorTypes.KnowIsUndeletable) {
                            toast.error(
                                "You can't delete an account with Knows that are created for a know demand or a content contest"
                            )
                        } else {
                            error.handleUnknown('An error occurred while deleting the user.')
                        }
                    } else {
                        throw error
                    }
                }),
            loadingAreas.delete
        )
    }

    useEffect(() => {
        SubjectService.list(user.country.code)
            .then((subjects) => {
                const subjectOptions = subjects
                    .sort((a, b) => (a.name < b.name ? -1 : 1))
                    .map((s) => ({ label: formatSubjectLabel(s), identifier: s.id.toString(), data: s.id }))
                setSubjectOptions(subjectOptions)
            })
            .catch((error: ApiError) => {
                error.handleUnknown('An error occurred while getting subjects.')
            })
    }, [setSubjectOptions, user.country.code])

    return (
        <>
            {user ? (
                <>
                    <Box>
                        <Button
                            onClick={deleteUser}
                            fullWidth
                            color="var(--red)"
                            hoverColor="var(--red-dark)"
                            loadingArea={loadingAreas.delete}
                        >
                            Delete user
                        </Button>
                    </Box>
                    <Box>
                        <Body1 color={theme.colors.white} marginBottom={15}>
                            Assign an user as expert for given subjects
                        </Body1>
                        <Form
                            ref={subjectExpertFormRef}
                            onSubmit={handleUserAssignExpertSubmit}
                            initialData={{
                                subjectsIds: user.expertSubjects.map((s) => ({
                                    label: formatSubjectLabel(s),
                                    identifier: s.id.toString(),
                                    data: s.id,
                                })),
                            }}
                        >
                            {subjectOptions && (
                                <SelectMultiple name="subjectsIds" options={subjectOptions} label="Subjects" />
                            )}
                            <Button fullWidth loadingArea={loadingAreas.expertSubmit}>
                                Save
                            </Button>
                        </Form>
                    </Box>
                    <Box>
                        <Body1 color={theme.colors.white} marginBottom={15}>
                            US Engagement Points
                        </Body1>
                        <Body2 color={theme.colors.body2Black}>{`Engagement level: ${user.engagementLevel}`}</Body2>
                        <Body2 color={theme.colors.body2Black}>{`Engagement points: ${user.engagementPoints}`}</Body2>
                        <Form
                            ref={engagementPointsFormRef}
                            onSubmit={handleUSEngagementPointsSubmit}
                            initialData={{ points: 10 }}
                        >
                            <Input
                                name="points"
                                placeholder={`Points (you can add negative points "-10" to subtract them from users )`}
                                type="number"
                            />
                            <Button fullWidth loadingArea={loadingAreas.engagementPoint}>
                                Save
                            </Button>
                        </Form>
                    </Box>
                </>
            ) : (
                <Spinner forceShow />
            )}
        </>
    )
}

export default UserAction
