import React from 'react'
import { Body1 } from 'components/elements/Text'
import InformationCard from 'components/features/InformationCard'
import { Input } from 'components/inputs/Input'
import theme from 'lib/constants/theme'

const DelayStepForm = () => (
    <InformationCard title={<Body1 color={theme.colors.knowunityBlue}>Delay</Body1>}>
        <Input name="delayInMinutes" placeholder="Delay (in minutes)" type="number" />
        <Input name="untilTime" placeholder="Time" type="time" />
    </InformationCard>
)

export default DelayStepForm
