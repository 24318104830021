import ApiService from 'services/ApiService'
import { IconName, Subject, SubjectCategory, UpdateSubject } from 'interfaces/Subject'
import { CountryCode } from 'interfaces/Country'

const SubjectService = {
    list(country: number | CountryCode) {
        return ApiService.get(`countries/${country}/subjects`).then((response) => {
            return response.data.content as Subject[]
        })
    },

    get(id: number) {
        return ApiService.get(`/subjects/${id}/admin`).then((response) => {
            return response.data as Subject
        })
    },

    create(subject: UpdateSubject) {
        return ApiService.post('/subjects', subject).then((response) => {
            return response.data as Subject
        })
    },

    update(id: number, subject: UpdateSubject) {
        return ApiService.patch(`/subjects/${id}`, subject).then((response) => {
            return response.data as Subject
        })
    },

    getCategories() {
        return ApiService.get('/subjects/categories').then((response) => {
            return response.data.content as SubjectCategory[]
        })
    },

    getIconNames() {
        return ApiService.get('/subjects/icons').then((response) => {
            return response.data.content as IconName[]
        })
    },
}

export default SubjectService
