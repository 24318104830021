import { LanguageCode } from 'interfaces/Language'
import { KnowFormat } from './Know'

export enum ReviewGroup {
    GroupA = 0,
    GroupB = 1,
    GroupC = 2,
}

export type InternalRatingType = 1 | 2 | 3 | null

export interface ReviewSettings {
    language: LanguageCode | null
    reviewGroup: ReviewGroup | null
    format: KnowFormat | null
    internalRating: InternalRatingType
    isAmbassador: boolean | null
    createdAfter: string | null
    createdBefore: string | null
}
