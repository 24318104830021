import React from 'react'
import { CampaignStep, CampaignStepType } from 'interfaces/Campaings'
import Button from 'components/elements/Button'
import Row from 'components/elements/Row'
import { Body2 } from 'components/elements/Text'
import CollapsibleCard from 'components/features/CollapsibleCard'
import { ReactComponent as EditIcon } from 'assets/icons/edit-white.svg'
import { ReactComponent as DeleteIcon } from 'assets/icons/delete.svg'
import theme from 'lib/constants/theme'
import { getCampaignStepIdentification, renderCampaignStep } from 'utils/campaign'
import styled from 'styled-components'
import CampaignService from './CampaignService'
import { loadingAreas } from 'lib/constants/LoadingAreas'
import { trackPromise } from 'react-promise-tracker'
import { toast } from 'react-toastify'
import { ApiError } from 'services/ApiService'
import Stack from 'components/elements/Stack'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'

const ButtonRow = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    button {
        height: 30px;
    }
`

const loadingArea = {
    sendDraft: 'sendDraft',
}

interface Props {
    handleDeleteStep: (indexToDelete: number) => void
    handleEditStep: (campaignStep: CampaignStep, index: number) => void
    steps: CampaignStep[]
    changeSteps: (steps: CampaignStep[]) => void
    isNew: boolean
    campaignUuid: string
}

const CampaignStepDisplay = ({ steps, changeSteps, handleDeleteStep, handleEditStep, isNew, campaignUuid }: Props) => {
    const sendStepDraftToYourself = (step: CampaignStep) => {
        const stepUuid = getCampaignStepIdentification(step)

        if (!stepUuid || !campaignUuid) return

        trackPromise(
            CampaignService.sendDraftStep(campaignUuid, stepUuid)
                .then(() => {
                    toast.success('A draft will be send to your account')
                })
                .catch((error) => {
                    if (error instanceof ApiError) {
                        error.handleUnknown('An error occurred while sending draft.')
                    } else {
                        throw error
                    }
                }),
            loadingAreas.save
        )
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleDrop = (item: any) => {
        if (!item.destination) return

        const updatedList = [...steps]

        const [reorderedItem] = updatedList.splice(item.source.index, 1)
        // Add dropped item
        updatedList.splice(item.destination.index, 0, reorderedItem)
        // Update State
        changeSteps(updatedList)
    }

    return (
        <>
            {steps.length ? (
                <>
                    <DragDropContext onDragEnd={handleDrop}>
                        <Droppable droppableId="list-container">
                            {(provided) => (
                                <div className="list-container" {...provided.droppableProps} ref={provided.innerRef}>
                                    <Stack gutter={10}>
                                        {steps.map((step, i) => (
                                            <Draggable key={`step-${i}`} draggableId={`step-${i}`} index={i}>
                                                {(provided) => (
                                                    <div
                                                        className="item-container"
                                                        ref={provided.innerRef}
                                                        {...provided.dragHandleProps}
                                                        {...provided.draggableProps}
                                                    >
                                                        <CollapsibleCard
                                                            key={i}
                                                            title={
                                                                <ButtonRow>
                                                                    <Body2 color={theme.colors.white}>{`${i + 1}: ${
                                                                        step.type
                                                                    }`}</Body2>
                                                                    <Row gap={20}>
                                                                        <Button
                                                                            fullWidth={false}
                                                                            onClick={() => handleDeleteStep(i)}
                                                                            type="button"
                                                                            icon={<DeleteIcon />}
                                                                            color={theme.colors.secondaryRed}
                                                                            hoverColor={theme.colors.secondaryRedDarker}
                                                                        >
                                                                            Delete
                                                                        </Button>
                                                                        <Button
                                                                            fullWidth={false}
                                                                            onClick={() => handleEditStep(step, i)}
                                                                            type="button"
                                                                            icon={<EditIcon />}
                                                                        >
                                                                            Edit
                                                                        </Button>
                                                                    </Row>
                                                                </ButtonRow>
                                                            }
                                                            openByDefault
                                                        >
                                                            <Stack gutter={35}>
                                                                {renderCampaignStep(step)}
                                                                {!isNew && step.type !== CampaignStepType.Delay ? (
                                                                    <Stack gutter={10}>
                                                                        <Button
                                                                            noMargin
                                                                            fullWidth={false}
                                                                            type="button"
                                                                            loadingArea={loadingArea.sendDraft}
                                                                            onClick={() => sendStepDraftToYourself(step)}
                                                                            color={theme.colors.knowunityBlueDark}
                                                                            hoverColor={theme.colors.knowunityBlue}
                                                                        >
                                                                            {'Send a draft of this step to your account'}
                                                                        </Button>
                                                                        <Body2 color={theme.colors.white} fontWeigth={400}>
                                                                            Remember to first update the campaign in order to
                                                                            see the newer version of draft
                                                                        </Body2>
                                                                    </Stack>
                                                                ) : null}
                                                            </Stack>
                                                        </CollapsibleCard>
                                                    </div>
                                                )}
                                            </Draggable>
                                        ))}
                                        {provided.placeholder}
                                    </Stack>
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                </>
            ) : (
                <Body2>No steps were added</Body2>
            )}
        </>
    )
}

export default CampaignStepDisplay
