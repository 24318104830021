/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useCallback, useEffect, useRef, useState } from 'react'
import LoadingArea from 'components/elements/LoadingArea'
import { Form } from '@unform/web'
import { FormSelect, Input } from 'components/inputs/Input'
import Button, { ButtonLink } from 'components/elements/Button'
import { Link, RouteComponentProps, useHistory } from 'react-router-dom'
import { FormHandles, SubmitHandler } from '@unform/core/typings/types'
import { ReactComponent as SaveIcon } from 'assets/icons/save.svg'
import { toast } from 'react-toastify'
import { trackPromise } from 'react-promise-tracker'
import { badRequestFormErrors, formatTimeDifference, getFileNameFromFileUrl } from 'utils'
import { ApiError } from 'services/ApiService'
import { ErrorTypes } from 'utils/constants/ErrorTypes'
import AdSystemService from 'services/AdSystemService'
import { AdCustomer as AdCustomerInterface, UpdateAdCustomer } from 'interfaces/AdSystem'
import Box from 'components/elements/Box'
import CopyIcon from 'components/elements/CopyIcon'
import routes from 'lib/constants/routes'
import RefactoredTable from 'components/modules/RefactoredTable'
import { ReactComponent as AddIcon } from 'assets/icons/add.svg'
import { ControlsWrapper, ControlWrapper, EditButton, FilterFormGrid, MetaBox } from 'style'
import { ReactComponent as BackIcon } from 'assets/icons/chefron-left.svg'
import theme from 'lib/constants/theme'
import Modal from 'components/modules/Modal'
import { useToggleState } from 'utils/hooks/useToggleState'
import ExplanationBanner from 'components/elements/ExplanationBanner'
import Stack from 'components/elements/Stack'
import EditRecordInline from 'components/elements/EditRecordInline'
import Meta from 'components/modules/Head'
import AdCustomerAttachments from './AdAttachments/AdCustomerAttachments'
import UploadWrapper from 'components/FileUpload/UploadWrapper'
import ContentPreview from 'components/modules/ContentPreview/ContentPreview'
import { ContentTypes } from 'interfaces/Know'
import { ContentPrefixes } from 'utils/constants/ContentPrefix'
import FileUploadService from 'services/FileUploadService'

interface Props extends RouteComponentProps<{ id: string }> {}

interface UploadContent {
    filename: string
    url: string
}

interface FormData {
    name: string
    subLineText: string
}

const loadingAreas = {
    save: 'saveAdCustomer',
    information: 'informationAdCustomer',
    container: 'adCampaignsContainer',
    logoImageUpload: 'adCustomerLogoImageUpload',
}

const AdCustomer = (props: Props) => {
    const [adCustomer, setAdCustomer] = useState<AdCustomerInterface>()
    const [showActiveCampaigns, setShowActiveCampaign] = useState<boolean | null>(true)
    const [initialData, setInitialData] = useState<UpdateAdCustomer>()
    const [uploadedThumbnailImage, setUploadedThumbnailImage] = useState<UploadContent | null>(null)
    const [showEditCustomerModal, toggleEditCustomerModal] = useToggleState(false)
    const [refetchTable, toggleRefetch] = useToggleState(false)

    const formRef = useRef<FormHandles>(null)
    const runningFormRef = useRef<FormHandles>(null)
    const history = useHistory()
    const id = props.match.params.id
    const isNew = id === 'create'

    const activeType = [
        {
            label: 'Active',
            data: true,
            identifier: 'Active',
        },
        {
            label: 'Not active',
            data: false,
            identifier: 'Not active',
        },
        {
            label: 'All',
            data: null,
            identifier: 'All',
        },
    ]

    const fetchData = useCallback(() => {
        if (!id || isNew) return

        trackPromise(
            AdSystemService.getCustomer(id)
                .then((customer) => {
                    setAdCustomer(customer)
                    setInitialData({
                        name: customer.name,
                        subLineText: customer.subLineText,
                        logoFilename: customer.logoFilePath,
                    })
                    if (customer.logoFilePath) {
                        setUploadedThumbnailImage({
                            filename: getFileNameFromFileUrl(customer.logoFilePath),
                            url: customer.logoFilePath,
                        })
                    }
                })
                .catch((error) => {
                    if (error instanceof ApiError) {
                        error.handleUnknown('An error occurred while getting customer.')
                    } else {
                        throw error
                    }
                }),
            loadingAreas.information
        )
    }, [id, isNew])

    useEffect(() => {
        fetchData()
    }, [fetchData])

    const handleSubmit: SubmitHandler<FormData> = (data) => {
        formRef.current!.setErrors({})

        const requestData = {
            ...data,
            logoFilename: uploadedThumbnailImage ? uploadedThumbnailImage.filename : null,
        }

        let handleFunc
        if (isNew) {
            handleFunc = AdSystemService.createCustomer(requestData)
        } else {
            handleFunc = AdSystemService.updateCustomer(id, requestData)
        }
        trackPromise(
            handleFunc
                .then((customer) => {
                    setAdCustomer(customer)
                    toast.success('Customer data has been saved.')
                    history.push(routes.adsCustomerRoute(customer.uuid))
                    !isNew && toggleEditCustomerModal()
                })
                .catch((error: ApiError) => {
                    if (error.type === ErrorTypes.FormValidation) {
                        badRequestFormErrors(error, formRef.current!)
                    } else {
                        error.handleUnknown(`An error occurred while ${isNew ? 'creating' : 'updating'} a customer.`)
                    }
                }),
            loadingAreas.save
        )
    }

    const updateAdCampaignPartially = useCallback(
        (itemUuid: string, recordName: string, recordValue: string | number, hideForm: () => void) => {
            if (!recordValue) return

            const body = {
                [`${recordName}`]: !isNaN(+recordValue) ? +recordValue : recordValue,
            }

            trackPromise(
                AdSystemService.updateCampaignPartially(itemUuid, body)
                    .then(() => {
                        fetchData()
                        toast.success('Campaign data has been saved.')
                        hideForm()
                        toggleRefetch()
                    })
                    .catch((error: ApiError) => {
                        if (error.type === ErrorTypes.FormValidation) {
                            badRequestFormErrors(error, formRef.current!)
                        } else {
                            error.handleUnknown(`An error occurred while updating an asset.`)
                        }
                    }),
                loadingAreas.save
            )
        },
        [fetchData, toggleRefetch]
    )

    const fetchCampaigns = useCallback(
        (page: number) => {
            return trackPromise(
                AdSystemService.listCampaign(id, page, showActiveCampaigns)
                    .then((data) => {
                        if (page !== data.page) return

                        return {
                            totalPages: data.totalPages,
                            totalElements: data.totalElements,
                            elements: data.campaings.map((c) => ({
                                id: c.uuid,
                                columns: [
                                    c.uuid,
                                    <EditRecordInline
                                        itemUuid={c.uuid}
                                        key={`edit-campaign-${c.name}`}
                                        name="name"
                                        value={c.name}
                                        onSubmit={updateAdCampaignPartially}
                                    >
                                        {c.name}
                                    </EditRecordInline>,
                                    c.goalType,
                                    <EditRecordInline
                                        itemUuid={c.uuid}
                                        key={`edit-campaign-${c.goal}`}
                                        name="goal"
                                        value={c.goal}
                                        onSubmit={updateAdCampaignPartially}
                                    >
                                        {c.goal ?? 'Not specified'}
                                    </EditRecordInline>,
                                    c.startOn ? formatTimeDifference(c.startOn) : 'never',
                                    c.endOn ? formatTimeDifference(c.endOn) : 'never',
                                    c.isActive ? 'Yes' : 'No',
                                    <Link key={c.uuid} to={routes.adsCampaignRoute(id, c.uuid)}>
                                        <Button noMargin fullWidth={false}>
                                            Go to
                                        </Button>
                                    </Link>,
                                ],
                            })),
                        }
                    })
                    .catch((error) => {
                        if (error instanceof ApiError) {
                            error.handleUnknown('An error occurred while getting customers.')
                        } else {
                            throw error
                        }
                    }),
                loadingAreas.container
            )
        },
        [id, showActiveCampaigns, updateAdCampaignPartially]
    )

    const handleUpdateActiveCampaignFilter = (filter: boolean) => setShowActiveCampaign(filter)

    const allowedFileFormats = ['image/png', 'image/jpg', 'image/jpeg']

    const onChangeFile = (event: React.ChangeEvent<HTMLInputElement>) => {
        const innerFile = event.target.files![0]
        if (!innerFile) {
            toast.error('You need to upload an asset image!')
            return
        }
        const maxFileSize = 25 * 1000000

        if (!allowedFileFormats.includes(innerFile.type)) {
            toast.error('Please upload your file as an image!')
            return
        }

        if (innerFile.size > maxFileSize) {
            toast.error('Sorry, the file is too big. The maximum file size is 25 MB.')
            return
        }

        trackPromise(
            FileUploadService.upload(innerFile, ContentPrefixes.ADCustomerLogo)
                .then(({ url, filename }) => {
                    setUploadedThumbnailImage({
                        url,
                        filename,
                    })
                })
                .catch((error) => {
                    if (error instanceof ApiError) {
                        if (error.type === ErrorTypes.InvalidMIMEType) {
                            toast.error('Please choose a different file format.')
                        } else {
                            error.handleUnknown('An error occurred while uploading the image')
                        }
                    } else {
                        throw error
                    }
                }),
            loadingAreas.logoImageUpload
        )
    }

    const UpdateCustomerForm = () => (
        <Form ref={formRef} onSubmit={handleSubmit} initialData={initialData}>
            <Input name="name" placeholder="Customer name" />
            <Input name="subLineText" placeholder="Subline text" />
            <>
                <label>Thumbnail Image</label>
                <UploadWrapper
                    handleFileUpload={onChangeFile}
                    area={loadingAreas.logoImageUpload}
                    acceptRules={allowedFileFormats.join(', ')}
                >
                    <p>Click here to select an image or video.</p>
                    <ContentPreview contentType={ContentTypes.image} previewUrl={uploadedThumbnailImage?.url ?? ''} />
                </UploadWrapper>
            </>
            <Button fullWidth icon={<SaveIcon />} loadingArea={loadingAreas.save}>
                {isNew ? 'Save' : 'Update'}
            </Button>
        </Form>
    )

    return (
        <section>
            <Meta title="Ad Customer" />
            {!isNew ? (
                <ExplanationBanner
                    title="Updates delay"
                    text="Changes about the ads might take up to 1 hour and 10 minutes to become present for the users in the app"
                />
            ) : null}
            <h2>Ad Customer</h2>
            <ControlsWrapper>
                <ControlWrapper>
                    <ButtonLink to={routes.adsSystemRoute} fullWidth={false} icon={<BackIcon />}>
                        Go back to customers
                    </ButtonLink>
                </ControlWrapper>
            </ControlsWrapper>
            <Stack>
                {!isNew && (
                    <LoadingArea>
                        <Box>
                            <Stack>
                                <MetaBox>
                                    <h3>Information</h3>
                                    <EditButton onClick={toggleEditCustomerModal} />
                                </MetaBox>
                                {adCustomer && (
                                    <>
                                        <p>
                                            <strong>UUID:</strong>&nbsp;
                                            {adCustomer.uuid}
                                            <CopyIcon text={adCustomer.uuid} />
                                        </p>
                                        <p>
                                            <strong>Name:</strong>&nbsp;
                                            {adCustomer.name}
                                        </p>
                                        <p>
                                            <strong>Subline text:</strong>&nbsp;
                                            {adCustomer.subLineText}
                                        </p>
                                        <p>
                                            <strong>Logo:</strong>&nbsp;
                                        </p>
                                        <img
                                            src={uploadedThumbnailImage?.url}
                                            width={125}
                                            height={125}
                                            alt="customer logo"
                                            style={{ objectFit: 'contain' }}
                                        />
                                    </>
                                )}
                            </Stack>
                        </Box>
                    </LoadingArea>
                )}
                {adCustomer ? <AdCustomerAttachments adCustomer={adCustomer} /> : null}

                {!isNew && adCustomer ? (
                    <Box>
                        <Stack>
                            <h2>Ad Campaigns</h2>
                            <FilterFormGrid gridAmount={2}>
                                <ControlWrapper>
                                    <ButtonLink
                                        to={routes.adsCreateCampaignRoute(adCustomer.uuid)}
                                        fullWidth={false}
                                        icon={<AddIcon />}
                                        hoverColor={theme.colors.knowunityBlue}
                                        color={theme.colors.knowunityBlueDark}
                                    >
                                        Create Campaign
                                    </ButtonLink>
                                </ControlWrapper>
                                <Form
                                    ref={runningFormRef}
                                    onSubmit={() => {}}
                                    //initialData={{ runningCampaings: runningCampaignFilter }}
                                >
                                    <FormSelect
                                        name="runningCampaings"
                                        placeholder="Filter by Running Campaigns"
                                        options={activeType}
                                        onUpdate={handleUpdateActiveCampaignFilter}
                                    />
                                </Form>
                            </FilterFormGrid>
                            <RefactoredTable
                                columns={['#', 'Name ✎', 'Goal Type', 'Goal ✎', 'Start On', 'End On', 'Is Active', 'Action']}
                                loadData={fetchCampaigns}
                                loadingArea={loadingAreas.container}
                                refetchKey={`${refetchTable}_${showActiveCampaigns}`}
                            />
                        </Stack>
                    </Box>
                ) : (
                    <UpdateCustomerForm />
                )}
            </Stack>
            <Modal show={showEditCustomerModal} title={'Update Customer information'} onClose={toggleEditCustomerModal}>
                <UpdateCustomerForm />
            </Modal>
        </section>
    )
}

export default AdCustomer
