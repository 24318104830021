import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Form } from '@unform/web'
import { Input, Textarea, Checkbox, SelectMultiple, SelectOption } from 'components/inputs/Input'
import FormSelect from 'components/inputs/FormSelect'
import Button from 'components/elements/Button'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { FormHandles, SubmitHandler } from '@unform/core/typings/types'
import { ReactComponent as SaveIcon } from 'assets/icons/save.svg'
import { trackPromise } from 'react-promise-tracker'
import { badRequestFormErrors, uniqueBy } from 'utils'
import { ApiError } from 'services/ApiService'
import { KnowDemand, KnowDemandFormat, KnowDemandPayoutOptions } from 'interfaces/KnowDemand'
import KnowDemandService from 'services/KnowDemandService'
import SubjectService from 'pages/Subjects/SubjectService'
import { Grade, GradeOption } from 'interfaces/Grade'
import { Subject } from 'interfaces/Subject'
import { ErrorTypes } from 'utils/constants/ErrorTypes'
import { Country } from 'interfaces/Country'
import CountriesService from 'services/CountriesService'
import { KnowType } from 'interfaces/Know'
import KnowTypeService from 'services/KnowTypeService'
import { countryToLanguageCode } from 'lib/country'
import { toast } from 'react-toastify'
import { SchoolType } from 'interfaces/SchoolType'
import SchoolTypeService from 'services/SchoolTypeService'
import { Subtitle1 } from 'components/elements/Text'
import theme from 'lib/constants/theme'
import { getKnowDemandsDefaultExpirationDateFromToday } from 'lib/features/knowdemands'
import { fetchGradesBasedOnSchoolType, returnGradeLabel } from 'lib/features/grades'

interface Props extends RouteComponentProps {
    knowDemand: KnowDemand | null
    isNew: boolean
    onSave(knowDemand: KnowDemand): void
    hideAnotherCheckbox?: boolean
    isAuto?: boolean
}

enum PayoutOption {
    Payout = 'payout',
    Overwrite = 'overwrite',
}

interface FormData {
    title: string
    description: string
    country: SelectOption<Country>
    knowType: SelectOption<KnowType>
    grades: SelectOption<Grade>[]
    subject: SelectOption<Subject>
    schoolType: SelectOption<SchoolType>
    createAnother: boolean
    maxReservationCount: string
    payoutTier: SelectOption<KnowDemandPayoutOptions | null>
    payoutSelection: SelectOption<PayoutOption.Payout | PayoutOption.Overwrite | null>
    format: SelectOption<KnowDemandFormat>
    expiresOn: string
}

const loadingAreas = {
    save: 'saveKnowDemand',
    form: 'knowDemandForm',
}

const payoutTierOptions = [
    { label: 'none', identifier: 'none', data: null },
    { label: 'Tier 0 (0.50 € / 2 PLN)', identifier: 'tier-0', data: KnowDemandPayoutOptions.Tier0 },
    { label: 'Tier 1 (1.00 € / 4 PLN)', identifier: 'tier-1', data: KnowDemandPayoutOptions.Tier1 },
    { label: 'Tier 2 (2.00 € / 8 PLN)', identifier: 'tier-2', data: KnowDemandPayoutOptions.Tier2 },
    { label: 'Tier 3 (3.00 € / 12 PLN)', identifier: 'tier-3', data: KnowDemandPayoutOptions.Tier3 },
    { label: 'Tier 4 (5.00 € / 20 PLN)', identifier: 'tier-4', data: KnowDemandPayoutOptions.Tier4 },
    { label: 'Tier 5 (10.00 € / 40 PLN)', identifier: 'tier-5', data: KnowDemandPayoutOptions.Tier5 },
    { label: 'Tier 6 (0.20 € / 1 PLN)', identifier: 'tier-6', data: KnowDemandPayoutOptions.Tier6 },
]

const knowDemandFormatOption = Object.keys(KnowDemandFormat).map((format) => ({
    // eslint-disable-next-line
    // @ts-ignore
    label: KnowDemandFormat[format],
    identifier: format,
    // eslint-disable-next-line
    // @ts-ignore
    data: KnowDemandFormat[format],
}))

const payoutOptionSelection = [
    {
        label: 'No payout',
        identifier: 'no-payout',
        data: null,
    },
    {
        label: 'Payout based on knower roadmap',
        identifier: 'payout',
        data: PayoutOption.Payout,
    },
    {
        label: 'Overwrite Payout Tier',
        identifier: 'overwrite',
        data: PayoutOption.Overwrite,
    },
]

const KnowDemandForm = (props: Props) => {
    const formRef = useRef<FormHandles>(null)
    const [country, setCountry] = useState<Country | null>(props.knowDemand?.country ?? null)
    const [knowTypeOptions, setKnowTypeOptions] = useState<{ label: string; identifier: string; data: KnowType }[]>([])
    const [countryOptions, setCountryOptions] = useState<{ label: string; identifier: string; data: Country }[]>([])
    const [gradeOptions, setGradeOptions] = useState<{ label: string; identifier: string; data: Grade }[]>([])
    const [subjectOptions, setSubjectOptions] = useState<{ label: string; identifier: string; data: Subject }[]>([])
    const [schoolTypeOptions, setSchoolTypeOptions] = useState<{ label: string; identifier: string; data: SchoolType }[]>([])
    const [initialData, setInitialData] = useState<FormData>()
    const [knowDemandsCreated, setKnowDemandsCreated] = useState(0)
    const [payoutOption, setPayoutOption] = useState<'payout' | 'overwrite' | 'none'>()

    const [selectedSchoolTypes, setSelectedSchoolTypes] = useState<SchoolType[]>()

    const handleGradeOptionsUpdate = (gradeOptions: GradeOption[]) => setGradeOptions(gradeOptions)

    const handleSchoolTypeSelected = (schoolType: SchoolType) => setSelectedSchoolTypes([schoolType])

    useEffect(() => {
        selectedSchoolTypes && fetchGradesBasedOnSchoolType(selectedSchoolTypes, handleGradeOptionsUpdate)
    }, [selectedSchoolTypes])

    const fetchCountry = useCallback(() => {
        trackPromise(
            CountriesService.list()
                .then((countries) => {
                    if (countries) {
                        if (props.isNew && !props.isAuto) {
                            setCountry(countries[0])
                        } else {
                            props.knowDemand && setCountry(props.knowDemand.country)
                        }
                        setCountryOptions(countries.map((c) => ({ label: c.name, identifier: c.id.toString(), data: c })))
                    }
                })
                .catch((error) => {
                    if (error instanceof ApiError) {
                        error.handleUnknown('Unable to list countries.')
                        return
                    } else {
                        throw error
                    }
                }),
            loadingAreas.form
        )
    }, [props.isNew, props.isAuto, props.knowDemand])

    useEffect(() => {
        fetchCountry()
    }, [fetchCountry])

    const returnPayoutMethodInitialData = (knowdemand: KnowDemand) => {
        switch (true) {
            case knowdemand.payoutTier !== null:
                return PayoutOption.Overwrite
            default:
                return null
        }
    }

    const handleGradeOptionsChange = useCallback(
        (gradesOptions: GradeOption[]) => {
            if (props.knowDemand?.grades) {
                gradesOptions = [
                    ...gradesOptions,
                    ...props.knowDemand.grades.map((g) => ({
                        label: returnGradeLabel(g),
                        identifier: g.id.toString(),
                        data: g,
                    })),
                ]
            }
            setGradeOptions(uniqueBy('identifier', gradesOptions))
        },
        [props.knowDemand?.grades]
    )

    useEffect(() => {
        if (!country) return

        let knowTypeOptions: { label: string; identifier: string; data: KnowType }[] = []
        let subjectOptions: { label: string; identifier: string; data: Subject }[] = []
        let schoolTypeOptions: { label: string; identifier: string; data: SchoolType }[] = []

        const subjectPromise = trackPromise(
            SubjectService.list(country.id)
                .then((subjects) => {
                    subjectOptions = subjects
                        .sort((a, b) => (a.name < b.name ? -1 : 1))
                        .map((s) => ({ label: s.name, identifier: s.id.toString(), data: s }))
                    setSubjectOptions(subjectOptions)
                })
                .catch((error: ApiError) => {
                    error.handleUnknown('An error occurred while getting subjects.')
                }),
            loadingAreas.form
        )

        const languageCode = countryToLanguageCode(country.code)
        if (!languageCode) {
            return
        }

        const knowTypePromise = trackPromise(
            KnowTypeService.list(languageCode)
                .then((types) => {
                    knowTypeOptions = types.map((type) => ({
                        label: type.name,
                        identifier: type.id.toString(),
                        data: type,
                    }))
                    setKnowTypeOptions(knowTypeOptions)
                })
                .catch((error: ApiError) => {
                    error.handleUnknown('An error occurred while getting content types.')
                }),
            loadingAreas.form
        )

        const schoolTypePromise = trackPromise(
            SchoolTypeService.list(country.id)
                .then((types) => {
                    schoolTypeOptions = types.map((type) => ({
                        label: type.name,
                        identifier: type.id.toString(),
                        data: type,
                    }))
                    fetchGradesBasedOnSchoolType(types, handleGradeOptionsChange)
                    setSchoolTypeOptions(schoolTypeOptions)
                })
                .catch((error: ApiError) => {
                    error.handleUnknown('An error occurred while getting content types.')
                }),
            loadingAreas.form
        )

        Promise.all([subjectPromise, knowTypePromise, schoolTypePromise]).then(() => {
            const knowDemand = props.knowDemand

            if (!knowDemand) return

            const payoutTierOption = payoutTierOptions.find((p) => p.data === knowDemand.payoutTier)
            const payoutOptionOption = payoutOptionSelection.find(
                (o) => o.data === returnPayoutMethodInitialData(knowDemand)
            )
            if (!payoutTierOption || !payoutOptionOption) return

            const initialForm: FormData = {
                title: knowDemand.title,
                country: { identifier: country.id.toString(), data: country },
                description: knowDemand.description,
                knowType: { identifier: knowDemand.knowType.id.toString(), data: knowDemand.knowType },
                grades: knowDemand.grades.map((grade) => ({
                    identifier: grade.id.toString(),
                    data: grade,
                })),
                subject: { identifier: knowDemand.subject.id.toString(), data: knowDemand.subject },
                schoolType: { identifier: knowDemand.schoolType.id.toString(), data: knowDemand.schoolType },
                maxReservationCount: knowDemand.maxReservationsCount.toString(),
                payoutTier: payoutTierOption,
                payoutSelection: payoutOptionOption,
                format: {
                    data: knowDemand.format,
                    identifier: knowDemand.format,
                },
                expiresOn: knowDemand?.expiresOn
                    ? knowDemand.expiresOn.slice(0, 10)
                    : getKnowDemandsDefaultExpirationDateFromToday().slice(0, 10),
                createAnother: true,
            }

            setInitialData(initialForm)

            knowDemand?.payoutTier && setPayoutOption('overwrite')
        })
    }, [country, handleGradeOptionsChange, props.knowDemand])

    const handleUpdateCountry = useCallback((data: Country) => {
        setCountry(data)
    }, [])

    const handleSubmit: SubmitHandler<FormData> = (data) => {
        if (!country) return
        formRef.current!.setErrors({})

        if (!data.expiresOn) {
            formRef.current!.setFieldError('expiresOn', 'Please enter correct end date')
            return
        }

        if (!data.maxReservationCount) {
            formRef.current!.setFieldError('maxReservationCount', 'Please enter maximum reservation count')
            return
        }

        if (new Date() > new Date(data.expiresOn)) {
            toast.error('Expiration date has to be in the future.')
            return
        }

        const updateKnowDemand = {
            title: data.title,
            description: data.description,
            subjectId: data.subject.data.id,
            gradeIds: data.grades.map((x) => x.data.id),
            knowTypeId: data.knowType.data.id,
            schoolTypeId: data.schoolType.data.id,
            countryId: data.country.data.id,
            maxReservationCount: parseInt(data.maxReservationCount),
            expiresOn: new Date(data.expiresOn).toISOString(),
            format: data.format.data,
            payoutTier: data.payoutTier?.data ?? null,
        }
        let handleFunc
        if (props.isNew) {
            handleFunc = KnowDemandService.create(updateKnowDemand)
        } else {
            if (!props.knowDemand) {
                return
            }

            handleFunc = KnowDemandService.update(props.knowDemand.uuid, updateKnowDemand)
        }

        trackPromise(
            handleFunc
                .then((knowDemand) => {
                    props.isNew && toast.success('Know demand has been created!')
                    data.createAnother ? setKnowDemandsCreated((count) => ++count) : props.onSave(knowDemand)
                    setPayoutOption(undefined)
                })
                .catch((error: ApiError) => {
                    if (error.type === ErrorTypes.FormValidation) {
                        badRequestFormErrors(error, formRef.current!)
                    } else {
                        error.handleUnknown('An error occurred while saving know demand.')
                    }
                }),
            loadingAreas.save
        )
    }

    const updateSelectionOption = (option: 'payout' | 'overwrite' | 'none') => setPayoutOption(option)

    return (
        <Form ref={formRef} initialData={initialData} onSubmit={handleSubmit}>
            {countryOptions && (
                <FormSelect name="country" options={countryOptions} placeholder="Country" onUpdate={handleUpdateCountry} />
            )}
            <Input name="title" placeholder="Title" />
            <Textarea name="description" placeholder="Description" maxLength="255" />
            {knowTypeOptions && <FormSelect name="knowType" options={knowTypeOptions} placeholder="Type" />}
            {schoolTypeOptions && (
                <FormSelect
                    name="schoolType"
                    options={schoolTypeOptions}
                    placeholder="School Type"
                    onUpdate={handleSchoolTypeSelected}
                />
            )}
            {gradeOptions && <SelectMultiple name="grades" options={gradeOptions} label="Grade" />}
            {subjectOptions && <FormSelect name="subject" options={subjectOptions} placeholder="Subject" />}
            {knowDemandFormatOption && <FormSelect placeholder="Format" name="format" options={knowDemandFormatOption} />}
            <Input name="expiresOn" placeholder="Expiration date" type="date" />
            <Input name="maxReservationCount" placeholder="Maximum Reservation Count" />
            <Subtitle1 color={theme.colors.knowunityBlue} textAlign="center">
                If you want the know to have a payout be sure to select Payout Method!
            </Subtitle1>
            <FormSelect
                placeholder="Selecting Payout Method"
                name="payoutSelection"
                options={payoutOptionSelection}
                onUpdate={updateSelectionOption}
            />
            {payoutOption === 'overwrite' ? (
                <FormSelect
                    name="payoutTier"
                    options={payoutTierOptions}
                    placeholder="Overwrite the payout by selecting a Payout Tier"
                />
            ) : null}
            {props.isNew && !props.hideAnotherCheckbox && (
                <Checkbox
                    name="createAnother"
                    placeholder={`Create another Know Demand ${
                        knowDemandsCreated ? `(already created ${knowDemandsCreated} know demands)` : ''
                    }`}
                />
            )}

            <Button fullWidth icon={<SaveIcon />} loadingArea={loadingAreas.save}>
                Save
            </Button>
        </Form>
    )
}

export default withRouter(KnowDemandForm)
