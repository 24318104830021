import { User } from './User'

export interface AdCustomer {
    uuid: string
    name: string
    logoFilePath: string | null
    subLineText: string | null
}

export type UpdateAdCustomer = Omit<AdCustomer, 'uuid' | 'logoFilePath'> & { logoFilename: string | null }

export interface AdCampaign {
    uuid: string
    name: string
    goalType: AdsGoalType
    goal: number
    userTargetingId: number
    isActive: boolean
    customer: User
    startOn: string | null
    endOn: string | null
    interests: AdsInterest[]
    frequencyCap: number | null
}

export interface CreateAdCampaign {
    name: string
    goalType: string
    goal: number
    startOn: string | null
    endOn: string | null
    interests: string[]
    frequencyCap: number | null
}

export interface UpdateAdCampaign {
    name: string
    goalType: string
    goal: number
    isActive: boolean
    startOn: string | null
    endOn: string | null
    interests: string[]
    frequencyCap: number | null
}

export interface AdsInterest {
    uuid: string
    tag: string
}

export enum AdsGoalType {
    Views = 'VIEWS',
    Clicks = 'CLICKS',
}

export interface ActiveCampaignFilter {
    isActive?: boolean
}

export type InlineAdRecord = {
    [x: string]: string | number
}

export enum AssetTimeframes {
    Current = 'CURRENT',
    Upcoming = 'UPCOMING',
}
