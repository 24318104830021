import React, { useCallback, useEffect, useState } from 'react'
import LoadingArea from 'components/elements/LoadingArea'
import { RouteComponentProps, useHistory } from 'react-router-dom'
import { trackPromise } from 'react-promise-tracker'
import { ApiError } from 'services/ApiService'
import Box from 'components/elements/Box'
import Stack from 'components/elements/Stack'
import ExperimentForm from './ExperimentForm'
import styled from 'styled-components'
import ExperimentationPlatformService from './ExperimentsService'
import { Experiment as ExperimentInterface, UpdateExperiment } from 'interfaces/Experiment'
import CopyIcon from 'components/elements/CopyIcon'
import { formatTimeDifference } from 'utils'
import VariantForm from './VariantForm'
import { MetaBox, EditButton } from 'style'
import { useToggleState } from 'utils/hooks/useToggleState'
import Modal from 'components/modules/Modal'
import GoBackButton from 'components/elements/GoBackButton'
import routes from 'lib/constants/routes'
import {
    formatJiraTicketsInformation,
    generateABTestingExperimentPlatformLink,
    generateMetabaseAIExperimentPlatformLink,
    generateMetabaseCoreExperimentPlatformLink,
    generateMetabaseMonetizationExperimentPlatformLink,
} from 'lib/features/experiments'
import Meta from 'components/modules/Head'
import { toast } from 'react-toastify'
import ExperimentsService from './ExperimentsService'
import Button from 'components/elements/Button'

interface Props extends RouteComponentProps<{ uuid: string }> {}

const loadingAreas = {
    container: 'experimentationContainer',
    duplicate: 'experimentDuplicate',
}

const Container = styled.section`
    padding-bottom: 25px;
    display: grid;
    gap: 10px;
`

const Experiment = (props: Props) => {
    const [experiment, setExperiment] = useState<ExperimentInterface | null>(null)
    const [showEditExperimentModal, toggleEditExperimentModal] = useToggleState(false)
    const history = useHistory()
    const uuid = props.match.params.uuid
    const isNew = !uuid || uuid === 'create'

    const updateExperiment = (experiment: ExperimentInterface) => {
        setExperiment(experiment)
        showEditExperimentModal && toggleEditExperimentModal()
    }

    const fetchData = useCallback(() => {
        if (!uuid || isNew) return

        trackPromise(
            ExperimentationPlatformService.get(uuid)
                .then((experiment) => {
                    const sortedVariants = experiment.variants.sort(
                        (a, b) => +a.identifier.slice(-1) - +b.identifier.slice(-1)
                    )
                    setExperiment({ ...experiment, variants: sortedVariants })
                })
                .catch((error) => {
                    if (error instanceof ApiError) {
                        error.handleUnknown('An error occurred while getting experiments.')
                    } else {
                        throw error
                    }
                }),

            loadingAreas.container
        )
    }, [uuid, isNew])

    useEffect(() => {
        fetchData()
    }, [fetchData])

    const duplicateExperiment = () => {
        if (!experiment) return null

        const { startOn, endOn, status, uuid, identifier, squad, variants, ...rest } = experiment

        const changedVariants = variants.map(({ identifier, ...rest }) => ({
            identifier:
                identifier.substring(0, identifier.indexOf('_variant')) +
                '_duplicate' +
                identifier.substring(identifier.indexOf('_variant'), identifier.length),
            ...rest,
        }))

        const requestBody: UpdateExperiment = {
            startOn: startOn ? new Date(startOn).toISOString() : null,
            endOn: endOn ? new Date(endOn).toISOString() : null,
            identifier: `${identifier}_duplicate`,
            variants: changedVariants,
            squad: squad ?? '',
            ...rest,
        }

        trackPromise(
            ExperimentsService.create(requestBody)
                .then((experiment) => {
                    updateExperiment(experiment)
                    toast.success('The experiment have been duplicated saved!')
                    history.push(routes.experimentRoute(experiment.uuid))
                })
                .catch((error) => {
                    if (error instanceof ApiError) {
                        error.handleUnknown('An error occurred while duplicating experiment.')
                    } else {
                        throw error
                    }
                }),
            loadingAreas.duplicate
        )
    }

    return (
        <Container>
            <Meta title="Experiment" />
            <h2>Experiment</h2>
            <GoBackButton route={routes.experimentsRoute} routeName="Experiments" />
            {!isNew && (
                <Box>
                    <Stack>
                        <MetaBox>
                            <h3>Information</h3>
                            <EditButton onClick={toggleEditExperimentModal} />
                        </MetaBox>
                        <LoadingArea area={loadingAreas.container}>
                            {experiment && (
                                <>
                                    <p>
                                        <strong>Uuid:</strong>&nbsp;
                                        {experiment.uuid}
                                        <CopyIcon text={experiment.uuid} />
                                    </p>
                                    <p>
                                        <strong>Name:</strong>&nbsp;
                                        {experiment.name}
                                    </p>
                                    <p>
                                        <strong>Identifier:</strong>&nbsp;
                                        {experiment.identifier}
                                        <CopyIcon text={experiment.identifier} />
                                    </p>
                                    <p>
                                        <strong>Status:</strong>&nbsp;
                                        {experiment.status}
                                    </p>
                                    <p>
                                        <strong>Description:</strong>&nbsp;
                                        {experiment.description}
                                    </p>
                                    <p>
                                        <strong>Cohort:</strong>&nbsp;
                                        {experiment?.cohortCode ?? 'Everyone'}
                                    </p>
                                    <p>
                                        <strong>Squad:</strong>&nbsp;
                                        {experiment?.squad ?? 'N/A'}
                                    </p>
                                    <p>
                                        <strong>Salt:</strong>&nbsp;
                                        {experiment.salt}
                                    </p>
                                    <p>
                                        <strong>Is cohort exclusive:</strong>&nbsp;
                                        {experiment?.isExclusiveCohort ? 'Yes' : 'No'}
                                    </p>
                                    <p>
                                        <strong>Rollout fraction:</strong>&nbsp;
                                        {experiment.rolloutFraction ?? 'Not specified'}
                                    </p>
                                    {experiment?.minimumAppBuildNumber ? (
                                        <p>
                                            <strong>Minimum App Build Number:</strong>&nbsp;
                                            {experiment.minimumAppBuildNumber}
                                        </p>
                                    ) : null}
                                    {experiment?.startOn ? (
                                        <p>
                                            <strong>Starts on:</strong>&nbsp;
                                            {formatTimeDifference(experiment.startOn)}
                                        </p>
                                    ) : null}
                                    {experiment?.endOn ? (
                                        <p>
                                            <strong>Ends on:</strong>&nbsp;
                                            {formatTimeDifference(experiment.endOn)}
                                        </p>
                                    ) : null}
                                    <h3>Metabase Evaluation</h3>
                                    <a
                                        href={generateMetabaseMonetizationExperimentPlatformLink(experiment)}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        Link to Monetization Metabase Evaluation
                                    </a>
                                    <a
                                        href={generateMetabaseCoreExperimentPlatformLink(experiment)}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        Link to Core Metabase Evaluation
                                    </a>
                                    <a
                                        href={generateMetabaseAIExperimentPlatformLink(experiment)}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        Link to AI Metabase Evaluation
                                    </a>
                                    <h3>AB Testing Platform (deprecated)</h3>
                                    <a
                                        href={generateABTestingExperimentPlatformLink(experiment)}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        Link to AB Testing Tool
                                    </a>

                                    <h3>Jira Tickets</h3>
                                    <Stack>{formatJiraTicketsInformation(experiment.jiraTicketUrl)}</Stack>
                                    <br />
                                    <Button
                                        fullWidth={false}
                                        onClick={duplicateExperiment}
                                        loadingArea={loadingAreas.duplicate}
                                    >
                                        Duplicate this experiment
                                    </Button>
                                </>
                            )}
                        </LoadingArea>
                    </Stack>
                </Box>
            )}
            {isNew ? (
                <ExperimentForm experiment={experiment} isNew={isNew} updateExperiment={updateExperiment} />
            ) : (
                <>{experiment ? <VariantForm variants={experiment.variants} disableAddingNewVariants /> : null}</>
            )}
            <Modal
                show={showEditExperimentModal}
                title="Update Experiment information"
                onClose={toggleEditExperimentModal}
                fullWidth
            >
                <ExperimentForm experiment={experiment} isNew={isNew} updateExperiment={updateExperiment} />
            </Modal>
        </Container>
    )
}

export default Experiment
