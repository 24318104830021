import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { FormHandles, SubmitHandler } from '@unform/core'
import { Form } from '@unform/web'
import { trackPromise } from 'react-promise-tracker'
import { toast } from 'react-toastify'
import { ApiError } from 'services/ApiService'
import { ReactComponent as SaveIcon } from 'assets/icons/save.svg'
import { badRequestFormErrors, isUUID } from 'utils'
import { ErrorTypes } from 'utils/constants/ErrorTypes'
import Button from 'components/elements/Button'
import CountriesService from 'services/CountriesService'
import { Input, SelectMultiple, FormSelect, SelectOption, SelectOptionWithLabel, Checkbox } from 'components/inputs/Input'
import { Grade } from 'interfaces/Grade'
import { SchoolType, SchoolTypeOption } from 'interfaces/SchoolType'
import UserTargetingService from 'services/UserTargetingService'
import SchoolTypeService from 'services/SchoolTypeService'
import { Country, CountryCode, CountryOption } from 'interfaces/Country'
import styled from 'styled-components'
import {
    PersonaUserTargeting,
    UserTargetingOutOfSchoolStatus,
    UserTargetingRequest,
    UserTargetingTypes,
} from 'interfaces/UserTargeting'
import CSVImporter, { CSVData } from 'components/FileUpload/CSVImport'
import { fetchGradesBasedOnSchoolType } from 'lib/features/grades'
import UserTargetingTypeSelect from './UserTargetingTypeSelect'
import theme from 'lib/constants/theme'
import { Body2, ButtonText } from 'components/elements/Text'
import { Region, RegionOption } from 'interfaces/Region'
import { Subject } from 'interfaces/Subject'
import SubjectService from 'pages/Subjects/SubjectService'
import { Tooltip, TooltipText } from 'style'
import { ReactComponent as InfoIcon } from 'assets/icons/info.svg'
import Stack from 'components/elements/Stack'

const SwitchTargetingTypeText = styled(ButtonText)`
    cursor: pointer;
`

const Highlight = styled.strong``

const Joined = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
`

const PersonRow = styled.div`
    display: flex;
    grid-template-columns: 400px auto;
    gap: 10px;
    align-items: center;
`

interface UserTargetingFormProps {
    setTargetingId: (id: number) => void
    userTargetingId?: number | null
    isNew?: boolean
    options?: {
        hideDaysSinceUserCreation?: boolean
    }
}

const loadingAreas = {
    save: 'saveUserTargetingOption',
    options: 'userTargetingOption',
}

interface FormData {
    grades: SelectOption<Grade>[]
    regions: SelectOption<Region>[]
    schoolTypes: SelectOption<SchoolType>[]
    subjects: SelectOption<Subject>[]
    countries: SelectOption<Country>[]
    country: SelectOption<Country> | null
    locationPrefixes: string
    isKnower: SelectOption<boolean | null> | null
    daysSinceUserCreation: string | null
    includeUsersWithoutSchoolType: boolean
    includeUsersWithoutGrade: boolean
    maxLastActiveSinceDaysAgo: number | null
    minLastActiveSinceDaysAgo: number | null
    maxDaysSinceUserCreatedOn: number | null
    minDaysSinceUserCreatedOn: number | null
    outOfSchoolStatus: SelectOption<UserTargetingOutOfSchoolStatus> | null
    persona: SelectOption<PersonaUserTargeting> | null
}

const UserTargetingForm = ({ setTargetingId, userTargetingId, isNew, options }: UserTargetingFormProps) => {
    const [gradesOptions, setGradesOptions] = useState<SelectOptionWithLabel<Grade>[]>([])
    const [schoolTypeOptions, setSchoolTypeOptions] = useState<SchoolTypeOption[]>()
    const [countriesOptions, setCountries] = useState<CountryOption[]>()
    const [subjectsOptions, setSubjectOptions] = useState<SelectOptionWithLabel<Subject>[]>()
    const [regions, setRegions] = useState<RegionOption[]>()
    const [initialData, setInitialData] = useState<Partial<FormData>>()
    const [countryForSelection, setCountryForSelection] = useState<Country>()
    const [selectedUserTargetingType, setUserTargetingType] = useState<UserTargetingTypes | undefined>()
    const [selectedSchoolTypes, setSelectedSchoolTypes] = useState<SchoolType[]>()

    const userTargetingFormRef = useRef<FormHandles>(null)

    const userStatusOptions = useMemo(
        () => [
            {
                label: 'All users (users & knowers)',
                identifier: 'all-users',
                data: null,
            },
            {
                label: 'Only knowers',
                identifier: 'only-knowers',
                data: true,
            },
            {
                label: 'Only users that are not knowers',
                identifier: 'only-users',
                data: false,
            },
        ],
        []
    )

    const outOfSchoolStatusOptions = [
        {
            label: 'Exclude',
            identifier: UserTargetingOutOfSchoolStatus.Exclude,
            data: UserTargetingOutOfSchoolStatus.Exclude,
        },
        {
            label: 'Include',
            identifier: UserTargetingOutOfSchoolStatus.Include,
            data: UserTargetingOutOfSchoolStatus.Include,
        },
    ]

    const personaUserOptions = [
        {
            label: 'No Filter',
            identifier: 'no-filter',
            data: null,
        },
        ...Object.keys(PersonaUserTargeting).map((typeKey) => ({
            // eslint-disable-next-line
            // @ts-ignore
            label: PersonaUserTargeting[typeKey],
            // eslint-disable-next-line
            // @ts-ignore
            identifier: PersonaUserTargeting[typeKey],
            // eslint-disable-next-line
            // @ts-ignore
            data: PersonaUserTargeting[typeKey],
        })),
    ]

    const handleSchoolTypeSelect = (schoolTypes: SchoolType[]) => setSelectedSchoolTypes(schoolTypes)

    useEffect(() => {
        if (!userTargetingId) return

        trackPromise(
            UserTargetingService.get(userTargetingId)
                .then((targeting) => {
                    setUserTargetingType(targeting.type)

                    const userStatusOption = userStatusOptions?.find((o) => o.data === targeting?.isKnower)

                    const data: Partial<FormData> = {
                        grades: targeting.grades.map((g) => ({ data: g, identifier: g.id.toString() })),
                        schoolTypes: targeting.schoolTypes.map((g) => ({ data: g, identifier: g.id.toString() })),
                        regions: targeting.regions.map((g) => ({ data: g, identifier: g.id.toString() })),
                        subjects: targeting?.issueSubjects
                            ? targeting.issueSubjects.map((s) => ({ data: s, identifier: s.id.toString() }))
                            : undefined,
                        country: targeting.countries?.length
                            ? { data: targeting.countries[0], identifier: targeting?.countries[0]?.id?.toString() }
                            : null,
                        countries: targeting.countries.map((g) => ({ data: g, identifier: g.id.toString() })),
                        locationPrefixes: targeting.locationPrefixes.join(','),
                        isKnower: userStatusOption ?? null,
                        daysSinceUserCreation: targeting.daysSinceUserCreation
                            ? targeting.daysSinceUserCreation.toString()
                            : null,
                        includeUsersWithoutGrade: targeting.includeUsersWithoutGrade,
                        persona: targeting.persona
                            ? {
                                  data: targeting.persona,
                                  identifier: targeting.persona,
                              }
                            : null,
                        includeUsersWithoutSchoolType: targeting.includeUsersWithoutSchoolType,
                        maxLastActiveSinceDaysAgo: targeting.maxLastActiveSinceDaysAgo ?? null,
                        minLastActiveSinceDaysAgo: targeting.minLastActiveSinceDaysAgo ?? null,
                        minDaysSinceUserCreatedOn: targeting.minDaysSinceUserCreatedOn ?? null,
                        maxDaysSinceUserCreatedOn: targeting.maxDaysSinceUserCreatedOn ?? null,
                        outOfSchoolStatus: targeting?.outOfSchoolStatus
                            ? { data: targeting.outOfSchoolStatus, identifier: targeting.outOfSchoolStatus }
                            : null,
                    }

                    if (targeting?.schoolTypes) {
                        setSelectedSchoolTypes(targeting.schoolTypes)
                    }

                    setInitialData(data)
                    targeting?.countries?.length && setCountryForSelection(targeting?.countries[0])
                })
                .catch((error: ApiError) => {
                    error.handleUnknown('An error occurred while fetching user targeting.')
                })
        )
    }, [userStatusOptions, userTargetingId])

    const handleGradeOptionsUpdate = (gradeOptions: SelectOptionWithLabel<Grade>[]) => {
        setGradesOptions(gradeOptions)
    }

    useEffect(() => {
        trackPromise(
            CountriesService.list()
                .then((countries) => {
                    const options = countries.map((country) => ({
                        label: country.name,
                        identifier: country.id.toString(),
                        data: country,
                    }))
                    setCountries(options)
                })
                .catch((error: ApiError) => {
                    error.handleUnknown('An error occurred while fetching countries.')
                })
        )

        trackPromise(
            CountriesService.listCountryRegions(1)
                .then((region) => {
                    const options = region.map((region) => ({
                        label: region.name,
                        identifier: region.id.toString(),
                        data: region,
                    }))
                    setRegions(options)
                })
                .catch((error: ApiError) => {
                    error.handleUnknown('An error occurred while fetching federal states.')
                }),
            loadingAreas.options
        )

        SubjectService.list(CountryCode.Germany)
            .then((subjects) => {
                const options = subjects
                    .sort((a, b) => (a.name < b.name ? -1 : 1))
                    .map((s) => ({ label: s.name, identifier: s.id.toString(), data: s }))

                setSubjectOptions(options)
            })
            .catch((error) => {
                if (error instanceof ApiError) {
                    error.handleUnknown('An error occurred while getting subjects.')
                } else {
                    throw error
                }
            })

        trackPromise(
            SchoolTypeService.list(CountryCode.Germany)
                .then((schoolTypes) => {
                    const options = schoolTypes.map((st) => ({
                        label: st.name,
                        identifier: st.id.toString(),
                        data: st,
                    }))

                    setSchoolTypeOptions(options)
                })
                .catch((error) => {
                    if (error instanceof ApiError) {
                        error.handleUnknown('An error occurred while getting school types.')
                    } else {
                        throw error
                    }
                }),
            loadingAreas.options
        )
    }, [])

    useEffect(() => {
        selectedSchoolTypes && fetchGradesBasedOnSchoolType(selectedSchoolTypes, handleGradeOptionsUpdate)
    }, [selectedSchoolTypes])

    const fetchTargetingOptionBasedOnCountry = useCallback((country: Country) => {
        if (country.id) {
            CountriesService.listCountryRegions(country.id)
                .then((countries) => {
                    const options = countries.map((country) => ({
                        label: country.name,
                        identifier: country.id.toString(),
                        data: country,
                    }))
                    setRegions(options)
                })
                .catch((error: ApiError) => {
                    error.handleUnknown(`An error occurred while fetching federal states for ${country.englishName}.`)
                })

            CountriesService.getCountrySchoolTypes(country.id)
                .then((schoolTypes) => {
                    const options = schoolTypes.map((st) => ({
                        label: st.name,
                        identifier: st.id.toString(),
                        data: st,
                    }))
                    setSchoolTypeOptions(options)
                })
                .catch((error: ApiError) => {
                    error.handleUnknown(`An error occurred while fetching school types for ${country.englishName}.`)
                })

            SubjectService.list(country.id)
                .then((subjects) => {
                    const options = subjects
                        .sort((a, b) => (a.name < b.name ? -1 : 1))
                        .map((s) => ({ label: s.name, identifier: s.id.toString(), data: s }))

                    setSubjectOptions(options)
                })
                .catch((error) => {
                    if (error instanceof ApiError) {
                        error.handleUnknown('An error occurred while getting subjects.')
                    } else {
                        throw error
                    }
                })
        }
    }, [])

    const onUpdateCountry = (country: Country) => {
        setCountryForSelection(country)
    }

    useEffect(() => {
        countryForSelection && fetchTargetingOptionBasedOnCountry(countryForSelection)
    }, [countryForSelection, fetchTargetingOptionBasedOnCountry])

    const handleCSVUploadComplete = async (csvData: CSVData[]) => {
        let targetingId = userTargetingId
        const requestData: UserTargetingRequest = {
            gradeIds: null,
            schoolTypeIds: null,
            regionIds: null,
            countryIds: null,
            issueSubjectIds: null,
            locationPrefixes: [],
            isKnower: false,
            type: UserTargetingTypes.UserList,
            daysSinceUserCreation: null,
            minLastActiveSinceDaysAgo: null,
            maxLastActiveSinceDaysAgo: null,
            outOfSchoolStatus: UserTargetingOutOfSchoolStatus.Exclude,
            persona: null,
            maxDaysSinceUserCreatedOn: null,
            minDaysSinceUserCreatedOn: null,
        }
        const handleFunc = targetingId
            ? UserTargetingService.update(targetingId, requestData)
            : UserTargetingService.create(requestData)

        await handleFunc
            .then((userTargeting) => {
                targetingId = userTargeting.id
            })
            .catch((error) => {
                if (error instanceof ApiError) {
                    console.error(error.message)
                } else {
                    throw error
                }
            })

        const progressText = document.getElementById('title')
        let count = 0
        let countSkipped = 0
        const chunkSize = 500
        for (let i = 0; i < csvData.length; i += chunkSize) {
            const data: string[] = []
            const chunk = csvData.slice(i, i + chunkSize)
            // eslint-disable-next-line no-loop-func
            Object.values(chunk).forEach((value) => {
                const uuid = Object.values(value)[0]
                if (isUUID(uuid)) {
                    data.push(uuid)
                    count++
                } else {
                    countSkipped++
                }
            })

            await UserTargetingService.batchUsers(targetingId!, data).catch((error: ApiError) => {
                error.handleUnknown(`An error occurred while adding users to user targeting.`)
            })

            if (progressText) progressText.textContent = `Loading... Progress: ${i}/${csvData.length}`
        }
        const text = `Imported ${count} lines (skipped ${countSkipped} ${countSkipped !== 1 ? 'lines' : 'line'}).`
        setTargetingId(targetingId!)
        if (progressText) progressText.textContent = text
        toast.info(text)
    }

    const handleCreateTargetingSubmit: SubmitHandler<FormData> = (data) => {
        userTargetingFormRef.current!.setErrors({})

        if (!selectedUserTargetingType) return

        const { schoolTypes, regions, grades, countries, locationPrefixes, daysSinceUserCreation } = data

        if (locationPrefixes?.length && locationPrefixes.split(',').some((x) => !/[A-Z]{2}-\d{2}/.test(x))) {
            userTargetingFormRef.current?.setFieldError(
                'locationPrefixes',
                'Location prefixes should follow the format: [country abbreviations]-[location prefix]!'
            )
            return
        }

        const daysSinceUserCreationNumber = daysSinceUserCreation ? Number.parseInt(daysSinceUserCreation) : null
        if (daysSinceUserCreationNumber !== null && daysSinceUserCreationNumber < 1) {
            userTargetingFormRef.current?.setFieldError(
                'daysSinceUserCreation',
                'Please choose a value greater than 0 or keep this field empty.'
            )
            return
        }

        const requestData: UserTargetingRequest = {
            gradeIds: grades?.length ? grades.map((x) => x.data.id) : null,
            schoolTypeIds: schoolTypes?.length ? schoolTypes.map((x) => x.data.id) : null,
            regionIds: regions?.length ? regions.map((x) => x.data.id) : null,
            countryIds: countries?.length ? countries.map((x) => x.data.id) : null,
            locationPrefixes: locationPrefixes ? locationPrefixes.split(',').map((location) => location.trim()) : [],
            isKnower: data?.isKnower?.data ?? null,
            issueSubjectIds: data.subjects.map((s) => s.data.id),
            type: selectedUserTargetingType,
            daysSinceUserCreation: daysSinceUserCreationNumber,
            includeUsersWithoutGrade: data.includeUsersWithoutGrade ?? null,
            includeUsersWithoutSchoolType: data.includeUsersWithoutSchoolType ?? null,
            maxLastActiveSinceDaysAgo: data.maxLastActiveSinceDaysAgo ? +data.maxLastActiveSinceDaysAgo : null,
            minLastActiveSinceDaysAgo: data.minLastActiveSinceDaysAgo ? +data.minLastActiveSinceDaysAgo : null,
            minDaysSinceUserCreatedOn: data.minDaysSinceUserCreatedOn ? +data.minDaysSinceUserCreatedOn : null,
            maxDaysSinceUserCreatedOn: data.maxDaysSinceUserCreatedOn ? +data.maxDaysSinceUserCreatedOn : null,
            outOfSchoolStatus: data?.outOfSchoolStatus?.data ?? null,
            persona: data.persona?.data ?? null,
        }
        let handleFunc
        if (userTargetingId) {
            handleFunc = UserTargetingService.update(userTargetingId, requestData)
        } else {
            handleFunc = UserTargetingService.create(requestData)
        }

        trackPromise(
            handleFunc
                .then((userTargeting) => {
                    setTargetingId(userTargeting.id)
                    toast.success(`User targeting was ${isNew ? 'created' : 'updated'}!`)
                })
                .catch((error) => {
                    if (error instanceof ApiError) {
                        if (error.type === ErrorTypes.FormValidation) {
                            badRequestFormErrors(error, userTargetingFormRef.current!)
                        } else {
                            error.handleUnknown('An error occurred while adding feedback dialog to the user.')
                        }
                    } else {
                        throw error
                    }
                }),
            loadingAreas.save
        )
    }

    const changeUserTargetingType = (type: UserTargetingTypes | undefined) => setUserTargetingType(type)

    const switchUserTargeting = () =>
        setUserTargetingType((targeting) =>
            targeting === UserTargetingTypes.Attributes ? UserTargetingTypes.UserList : UserTargetingTypes.Attributes
        )

    const renderTargetingFormBasedOnType = () => {
        switch (selectedUserTargetingType) {
            case undefined:
                return <UserTargetingTypeSelect changeUserTargetingType={changeUserTargetingType} />
            case UserTargetingTypes.Attributes:
                return (
                    <>
                        <p>
                            Choose the attributes that you want to target users for. Only one attribute has to match for a
                            user to be targeted.
                        </p>
                        <p>
                            <Highlight>Example:</Highlight> If you choose the country “Germany” and grade “5”, then everyone
                            in grade “5” and in Germany and will be targeted.
                        </p>
                        <Form ref={userTargetingFormRef} onSubmit={handleCreateTargetingSubmit} initialData={initialData}>
                            {countriesOptions && (
                                <FormSelect
                                    name="country"
                                    options={countriesOptions}
                                    placeholder="Country (for region/grade/school type selection below)"
                                    onUpdate={(val) => onUpdateCountry(val)}
                                />
                            )}
                            {regions && (
                                <SelectMultiple allowSelectAllOptions name="regions" options={regions} label="Regions" />
                            )}

                            {schoolTypeOptions && (
                                <SelectMultiple
                                    allowSelectAllOptions
                                    name="schoolTypes"
                                    options={schoolTypeOptions}
                                    label="School Types"
                                    onUpdate={handleSchoolTypeSelect}
                                />
                            )}

                            {subjectsOptions && (
                                <SelectMultiple name="subjects" options={subjectsOptions} label="Issue subjects" />
                            )}

                            {gradesOptions && (
                                <SelectMultiple
                                    allowSelectAllOptions
                                    name="grades"
                                    options={gradesOptions}
                                    label="Grades (you need to select school types to check grades options)"
                                />
                            )}
                            {!options?.hideDaysSinceUserCreation ? (
                                <>
                                    <Input
                                        name="daysSinceUserCreation"
                                        type="number"
                                        placeholder="Target users that created their account within the last x days"
                                    />
                                    <Joined>
                                        <Input
                                            name="minLastActiveSinceDaysAgo"
                                            type="number"
                                            placeholder="Target users that were active within minimum the last x days"
                                        />
                                        <Input
                                            name="maxLastActiveSinceDaysAgo"
                                            type="number"
                                            placeholder="Target users that were active within maximum the last x days"
                                        />
                                    </Joined>
                                </>
                            ) : null}
                            <Joined>
                                <Input
                                    name="minDaysSinceUserCreatedOn"
                                    type="number"
                                    placeholder="Target users that created their account within minimum x days"
                                />
                                <Input
                                    name="maxDaysSinceUserCreatedOn"
                                    type="number"
                                    placeholder="Target users that created their account within maximum x days"
                                />
                            </Joined>
                            {countriesOptions && (
                                <SelectMultiple name="countries" options={countriesOptions} label="Countries" />
                            )}
                            <Input
                                name="locationPrefixes"
                                placeholder="Location Prefixes (separated by commas) - Format: [country abbreviations]-[location prefix] i.e. DE-10 (Germany), PL-80 (Poland), FR-23 (France), IT-11 (Italy), UK-45 (Great Britain), AT-22 (Austria) and CH-32 (Switzerland)."
                            />
                            {userStatusOptions && (
                                <FormSelect name="isKnower" options={userStatusOptions} placeholder="User status" />
                            )}
                            {outOfSchoolStatusOptions && (
                                <FormSelect
                                    name="outOfSchoolStatus"
                                    placeholder="Out of school status"
                                    options={outOfSchoolStatusOptions}
                                />
                            )}
                            <PersonRow>
                                <FormSelect name="persona" placeholder="User persona" options={personaUserOptions} />
                                <Tooltip>
                                    <InfoIcon />
                                    <TooltipText>
                                        <Stack>
                                            <Body2 fontFamily="Inter" fontWeigth={400} color={theme.colors.white}>
                                                Content user has had 3 study events (content duration 60s) on 3 different
                                                days AI
                                            </Body2>
                                            <Body2 fontFamily="Inter" fontWeigth={400} color={theme.colors.white}>
                                                User has had 3 AI message send events on 3 different days Zombie user A/B
                                                does
                                            </Body2>
                                            <Body2 fontFamily="Inter" fontWeigth={400} color={theme.colors.white}>
                                                not fall into a content user or an AI user (A and B are the same user type)
                                            </Body2>
                                        </Stack>
                                    </TooltipText>
                                </Tooltip>
                            </PersonRow>
                            <Checkbox name="includeUsersWithoutGrade" placeholder="Include users without selected grades" />
                            <Checkbox
                                name="includeUsersWithoutSchoolType"
                                placeholder="Include users without selected school types"
                            />
                            <Button
                                fullWidth
                                icon={<SaveIcon />}
                                loadingArea={loadingAreas.save}
                                hoverColor={theme.colors.darkBackground}
                            >
                                {`Save user targeting`}
                            </Button>
                        </Form>
                    </>
                )
            case UserTargetingTypes.UserList:
                return (
                    <>
                        {!userTargetingId && (
                            <>
                                <h3 style={{ color: 'var(--links)', fontWeight: 600, textAlign: 'center' }}>
                                    User Targeting will be created automatically after the upload process is finished
                                </h3>
                                <br />
                                <p>
                                    The actual imported targeted user count might be lower than the user count present in
                                    your CSV file because user is targeted if below conditions are met:
                                </p>
                                <ul>
                                    <li>the interface language is matching</li>
                                    <li>{`user has verified her/his email (in case of Manual Emails)`}</li>
                                    <li>{`user is subscribed to the type of email you are sending (in case of Manual Emails)`}</li>
                                </ul>
                            </>
                        )}
                        <Form ref={userTargetingFormRef} onSubmit={handleCreateTargetingSubmit} initialData={initialData}>
                            <br />

                            <CSVImporter
                                title="CSV Upload area for user UUIDs. On upload users would be appended (not replaced)"
                                handleCSVUploadComplete={handleCSVUploadComplete}
                            />
                        </Form>
                    </>
                )
        }
    }
    return (
        <div>
            {renderTargetingFormBasedOnType()}
            {selectedUserTargetingType && (
                <>
                    <br />
                    <SwitchTargetingTypeText
                        onClick={switchUserTargeting}
                        color={theme.colors.knowunityBlue}
                        textAlign="center"
                    >
                        {`Switch User Targeting Type to ${
                            selectedUserTargetingType === UserTargetingTypes.Attributes ? 'User List' : 'Attributes'
                        }`}
                    </SwitchTargetingTypeText>
                </>
            )}
        </div>
    )
}

export default UserTargetingForm
