import React, { useCallback, useEffect, useState } from 'react'
import LoadingArea from 'components/elements/LoadingArea'
import { RouteComponentProps, useHistory } from 'react-router-dom'
import { trackPromise } from 'react-promise-tracker'
import { ApiError } from 'services/ApiService'
import Box from 'components/elements/Box'
import Stack from 'components/elements/Stack'
import GoBackButton from 'components/elements/GoBackButton'
import routes from 'lib/constants/routes'
import CampaignService from './CampaignService'
import CampaignForm from './CampaignForm'
import CopyIcon from 'components/elements/CopyIcon'
import Meta from 'components/modules/Head'
import { Campaign, CampaignRequest } from 'interfaces/Campaings'
import Button from 'components/elements/Button'
import { toast } from 'react-toastify'

interface Props extends RouteComponentProps<{ uuid: string }> {}

const loadingAreas = {
    container: 'competitionContainer',
    duplicate: 'competitionDuplicate',
}

const CampaignComponent = (props: Props) => {
    const [campaign, setCampaign] = useState<Campaign | null>(null)
    const history = useHistory()
    const campaignUuid = props.match.params.uuid
    const isNew = !campaignUuid

    const updateCampaign = (campaign: Campaign) => setCampaign(campaign)

    const fetchData = useCallback(() => {
        if (!campaignUuid) return

        trackPromise(
            CampaignService.get(campaignUuid)
                .then((campaign) => {
                    setCampaign(campaign)
                })
                .catch((error: ApiError) => {
                    error.handleUnknown('An error occurred while getting campaign.')
                }),
            loadingAreas.container
        )
    }, [campaignUuid])

    const duplicateExperiment = () => {
        if (!campaign) return null

        const { isActive, uuid, ...rest } = campaign

        const requestBody: CampaignRequest = {
            isActive: false,
            ...rest,
        }

        trackPromise(
            CampaignService.create(requestBody)
                .then((camp) => {
                    updateCampaign(camp)
                    toast.success('The experiment have been duplicated saved!')
                    history.push(routes.campaignRoute(camp.uuid))
                })
                .catch((error) => {
                    if (error instanceof ApiError) {
                        error.handleUnknown('An error occurred while duplicating experiment.')
                    } else {
                        throw error
                    }
                }),
            loadingAreas.duplicate
        )
    }

    useEffect(() => {
        fetchData()
    }, [fetchData])

    return (
        <section>
            <Meta title="Campaign" />
            <h2>Campaign</h2>
            <GoBackButton route={routes.campaingsRoute} routeName="Campaigns" />
            {!isNew && (
                <Box>
                    <Stack>
                        <h3>Information</h3>
                        <LoadingArea area={loadingAreas.container}>
                            {campaign && (
                                <>
                                    <p>
                                        <strong>Uuid:</strong>&nbsp;
                                        {campaign.uuid}
                                        <CopyIcon text={campaign.uuid} />
                                    </p>
                                    <p>
                                        <strong>Name:</strong>&nbsp;
                                        {campaign.name}
                                    </p>
                                    <p>
                                        <strong>Description:</strong>&nbsp;
                                        {campaign.description}
                                    </p>
                                    <p>
                                        <strong>Trigger:</strong>&nbsp;
                                        {campaign.trigger}
                                    </p>
                                    <p>
                                        <strong>Triggering frequency cap:</strong>&nbsp;
                                        {campaign.triggeringFrequencyCapInDays
                                            ? `${campaign.triggeringFrequencyCapInDays} ${
                                                  campaign.triggeringFrequencyCapInDays === 1 ? 'day' : 'days'
                                              }`
                                            : 'none'}
                                    </p>
                                    <p>
                                        <strong>Is Active:</strong>&nbsp;
                                        {campaign.isActive ? 'yes' : 'no'}
                                    </p>
                                    <p>
                                        <strong>Experiment Variant Identifier:</strong>&nbsp;
                                        {campaign.experimentVariantIdentifier}
                                    </p>
                                    <br />
                                    <Button
                                        fullWidth={false}
                                        onClick={duplicateExperiment}
                                        loadingArea={loadingAreas.duplicate}
                                    >
                                        Duplicate this experiment
                                    </Button>
                                </>
                            )}
                        </LoadingArea>
                    </Stack>
                </Box>
            )}
            <CampaignForm campaignUuid={campaignUuid} isNew={isNew} campaign={campaign} updateCampaign={updateCampaign} />
        </section>
    )
}

export default CampaignComponent
