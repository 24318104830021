import React from 'react'

import { RouteComponentProps, withRouter } from 'react-router-dom'
import { useKnowPage } from './useKnowPage'
import { Container, Grid } from './Know.styles'
import LoadingArea from 'components/elements/LoadingArea'
import { KnowInformationSection } from './KnowInformationSection'
import { ActionsMenuSection } from './ActionsMenuSection'
import { KnowDemandSection } from './KnowDemandSection'
import { KnowContentSection } from './KnowContentSection'
import { ReviewHistorySection } from './ReviewHistorySection'
import UserAdminNotes from 'components/features/users/UserAdminNotes'
import { loadingAreas } from 'lib/constants/LoadingAreas'
import Meta from 'components/modules/Head'

interface KnowProps extends RouteComponentProps<{ uuid: string }> {}

const Know = (KnowProps: KnowProps) => {
    const {
        fetchData,
        flashCards,
        isKnowInReview,
        know,
        knowUuid,
        onSuccessSubmitKnowEdit,
        showEditKnowModal,
        toggleEditKnowModal,
        updateKnow,
        updateFlashcards,
        submitFlashcardsUpdate,
    } = useKnowPage(KnowProps.match.params.uuid)

    if (!know) return <></>

    return (
        <Container>
            <Meta title={`Know ${know?.title ?? ''} `} />
            <div className="wrapper">
                <Grid>
                    <div>
                        <LoadingArea area={loadingAreas.save}>
                            <KnowInformationSection
                                isKnowInReview={isKnowInReview}
                                know={know}
                                showKnowEditMode={showEditKnowModal}
                                toggleKnowEditMode={toggleEditKnowModal}
                                onSuccessSubmitKnowEdit={onSuccessSubmitKnowEdit}
                                updateKnow={updateKnow}
                            />
                        </LoadingArea>
                    </div>
                    <div>
                        <KnowContentSection
                            know={know}
                            flashCards={flashCards}
                            fetchData={fetchData}
                            updateFlashcards={updateFlashcards}
                            submitFlashcardsUpdate={submitFlashcardsUpdate}
                        />
                        <KnowDemandSection knowUuid={knowUuid} />
                    </div>
                    <div>
                        <ActionsMenuSection
                            know={know}
                            updateKnow={updateKnow}
                            isKnowInReview={isKnowInReview}
                            showEditKnowModal={showEditKnowModal}
                        />
                    </div>
                </Grid>
                <UserAdminNotes user={know.knower.user} />
                <ReviewHistorySection knowUuid={knowUuid} loadingAreaHistory={loadingAreas.history} />
            </div>
        </Container>
    )
}

export default withRouter(Know)
