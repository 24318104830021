import React from 'react'
import { Experiment } from 'interfaces/Experiment'

// Identifier must consist of only lower case letters (a-z), numbers (0-9) and underscores (_)
export const isIdentifierInvalid = (identifier: string) => /[^a-z0-9_]/g.test(identifier)

export const getVariantIdentifier = (experimentIdentifier: string, variantsCount: number) =>
    `${experimentIdentifier}_variant_${variantsCount}`

export const validateAndReturnCorrectRecordType = (value: string): string | number | boolean | string[] | number[] => {
    switch (true) {
        case value === 'true':
        case value === 'false':
            return JSON.parse(value)
        case !isNaN(+value):
            return JSON.parse(value)
        case value.split(',').length > 1:
            return value
                .split(',')
                .filter((x) => !!x)
                .map((x) => x.trim())
                .map((x) => (!isNaN(+x) ? JSON.parse(x) : x))
        default:
            return value
    }
}

export const formatJiraTicketsInformation = (jiraTickets: string) =>
    jiraTickets.split(',').map((ticketUrl, i) => (
        <p key={i}>
            •{' '}
            <a href={ticketUrl.trim()} target="_blank" rel="noreferrer">
                {ticketUrl.trim()}
            </a>
        </p>
    ))

export const generateABTestingExperimentPlatformLink = (experiment: Experiment) =>
    `https://ab-testing-web-app.knowunity.com/?filter_by_other_columns=False&alpha=95.0&metric_type=Conversion${
        experiment?.endOn ? `&end_date=${experiment.endOn.slice(0, 10)}` : ''
    }&add_event_filters=False&new_users_only=False${
        experiment?.minimumAppBuildNumber
            ? `&app_filter=app_build_number&app_build_number=${experiment.minimumAppBuildNumber}`
            : ''
    }&group_distinctively=True${
        experiment?.variants?.length > 0
            ? experiment.variants.map((variant) => `&experiment_selection=${variant.identifier}`).join('')
            : ''
    }&grouping_id_column=user_uuid&target_events=premium_purchase${
        experiment?.startOn ? `&start_date=${experiment.startOn.slice(0, 10)}` : ''
    }&start_events=screen_view`

export const generateMetabaseAIExperimentPlatformLink = (experiment: Experiment) =>
    `https://metabase.knowunity.de/dashboard/633-ai-buddy-evaluations-dashboard-p-value?tab=199-👥-dau-added
    ${experiment?.startOn ? `&start_date=${experiment.startOn.slice(0, 10)}` : ''}${
        experiment?.endOn ? `&end_date=${experiment.endOn.slice(0, 10)}` : ''
    }${experiment?.minimumAppBuildNumber ? `&app_build_number=${experiment.minimumAppBuildNumber}` : ''}${
        experiment?.variants?.length > 0
            ? experiment.variants.map((variant, i) => `&${`var_${i}_name`}=${variant.identifier}`).join('')
            : ''
    }${
        experiment?.variants?.length > 0
            ? `&test_baseline=${experiment.variants[0].identifier}&baseline_name=${experiment.variants[0].identifier}`
            : ''
    }`

export const generateMetabaseCoreExperimentPlatformLink = (experiment: Experiment) =>
    `https://metabase.knowunity.de/dashboard/659-core-evaluation-dashboard?tab=71-overall
        ${experiment?.startOn ? `&start_date=${experiment.startOn.slice(0, 10)}` : ''}${
        experiment?.endOn ? `&end_date=${experiment.endOn.slice(0, 10)}` : ''
    }${experiment?.minimumAppBuildNumber ? `&app_build_number=${experiment.minimumAppBuildNumber}` : ''}${
        experiment?.variants?.length > 0
            ? experiment.variants.map((variant, i) => `&${`var${i}_name`}=${variant.identifier}`).join('')
            : ''
    }${
        experiment?.variants?.length > 0
            ? `&test_baseline=${experiment.variants[0].identifier}&baseline_name=${experiment.variants[0].identifier}`
            : ''
    }`

export const generateMetabaseMonetizationExperimentPlatformLink = (experiment: Experiment) =>
    `https://metabase.knowunity.de/dashboard/724-monetisation-conversion-experiment-overview?tab=208-overview
${experiment?.startOn ? `&start_date=${experiment.startOn.slice(0, 10)}` : ''}${
        experiment?.endOn ? `&end_date=${experiment.endOn.slice(0, 10)}` : ''
    }${experiment?.minimumAppBuildNumber ? `&app_build_number=${experiment.minimumAppBuildNumber}` : ''}${
        experiment?.variants?.length > 0
            ? experiment.variants.map((variant, i) => `&${`name_v${i}`}=${variant.identifier}`).join('')
            : ''
    }${experiment?.variants?.length > 0 ? `&var_0=${experiment.variants[0].identifier}` : ''}`
