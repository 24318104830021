import React, { useRef } from 'react'
import { FormHandles, SubmitHandler } from '@unform/core'
import { Form } from '@unform/web'
import Button from 'components/elements/Button'
import { ReactComponent as AddIcon } from 'assets/icons/add.svg'
import { ReactComponent as SaveIcon } from 'assets/icons/save.svg'
import { ButtonText } from 'components/elements/Text'
import { Input } from 'components/inputs/Input'
import Modal from 'components/modules/Modal'
import theme from 'lib/constants/theme'
import { toast } from 'react-toastify'
import { ApiError } from 'services/ApiService'
import CampaignService from './CampaignService'
import { useToggleState } from 'utils/hooks/useToggleState'
import { CampaignStep, CampaignStepType } from 'interfaces/Campaings'
import styled from 'styled-components'

const Container = styled.div`
    form button {
        height: auto !important;
    }
`

interface Props {
    updateCampaignStep: (step: CampaignStep) => void
    campaignUuid: string
}

const CampaignImportForm = ({ updateCampaignStep, campaignUuid }: Props) => {
    const [showImportStepForm, toggleImportStepForm] = useToggleState(false)
    const importStepFormRef = useRef<FormHandles>(null)

    const handleImportStepFormSubmit: SubmitHandler<{ uuidToImport: string }> = ({ uuidToImport }) => {
        if (!uuidToImport) {
            importStepFormRef.current?.setFieldError('uuidToImport', 'This field is required')
            return
        }

        CampaignService.getCampaignStep(campaignUuid, uuidToImport)
            .then((step) => {
                const stepWithoutUuid = step
                switch (step.type) {
                    case CampaignStepType.Delay:
                        delete step.delay?.uuid
                        break
                    case CampaignStepType.PushNotification:
                        delete step.pushNotification?.uuid
                        break
                    case CampaignStepType.Email:
                        delete step.email?.uuid
                        break
                    default:
                        break
                }

                updateCampaignStep(stepWithoutUuid)
                toast.success('Step was imported')
                toggleImportStepForm()
            })
            .catch((error) => {
                if (error instanceof ApiError) {
                    error.handleUnknown(`Step with this uuid doesn't exist`)
                    return
                } else {
                    throw error
                }
            })
    }

    return (
        <Container>
            <Button fullWidth={false} onClick={toggleImportStepForm} icon={<AddIcon />} type="button">
                <ButtonText color={theme.colors.white}>Import a step</ButtonText>
            </Button>

            <Modal show={showImportStepForm} title={'Import a step'} onClose={toggleImportStepForm}>
                <Form ref={importStepFormRef} onSubmit={handleImportStepFormSubmit}>
                    <Input name="uuidToImport" placeholder="Uuid of the step to import" />

                    <Button
                        fullWidth
                        icon={<SaveIcon />}
                        type="button"
                        onClick={() => importStepFormRef.current?.submitForm()}
                    >
                        Save
                    </Button>
                </Form>
            </Modal>
        </Container>
    )
}

export default CampaignImportForm
