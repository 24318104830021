import { AutoKnowDemand, KnowDemandsStatus } from 'interfaces/AutoKnowDemand'
import ApiService from 'services/ApiService'

const AutoKnowDemandsService = {
    list(
        page: number,
        limit?: number,
        status?: KnowDemandsStatus | null,
        gradeId?: number | null,
        searchTerm?: string,
        countryId?: number | null,
        maxFillRate?: number | null,
        resultsCount?: number | null,
        sortBy?: string | null
    ) {
        return ApiService.get(
            `/search-terms?page=${page}${limit ? `&limit=${limit}` : ''}${status ? `&status=${status}` : ''}${
                gradeId ? `&grade=${gradeId}` : ''
            }${searchTerm ? `&term=${searchTerm}` : ''}${countryId ? `&country=${countryId}` : ''}${
                maxFillRate ? `&maxFillRate=${maxFillRate}` : ''
            }${resultsCount ? `&maximumResultsCount=${resultsCount}` : ''}${sortBy ? `&orderBy=${sortBy}` : ''}`
        ).then((response) => {
            return {
                terms: response.data.content as AutoKnowDemand[],
                page: response.data.number as number,
                totalPages: response.data.totalPages as number,
                totalElements: response.data.totalElements as number,
            }
        })
    },

    updateId(termId: string, knowDemandUUID: string) {
        return ApiService.patch(`/search-terms/${termId}/know-demand`, { knowDemandUUID }).then((response) => {
            return response.data
        })
    },

    updateStatus(id: number, status: KnowDemandsStatus) {
        return ApiService.patch(`/search-terms/${id}/status`, { status }).then((response) => {
            return response.data
        })
    },
}

export default AutoKnowDemandsService
