/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useCallback, useEffect, useRef, useState } from 'react'
import Button from 'components/elements/Button'
import { ApiError } from 'services/ApiService'
import KnowDemandService from 'services/KnowDemandService'
import { trackPromise } from 'react-promise-tracker'
import { Country } from 'interfaces/Country'
import CSVImporter, { CSVData } from 'components/FileUpload/CSVImport'
import { toast } from 'react-toastify'
import { ErrorTypes } from 'utils/constants/ErrorTypes'
import { ReactComponent as SaveIcon } from 'assets/icons/save.svg'
import Modal from 'components/modules/Modal'
import { Grade, GradeOption } from 'interfaces/Grade'
import { KnowType } from 'interfaces/Know'
import { SchoolType } from 'interfaces/SchoolType'
import { Subject } from 'interfaces/Subject'
import { countryToLanguageCode } from 'lib/country'
import SubjectService from 'pages/Subjects/SubjectService'
import KnowTypeService from 'services/KnowTypeService'
import SchoolTypeService from 'services/SchoolTypeService'
import CountriesService from 'services/CountriesService'
import { FormSelect } from 'components/inputs/Input'
import { Form } from '@unform/web'
import { FormHandles } from '@unform/core'
import { CreateKnowDemand, KnowDemandFormat } from 'interfaces/KnowDemand'
import { HeaderLabels, STANDARD_HEADERS, processCSVData } from 'lib/features/knowdemands'
import { fetchGradesBasedOnSchoolType } from 'lib/features/grades'

const loadingAreas = {
    save: 'knowDemandsBatchSave',
    form: 'knowDemandsForm',
}

interface Props {
    show: boolean
    onClose(): void
    onSuccess?: () => void
}

const KnowDemandsBatchUploadModal = ({ show, onClose, onSuccess }: Props) => {
    const [batchData, setBatchData] = useState<CreateKnowDemand[]>()
    const [country, setCountry] = useState<Country | null>(null)
    const [countryOptions, setCountryOptions] = useState<{ label: string; identifier: string; data: Country }[]>([])
    const [knowTypeOptions, setKnowTypeOptions] = useState<KnowType[]>([])
    const [gradeOptions, setGradeOptions] = useState<Grade[]>([])
    const [subjectOptions, setSubjectOptions] = useState<Subject[]>([])
    const [schoolTypeOptions, setSchoolTypeOptions] = useState<SchoolType[]>([])

    const formRef = useRef<FormHandles>(null)

    const handleClose = () => {
        onClose()
    }

    const handleGradeOptionsUpdate = (gradeOptions: GradeOption[]) =>
        setGradeOptions(gradeOptions.map((option) => option.data))

    const fetchCountry = useCallback(() => {
        trackPromise(
            CountriesService.list()
                .then((countries) => {
                    if (countries) {
                        setCountry(countries[0])
                        setCountryOptions(countries.map((c) => ({ label: c.name, identifier: c.id.toString(), data: c })))
                    }
                })
                .catch((error) => {
                    if (error instanceof ApiError) {
                        error.handleUnknown('Unable to list countries.')
                        return
                    } else {
                        throw error
                    }
                }),
            loadingAreas.form
        )
    }, [])

    useEffect(() => {
        fetchCountry()
    }, [fetchCountry])

    useEffect(() => {
        if (!country) return

        trackPromise(
            SubjectService.list(country.id)
                .then((subjects) => {
                    setSubjectOptions(subjects)
                })
                .catch((error: ApiError) => {
                    error.handleUnknown('An error occurred while getting subjects.')
                }),
            loadingAreas.form
        )

        const languageCode = countryToLanguageCode(country.code)
        if (!languageCode) {
            return
        }

        trackPromise(
            KnowTypeService.list(languageCode)
                .then((types) => {
                    setKnowTypeOptions(types)
                })
                .catch((error: ApiError) => {
                    error.handleUnknown('An error occurred while getting content types.')
                }),
            loadingAreas.form
        )

        trackPromise(
            SchoolTypeService.list(country.id)
                .then((types) => {
                    setSchoolTypeOptions(types)
                    fetchGradesBasedOnSchoolType(types, handleGradeOptionsUpdate)
                })
                .catch((error: ApiError) => {
                    error.handleUnknown('An error occurred while getting content types.')
                }),
            loadingAreas.form
        )
    }, [country])

    const handleUpdateCountry = useCallback((data: Country) => {
        setCountry(data)
    }, [])

    const handleSubmit = () => {
        if (batchData?.length) {
            trackPromise(
                KnowDemandService.createBatch(batchData)
                    .then(() => {
                        onSuccess && onSuccess()
                        toast.success('Know demands have been created!')
                        onClose()
                    })
                    .catch((error: ApiError) => {
                        if (error.type === ErrorTypes.FormValidation) {
                            toast.error('Values are not matching. Make sure you selected the correct targeted country!')
                        }
                        if (error.type === ErrorTypes.NotFound) {
                            toast.error("Values in your file were not valid. Make sure there aren't any typos!")
                        } else {
                            error.handleUnknown('An error occurred while saving know demand.')
                        }
                    }),
                loadingAreas.save
            )
        } else {
            toast.warn('Upload your data before saving! No data found yet')
        }
    }

    const handleCSVUploadComplete = (cvsData: CSVData[]) => {
        if (!(gradeOptions && schoolTypeOptions && knowTypeOptions && subjectOptions && country)) {
            toast.error('Please select a target country')
            return
        }

        const headers = Object.keys(cvsData[0])
        const headerLabels = Object.values(HeaderLabels)

        if (headers.length !== STANDARD_HEADERS.length) {
            toast.warn('Make sure to put correct headers (Subject, Title, ...) as the first row of the CSV file!')
            return
        }

        for (let i = 0; i < headerLabels.length; i++) {
            if (headers[i] !== headerLabels[i]) {
                toast.warn(`${headers[i]} is not part of the standard headers (Subject, Title, ...), check for typos!`)
                return
            }
        }

        const formattedBatchArray = processCSVData(
            cvsData,
            subjectOptions,
            knowTypeOptions,
            gradeOptions,
            schoolTypeOptions,
            country,
            toast
        )

        if (formattedBatchArray?.length) {
            toast.info(
                `Found ${formattedBatchArray.length} know demands! Remember to press the "Save know demands" button to save the data.`
            )
            setBatchData(formattedBatchArray)
        }
    }

    return (
        <Modal show={show} onClose={handleClose} title="Upload Know Demands Batch">
            <strong>Requirements:</strong>
            <ul>
                <li>
                    {`First row of the CSV file must contain correct Column Name: ${Object.values(HeaderLabels).join(
                        ', '
                    )} (in this order)`}
                </li>
                <li>{`All cell data values must be an exact match with values stored in the database. Put "Lycée général" instead of "lycée" (also mind lower and upper case differences)`}</li>
                <li>
                    {`Possible formats are: `}
                    <strong>{Object.values(KnowDemandFormat).join(', ')}</strong>
                </li>
                <li>
                    {`If you want to add payment to a Know Demand the options are:`}
                    <ul>
                        <li>
                            <strong>{`BASED_ON_FOLLOWERS_COUNT`}</strong> to add payout based on following count
                        </li>
                        <li>
                            <strong>{`KNOW_DEMAND_TIER_(number)`}</strong> to add Know Demand Payout Tier
                        </li>
                        <li>{`Leave empty if you don't want to add payout at all`}</li>
                        <li>{`All other options will result in an error`}</li>
                    </ul>
                </li>
                <li>{`Before uploading your file select correct targeted country`}</li>
            </ul>
            <br />
            <strong>Example document:</strong>&nbsp;
            <a href="/files/KnowDemandsBatchUploadDemo.csv" download="example_knowdemands.csv">
                Download
            </a>
            <Form ref={formRef} onSubmit={() => {}}>
                {countryOptions && (
                    <FormSelect
                        name="country"
                        options={countryOptions}
                        placeholder="Select Targeted Country"
                        onUpdate={handleUpdateCountry}
                    />
                )}
            </Form>
            <CSVImporter
                title="Upload area for CSV with know demands"
                handleCSVUploadComplete={handleCSVUploadComplete}
                showHeader
            />
            <Button
                onClick={handleSubmit}
                fullWidth
                icon={<SaveIcon />}
                loadingArea={loadingAreas.save}
                disabled={!batchData}
            >
                Save know demands
            </Button>
        </Modal>
    )
}

export default KnowDemandsBatchUploadModal
