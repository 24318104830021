import React from 'react'
import { FormHandles, SubmitHandler } from '@unform/core'
import { Form } from '@unform/web'
import Button from 'components/elements/Button'
import { Body1, Body2, ButtonText, Headline5 } from 'components/elements/Text'
import { SelectOption } from 'components/inputs/Input'
import Modal from 'components/modules/Modal'
import { useRef, useState } from 'react'
import { useToggleState } from 'utils/hooks/useToggleState'
import { ReactComponent as AddIcon } from 'assets/icons/add.svg'
import { ReactComponent as SaveIcon } from 'assets/icons/save.svg'
import Box from 'components/elements/Box'
import Stack from 'components/elements/Stack'
import theme from 'lib/constants/theme'
import styled from 'styled-components'
import { CampaignStep, CampaignStepType, ContentLanguage } from 'interfaces/Campaings'
import { defaultStepDataPerMarketObject } from 'utils/campaign'
import { toast } from 'react-toastify'
import { EmailCategories } from 'interfaces/ManualEmails'
import { getDefaultEmailBody, handleClickRateHelpersCheck, validateEmail } from 'utils/email'
import EmailEditor, {
    FileUploadCallback,
    DisplayConditionCallback,
    EventCallback,
    Design,
    SaveDesignCallback,
    ExportHtmlCallback,
    MergeTag,
} from 'react-email-editor'
import Tabs from 'components/modules/Tabs'
import InformationCard from 'components/features/InformationCard'
import PushNotificationStepForm from './PushNotificationStepForm'
import DelayStepForm from './DelayStepForm'
import EmailStepForm from './EmailStepForm'
import CampaignStepDisplay from './CampaignStepDisplay'
import Row from 'components/elements/Row'
import CampaignImportForm from './CampaignImportForm'

type EmailEditorProps = {
    registerCallback(type: 'image', callback: FileUploadCallback): void
    registerCallback(type: 'displayCondition', callback: DisplayConditionCallback): void
    addEventListener(type: string, callback: EventCallback): void
    loadDesign(design: Design): void
    saveDesign(callback: SaveDesignCallback): void
    exportHtml(callback: ExportHtmlCallback): void
    setMergeTags(mergeTags: ReadonlyArray<MergeTag>): void
}

const loadingAreas = {
    save: 'sendEmail',
}

const Container = styled.div`
    .buttons {
        display: flex;
        gap: 10px;
    }
    .notification-data {
        input {
            width: 450px;
        }
        justify-content: space-between;
        display: flex;
        flex-wrap: wrap;
    }
`

const ButtonRow = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    button {
        height: 30px;
    }
`

type StepFormData = {
    // notification
    clickPath: string
    trackingType: string
    // delay
    delayInMinutes: number
    untilTime: string
    // email
    type: string
    fromEmail: string
    placeholderFillerPrompt: string
    category: SelectOption<EmailCategories>
}

type StepUpdate = CampaignStep & { index: number }

interface Props {
    setCampaignSteps: React.Dispatch<React.SetStateAction<CampaignStep[]>>
    steps: CampaignStep[]
    isNew: boolean
    campaignUuid: string
}

const CampaignStepsForm = ({ steps, setCampaignSteps, isNew, campaignUuid }: Props) => {
    const [selectedSections, setSelectedSections] = useState<CampaignStepType>()
    const [selectedStep, setSelectedStep] = useState<StepUpdate | null>(null)
    const [showStepForm, toggleStepForm] = useToggleState(false)
    const [pushNotificationTitleData, setNotificationTitleData] =
        useState<Partial<typeof defaultStepDataPerMarketObject>>(defaultStepDataPerMarketObject)
    const [pushNotificationBodyData, setPushNotificationBodyData] =
        useState<Partial<typeof defaultStepDataPerMarketObject>>(defaultStepDataPerMarketObject)
    const [pushNotificationThumbnailData, setPushNotificationThumbnailData] =
        useState<Partial<typeof defaultStepDataPerMarketObject>>(defaultStepDataPerMarketObject)
    const [selectedEmailContentLanguage, setSelectedEmailContentLanguage] = useState<ContentLanguage>(ContentLanguage['de'])
    const [emailBodyHtml, setEmailBodyHtml] =
        useState<Partial<typeof defaultStepDataPerMarketObject>>(defaultStepDataPerMarketObject)
    const [emailBodyDesign, setEmailBodyDesign] =
        useState<Partial<typeof defaultStepDataPerMarketObject>>(defaultStepDataPerMarketObject)
    const [emailSubjects, setEmailSubjects] =
        useState<Partial<typeof defaultStepDataPerMarketObject>>(defaultStepDataPerMarketObject)
    const stepFormRef = useRef<FormHandles>(null)
    const emailEditor = useRef<EmailEditorProps>(null)

    const updateCampaignStep = (campaign: CampaignStep) => setCampaignSteps((prevStep) => [...prevStep, campaign])

    const changeSteps = (steps: CampaignStep[]) => setCampaignSteps(steps)

    const handleEditStep = (campaignStep: CampaignStep, index: number) => {
        setSelectedStep({ ...campaignStep, index })

        setSelectedSections(campaignStep.type)
        campaignStep.email?.subject && setEmailSubjects(campaignStep.email.subject)
        campaignStep.email?.bodyHtml && setEmailBodyHtml(campaignStep.email.bodyHtml)
        campaignStep.email?.bodyDesign && setEmailBodyDesign(campaignStep.email.bodyDesign)

        campaignStep.pushNotification?.body && setPushNotificationBodyData(campaignStep.pushNotification.body)
        campaignStep.pushNotification?.title && setNotificationTitleData(campaignStep.pushNotification.title)
        campaignStep.pushNotification?.thumbnailImageFilePath &&
            setPushNotificationThumbnailData(campaignStep.pushNotification.thumbnailImageFilePath)

        handleEmailDesignRerender(selectedEmailContentLanguage)

        toggleStepForm()
    }

    const handleDeleteStep = (indexToDelete: number) => {
        if (!window.confirm(`Are you sure you want to delete this step?`)) return

        setCampaignSteps((steps) => steps.filter((_, i) => i !== indexToDelete))
    }

    const updateNotificationData = (key: 'title' | 'body' | 'thumbnail', language: ContentLanguage, value: string) => {
        const updateFunction =
            key === 'thumbnail'
                ? setPushNotificationThumbnailData
                : key === 'title'
                ? setNotificationTitleData
                : setPushNotificationBodyData

        updateFunction((prevData) => {
            const tempData = { ...prevData }
            tempData[`${language}`] = value
            return tempData
        })
    }

    const getPushNotificationDefaultDataByLanguage = (type: 'title' | 'body' | 'thumbnail', language: ContentLanguage) => {
        const sourceData =
            type === 'thumbnail'
                ? pushNotificationThumbnailData
                : type === 'title'
                ? pushNotificationTitleData
                : pushNotificationBodyData

        return sourceData[language] ?? ''
    }

    const getEmailSubjectsDataByLanguage = (language: ContentLanguage) => emailSubjects[language]

    const updateEmailSubjectData = (language: ContentLanguage, value: string) => {
        setEmailSubjects((prevSubjects) => {
            const tempData = { ...prevSubjects }
            tempData[`${language}`] = value
            return tempData
        })
    }

    const updateEmailStepData = (design: string, html: string, o?: { skipToastMessage: boolean }) => {
        if (!selectedEmailContentLanguage) return

        !o?.skipToastMessage && toast.info('Email body saved')

        setEmailBodyHtml((prevHtml) => {
            const tempHtmlData = { ...prevHtml }
            tempHtmlData[`${selectedEmailContentLanguage}`] = html
            return tempHtmlData
        })

        setEmailBodyDesign((prevBody) => {
            const tempBodyData = { ...prevBody }
            tempBodyData[`${selectedEmailContentLanguage}`] = design
            return tempBodyData
        })
    }

    const handleEmailLanguageSelectionClicked = (language: string) => {
        setSelectedEmailContentLanguage(language as ContentLanguage)

        handleEmailDesignRerender(language)
    }

    const handleEmailDesignRerender = (language: string) => {
        const body = emailBodyDesign[language]

        const bodyDesign = getDefaultEmailBody()

        if (!emailEditor?.current) return

        if (!body) {
            emailEditor.current.loadDesign(bodyDesign)
        } else {
            emailEditor.current.loadDesign(JSON.parse(body))
        }
    }

    const onLoad = () => {
        if (!selectedEmailContentLanguage) return

        handleEmailDesignRerender(selectedEmailContentLanguage)
    }

    const saveDesign = (o?: { skipToastMessage: boolean }) => {
        if (!emailEditor?.current) return

        emailEditor.current.exportHtml(({ html, design }) => {
            handleClickRateHelpersCheck(html)

            updateEmailStepData(JSON.stringify(design), html, { skipToastMessage: o?.skipToastMessage ?? false })
        })
    }

    const handleCampaignStepFormSubmit: SubmitHandler<StepFormData> = (stepFormData) => {
        if (selectedSections === CampaignStepType.Email) {
            saveDesign({ skipToastMessage: true })
        }

        if (!selectedSections) {
            toast.warning('You need to add atleast 1 section to the step!')
            return
        }

        // Push Notification body
        const filteredPushNotificationBodyData = { ...pushNotificationBodyData }
        const filteredPushNotificationTitleData = { ...pushNotificationTitleData }
        const filteredPushNotificationThumbnailData = { ...pushNotificationThumbnailData }
        Object.keys(filteredPushNotificationBodyData).forEach(
            (k) => !filteredPushNotificationBodyData[k] && delete filteredPushNotificationBodyData[k]
        )
        Object.keys(filteredPushNotificationTitleData).forEach(
            (k) => !filteredPushNotificationTitleData[k] && delete filteredPushNotificationTitleData[k]
        )
        Object.keys(filteredPushNotificationThumbnailData).forEach(
            (k) => !filteredPushNotificationThumbnailData[k] && delete filteredPushNotificationThumbnailData[k]
        )

        // Email body
        const filteredEmailDesignBodyData = { ...emailBodyDesign }
        const filteredEmailHTMLBodyData = { ...emailBodyHtml }
        const filteredEmailSubjectData = { ...emailSubjects }
        Object.keys(filteredEmailDesignBodyData).forEach(
            (k) => !filteredEmailDesignBodyData[k] && delete filteredEmailDesignBodyData[k]
        )
        Object.keys(filteredEmailHTMLBodyData).forEach(
            (k) => !filteredEmailHTMLBodyData[k] && delete filteredEmailHTMLBodyData[k]
        )
        Object.keys(filteredEmailSubjectData).forEach(
            (k) => !filteredEmailSubjectData[k] && delete filteredEmailSubjectData[k]
        )

        let step: CampaignStep = {
            type: selectedSections,
            delay: {
                durationInMinutes: 0,
                untilTime: null,
            },
            email: {
                bodyDesign: null,
                bodyHtml: null,
                category: '' as EmailCategories,
                fromEmail: null,
                subject: null,
                trackingType: '',
                placeholderFillerPrompt: '',
            },
            pushNotification: {
                body: null,
                clickPath: '',
                title: null,
                trackingType: '',
                thumbnailImageFilePath: null,
            },
        }

        if (selectedSections.includes(CampaignStepType.PushNotification)) {
            if (Object.keys(filteredPushNotificationTitleData).length === 0) {
                toast.warning('You need to pass atleast 1 copy for notification title')
                return
            }

            if (Object.keys(filteredPushNotificationBodyData).length === 0) {
                toast.warning('You need to pass atleast 1 copy for notification body')
                return
            }

            if (!stepFormData.clickPath) {
                stepFormRef.current!.setFieldError('clickPath', 'This field is required!')
                return
            }

            if (!stepFormData.trackingType) {
                stepFormRef.current!.setFieldError('trackingType', 'This field is required!')
                return
            }

            step = {
                ...step,
                pushNotification: {
                    title: filteredPushNotificationTitleData,
                    body: filteredPushNotificationBodyData,
                    clickPath: stepFormData.clickPath,
                    trackingType: stepFormData.trackingType,
                    uuid: selectedStep?.pushNotification?.uuid ?? undefined,
                    thumbnailImageFilePath: filteredPushNotificationThumbnailData,
                },
            }
        }

        if (selectedSections.includes(CampaignStepType.Delay)) {
            if (!stepFormData.delayInMinutes && !stepFormData.untilTime) {
                toast.warning('You need to fill delay duration or wait until time')
                return
            }

            if (stepFormData.delayInMinutes && stepFormData.untilTime) {
                toast.warning('You can select either delay duration or wait until time. Not both')
                return
            }

            const untilTime = stepFormData?.untilTime ? `0000-01-01T${stepFormData.untilTime}:00Z` : null

            step = {
                ...step,
                delay: {
                    durationInMinutes: stepFormData?.delayInMinutes ? +stepFormData.delayInMinutes : 0,
                    untilTime: untilTime,
                    uuid: selectedStep?.delay?.uuid ?? undefined,
                },
            }
        }

        if (selectedSections.includes(CampaignStepType.Email)) {
            if (!emailBodyHtml) {
                toast.warning('You have to design the email!')
                return
            }

            if (stepFormData.fromEmail && !validateEmail(stepFormData.fromEmail)) {
                stepFormRef.current!.setFieldError('fromEmail', 'Please enter a valid email address.')
                return
            }

            if (stepFormData.fromEmail && !validateEmail(stepFormData.fromEmail)) {
                stepFormRef.current!.setFieldError('subject', 'Please enter a valid email address.')
                return
            }

            step = {
                ...step,
                email: {
                    subject: filteredEmailSubjectData,
                    trackingType: stepFormData.type,
                    fromEmail: stepFormData.fromEmail,
                    category: stepFormData?.category?.data,
                    bodyDesign: filteredEmailDesignBodyData,
                    bodyHtml: filteredEmailHTMLBodyData,
                    uuid: selectedStep?.email?.uuid ?? undefined,
                    placeholderFillerPrompt: stepFormData?.placeholderFillerPrompt,
                },
            }
        }

        if (selectedStep) {
            const { index } = selectedStep

            setCampaignSteps((prevStep) => {
                const tempStep = prevStep
                tempStep[index] = step
                return tempStep
            })
        } else {
            updateCampaignStep(step)
        }

        resetValuesAndCloseModal()
    }

    const resetValuesAndCloseModal = () => {
        setSelectedSections(undefined)
        setNotificationTitleData(defaultStepDataPerMarketObject)
        setPushNotificationBodyData(defaultStepDataPerMarketObject)
        setPushNotificationThumbnailData(defaultStepDataPerMarketObject)
        setEmailBodyHtml(defaultStepDataPerMarketObject)
        setEmailBodyDesign(defaultStepDataPerMarketObject)
        setEmailSubjects(defaultStepDataPerMarketObject)
        setSelectedEmailContentLanguage(ContentLanguage.de)

        setSelectedStep(null)
        toggleStepForm()
    }

    const initialData = selectedStep
        ? {
              clickPath: selectedStep?.pushNotification?.clickPath,
              trackingType: selectedStep?.pushNotification?.trackingType,
              delayInMinutes: selectedStep?.delay?.durationInMinutes ? selectedStep?.delay?.durationInMinutes : undefined,
              untilTime: selectedStep?.delay?.untilTime ? selectedStep.delay.untilTime.slice(11, 16) : undefined,
              placeholderFillerPrompt: selectedStep?.email?.placeholderFillerPrompt ?? '',
              type: selectedStep?.email?.trackingType,
              fromEmail: selectedStep?.email?.fromEmail,
              category: {
                  data: selectedStep?.email?.category,
                  identifier: selectedStep?.email?.category,
              },
          }
        : undefined

    const addSectionToSteps = (step: CampaignStepType) => {
        setSelectedSections(step)
        setNotificationTitleData(defaultStepDataPerMarketObject)
        setPushNotificationBodyData(defaultStepDataPerMarketObject)
        setPushNotificationThumbnailData(defaultStepDataPerMarketObject)
        setEmailBodyHtml(defaultStepDataPerMarketObject)
        setEmailBodyDesign(defaultStepDataPerMarketObject)
    }

    const isSelectedEmailBodyFilled = (language: ContentLanguage) => {
        if (!emailBodyDesign) return false

        return emailBodyDesign && !!emailBodyDesign[language]
    }

    return (
        <Container>
            <Modal
                fullWidth
                show={showStepForm}
                title={selectedStep ? 'Update Step' : 'Add Step'}
                onClose={resetValuesAndCloseModal}
            >
                <Stack>
                    <Body2 fontWeigth={400}>
                        Hint: you still need to save the campaign after adding Steps on the bottom of the form in order to
                        save the changes
                    </Body2>
                    {!selectedStep ? (
                        <div className="buttons">
                            {Object.entries(CampaignStepType).map(
                                ([k, v]) =>
                                    selectedSections !== v && (
                                        <Button
                                            onClick={() => addSectionToSteps(v)}
                                            fullWidth={false}
                                            key={v}
                                            height={50}
                                            type="button"
                                        >
                                            {`Change to ${k} step`}
                                        </Button>
                                    )
                            )}
                        </div>
                    ) : null}

                    <Form ref={stepFormRef} onSubmit={handleCampaignStepFormSubmit} initialData={initialData}>
                        <Stack gutter={15}>
                            {selectedSections === CampaignStepType.PushNotification ? (
                                <PushNotificationStepForm
                                    updateNotification={updateNotificationData}
                                    getPushNotificationDataByLabel={getPushNotificationDefaultDataByLanguage}
                                />
                            ) : null}
                            {selectedSections === CampaignStepType.Delay ? <DelayStepForm /> : null}
                            {selectedSections === CampaignStepType.Email ? (
                                <InformationCard title={<Body1 color={theme.colors.knowunityBlue}>Email</Body1>}>
                                    <EmailStepForm
                                        updateEmailSubjects={updateEmailSubjectData}
                                        getEmailSubjectsDataByLanguage={getEmailSubjectsDataByLanguage}
                                    />

                                    <Box>
                                        <Stack gutter={10}>
                                            <h3>Email editor</h3>
                                            <p>{`{{name}} will be replaced with the first name of the user, while {{unsubscribe}} will be replaced with a link to unsubscribe from our email`}</p>
                                            <Tabs
                                                onLabelClicked={(language) => handleEmailLanguageSelectionClicked(language)}
                                                deactivateUrlParam
                                            >
                                                {Object.values(ContentLanguage).map((language) => (
                                                    <div
                                                        key={language}
                                                        data-label={language}
                                                        data-title={`${language} ${
                                                            isSelectedEmailBodyFilled(language) ? '✔️' : ''
                                                        }`}
                                                    />
                                                ))}
                                            </Tabs>
                                            <Stack gutter={20}>
                                                <Button
                                                    noMargin
                                                    fullWidth
                                                    icon={<SaveIcon />}
                                                    type="button"
                                                    loadingArea={loadingAreas.save}
                                                    onClick={() => saveDesign()}
                                                >
                                                    {`Save your design for selected language (${selectedEmailContentLanguage})`}
                                                </Button>
                                                <div />
                                            </Stack>
                                        </Stack>
                                        <EmailEditor
                                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                            ref={emailEditor as any}
                                            appearance={{
                                                theme: 'dark',
                                            }}
                                            onReady={onLoad}
                                            minHeight={800}
                                        />
                                    </Box>
                                </InformationCard>
                            ) : null}
                            {!selectedSections ? (
                                <Body1 color={theme.colors.white} fontWeigth={400}>
                                    Please select the type of this step
                                </Body1>
                            ) : null}
                        </Stack>
                        <br />
                        <Button
                            fullWidth
                            icon={<SaveIcon />}
                            type="button"
                            onClick={() => stepFormRef.current?.submitForm()}
                        >
                            Save
                        </Button>
                    </Form>
                </Stack>
            </Modal>

            <Box>
                <Stack>
                    <ButtonRow>
                        <Headline5 color={theme.colors.white} marginBottom={10}>
                            Steps
                        </Headline5>
                        <Row>
                            <CampaignImportForm campaignUuid={campaignUuid} updateCampaignStep={updateCampaignStep} />
                            <Button fullWidth={false} onClick={toggleStepForm} icon={<AddIcon />} type="button">
                                <ButtonText color={theme.colors.white}>Add a step</ButtonText>
                            </Button>
                        </Row>
                    </ButtonRow>

                    <CampaignStepDisplay
                        handleDeleteStep={handleDeleteStep}
                        handleEditStep={handleEditStep}
                        changeSteps={changeSteps}
                        steps={steps}
                        isNew={isNew}
                        campaignUuid={campaignUuid}
                    />
                </Stack>
            </Box>
        </Container>
    )
}

export default CampaignStepsForm
