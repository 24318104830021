import React, { useState } from 'react'
import { Body1 } from 'components/elements/Text'
import { Input } from 'components/inputs/Input'
import theme from 'lib/constants/theme'
import Row from 'components/elements/Row'
import Stack from 'components/elements/Stack'
import InputElement from 'components/inputs/InputElement'
import ModalSelect from 'components/inputs/ModalSelect'
import { ContentLanguage } from 'interfaces/Campaings'
import { EmailCategories } from 'interfaces/ManualEmails'
import { returnCategoryDescription } from 'utils/email'
import Tabs from 'components/modules/Tabs'

interface Props {
    updateEmailSubjects: (language: ContentLanguage, value: string) => void
    getEmailSubjectsDataByLanguage: (language: ContentLanguage) => string | undefined
}

const EmailStepForm = ({ updateEmailSubjects, getEmailSubjectsDataByLanguage }: Props) => {
    const [selectedLanguage, setSelectedLanguage] = useState<ContentLanguage>(ContentLanguage.de)

    const categoriesOptions = Object.values(EmailCategories).map((category) => ({
        label: category,
        identifier: category,
        data: category,
        description: returnCategoryDescription(category),
    }))

    const isSubjectBodyFilled = (language: ContentLanguage) => !!getEmailSubjectsDataByLanguage(language)

    return (
        <>
            <Body1 fontWeigth={400} color={theme.colors.knowunityBlue}>
                Email Subject
            </Body1>
            <br />
            <Tabs onLabelClicked={(language) => setSelectedLanguage(language as ContentLanguage)} deactivateUrlParam>
                {Object.values(ContentLanguage).map((language) => (
                    <div
                        key={language}
                        data-label={language}
                        data-title={`${language} ${isSubjectBodyFilled(language) ? '✔️' : ''}`}
                    />
                ))}
            </Tabs>

            <Stack gutter={0}>
                {Object.keys(ContentLanguage).map((language, i) => (
                    <div key={i} hidden={selectedLanguage !== language}>
                        <Row key={i}>
                            <Body1 color={theme.colors.white} style={{ width: 55 }}>{`${language}:`}</Body1>
                            <InputElement
                                label={''}
                                noMargin
                                defaultValue={getEmailSubjectsDataByLanguage(language as ContentLanguage)}
                                onInput={(value) => updateEmailSubjects(language as ContentLanguage, value)}
                            />
                        </Row>
                    </div>
                ))}
            </Stack>
            <Input name="type" placeholder="Type" tooltip="Used for tracking" />
            <Input name="fromEmail" placeholder="From Email (optional)" />
            <Input name="placeholderFillerPrompt" placeholder="Placeholder filler prompt" />
            {categoriesOptions && <ModalSelect name="category" options={categoriesOptions} label="Category" />}
        </>
    )
}

export default EmailStepForm
