import { CountryCode } from 'interfaces/Country'
import { countryCodeToLiveDomain } from 'lib/country'

const dashboardRoute = '/dashboard'
const discussionForumQuestionRoute = (uuid: string) => `/discussion-forum/questions/${uuid}`
const feedbackDialogsRoute = '/feedback-dialogs'
const feedbackDialogRoute = (uuid: string) => `/feedback-dialogs/${uuid}`
const feedbackDialogCreateRoute = '/feedback-dialogs/create'
const knowsRoute = () => '/knows'
const knowRoute = (uuid: string, o?: { redirectUuids: string[] }) =>
    o?.redirectUuids ? `/knows/${uuid}?redirect_ids=${JSON.stringify(o.redirectUuids)}` : `/knows/${uuid}`
const knowerRoute = (uuid: string) => `/knowers/${uuid}`
const knowDemandsRoute = '/knowdemands'
const knowDemandRoute = (uuid: string) => `/knowdemands/${uuid}`
const knowDemandCreateRoute = '/knowdemands/create'
const loginRoute = '/login'
const reportsRoute = '/reports'
const reportRoute = (uuid: string, o?: { redirectIds: string[] }) =>
    o?.redirectIds ? `/reports/${uuid}?redirect_ids=${JSON.stringify(o.redirectIds)}` : `/reports/${uuid}`
const subjectsRoute = '/subjects'
const subjectsCreateRoute = '/subjects/create'
const subjectRoute = (id: number) => `/subjects/${id}`
const transactionsRoute = '/transactions'
const usersRoute = '/users'
const userRoute = (uuid: string) => `/users/${uuid}`
const userCreateRoute = '/users/create'
const payoutRequestsRoute = '/payout-requests'
const payoutRequestRoute = (uuid: string, o?: { redirectIds: string[] }) =>
    o?.redirectIds
        ? `/payout-requests/${uuid}${o?.redirectIds?.length ? `?redirect_ids=${JSON.stringify(o.redirectIds)}` : ''}`
        : `/payout-requests/${uuid}`
const adsSystemRoute = '/ads-system'
const adsCreateCustomersRoute = '/ads-customers/create'
const adsCustomerRoute = (uuid: string) => `/ads-customers/${uuid}`
const adsCreateCampaignRoute = (customerUuid: string) => `/ads-customers/${customerUuid}/campaigns/create`
const adsCampaignRoute = (customerUuid: string, uuid: string) => `/ads-customers/${customerUuid}/campaigns/${uuid}`
const adsCreateAssetRoute = (customerUuid: string, campaignUuid: string) =>
    `/ads-customers/${customerUuid}/campaigns/${campaignUuid}/assets/create`
const adsAssetRoute = (customerUuid: string, campaignUuid: string, uuid: string) =>
    `/ads-customers/${customerUuid}/campaigns/${campaignUuid}/assets/${uuid}`
const manualPushNotificationsRoute = '/manual-push-notifications'
const manualPushNotificationsCreateRoute = '/manual-push-notifications/create'
const manualPushNotificationRoute = (uuid: string, tab?: string) =>
    `/manual-push-notifications/${uuid}${tab ? `?tab=${tab}` : ''}`
const manualEmailsRoute = '/manual-emails'
const manualEmailCreateRoute = '/manual-emails/create'
const manualEmailRoute = (uuid: string, tab?: string) => `/manual-emails/${uuid}${tab ? `?tab=${tab}` : ''}`
const manualChatMessagesRoute = '/manual-chat-messages'
const manualChatMessageCreateRoute = '/manual-chat-messages/create'
const manualChatMessageRoute = (uuid: string, tab?: string) => `/manual-chat-messages/${uuid}${tab ? `?tab=${tab}` : ''}`
const autoKnowDemandsRoute = '/auto-know-demands'
const knowTypesRoute = '/knowtypes'
const knowTypeRoute = (id: number) => `/knowtypes/${id}`
const schoolTypesRoute = '/schooltypes'
const schoolTypeRoute = (id: number) => `/schooltypes/${id}`
const gradesRoute = '/grades'
const gradeRoute = (id: number) => `/grades/${id}`
const regionsRoute = '/regions'
const regionRoute = (id: number) => `/regions/${id}`
const curriculumAnalysisRoute = '/curriculum-analysis'
const taxonomyImportRoute = '/taxonomy-import'
const taxonomyRoute = '/taxonomy'
const experimentsRoute = '/experiments'
const experimentCreateRoute = '/experiments/create'
const experimentRoute = (uuid: string) => `/experiments/${uuid}`

const seoSubjectsRoute = '/seo-subjects'
const seoSubjectCreateRoute = '/seo-subjects/create'
const seoSubjectRoute = (uuid: string) => `/seo-subjects/${uuid}`
const seoTopicsCreateRoute = (subjectUuid: string) => `/seo-subjects/${subjectUuid}/seo-topics/create`
const seoTopicRoute = (subjectUuid: string, uuid: string) => `/seo-subjects/${subjectUuid}/seo-topics/${uuid}`
const seoSubTopicsCreateRoute = (subjectUuid: string, subtopicUuid: string) =>
    `/seo-subjects/${subjectUuid}/seo-topics/${subtopicUuid}/seo-subtopics/create`
const seoSubTopicRoute = (subjectUuid: string, subtopicUuid: string, uuid: string) =>
    `/seo-subjects/${subjectUuid}/seo-topics/${subtopicUuid}/seo-subtopics/${uuid}`

const seoSubjectLiveRoute = (countryCode: CountryCode, subjectSlug: string) =>
    `https://${countryCodeToLiveDomain(countryCode)}/subjects/${subjectSlug}`
const seoTopicLiveRoute = (countryCode: CountryCode, subjectSlug: string, topicSlug: string) =>
    `https://${countryCodeToLiveDomain(countryCode)}/subjects/${subjectSlug}/${topicSlug}`
const seoSubtopicLiveRoute = (countryCode: CountryCode, subjectSlug: string, topicSlug: string, subtopicSlug: string) =>
    `https://${countryCodeToLiveDomain(countryCode)}/subjects/${subjectSlug}/${topicSlug}/${subtopicSlug}`

const competitionsRoute = '/competitions'
const competitionRoute = (uuid: string) => `/competitions/${uuid}`
const competitionCreateRoute = '/competitions/create'

const campaingsRoute = '/campaigns'
const campaignRoute = (uuid: string) => `/campaigns/${uuid}`
const campaignCreateRoute = '/campaigns/create'

const knowerBonusPayments = '/knower-bonus-payments'

const moderationKeywordsRoute = '/moderation-keywords'

const careerPartnersRoute = '/careers-partners'
const careerPartnersCreateRoute = '/careers-partners/create'
const careerPartnerRoute = (uuid: string) => `/careers-partners/${uuid}`

export const generateRoute = (slug: string, query: Record<string, string | boolean | number | undefined>) => {
    const params = Object.entries(query).filter(([_, v]) => !!v)
    const searchParams = new URLSearchParams(params as string[][])
    return `${slug}${params?.length ? `?${searchParams}` : ''}`
}

const schoolsSuggestionsRoute = '/schools-suggestions'
const schoolsSuggestionRoute = (uuid: string, o?: { redirectUuids: string[] }) =>
    o?.redirectUuids
        ? `/schools-suggestions/${uuid}?redirect_ids=${JSON.stringify(o.redirectUuids)}`
        : `/schools-suggestions/${uuid}`
const schoolsSuggestionCreateRoute = '/schools-suggestions/create'

const schoolsRoute = '/schools'
const schoolRoute = (uuid: string) => `/schools/${uuid}`
const schoolCreateRoute = '/schools/create'

const aiChatBotsRoute = `/ai-chat-bots`
const aiChatBotRoute = (uuid: string) => `/ai-chat-bots/${uuid}`

const courseFundraiseRoute = `/course-fundraise`

const routes = {
    dashboardRoute,
    aiChatBotsRoute,
    aiChatBotRoute,
    discussionForumQuestionRoute,
    feedbackDialogsRoute,
    feedbackDialogRoute,
    feedbackDialogCreateRoute,
    knowsRoute,
    knowRoute,
    knowerRoute,
    knowDemandsRoute,
    knowDemandRoute,
    knowDemandCreateRoute,
    loginRoute,
    reportsRoute,
    reportRoute,
    subjectsRoute,
    subjectsCreateRoute,
    subjectRoute,
    transactionsRoute,
    usersRoute,
    userRoute,
    userCreateRoute,
    payoutRequestsRoute,
    payoutRequestRoute,
    adsSystemRoute,
    adsCreateCustomersRoute,
    adsCustomerRoute,
    adsCampaignRoute,
    adsCreateCampaignRoute,
    adsCreateAssetRoute,
    adsAssetRoute,
    manualPushNotificationsRoute,
    manualPushNotificationsCreateRoute,
    manualPushNotificationRoute,
    manualEmailsRoute,
    manualEmailCreateRoute,
    manualEmailRoute,
    manualChatMessagesRoute,
    manualChatMessageCreateRoute,
    manualChatMessageRoute,
    autoKnowDemandsRoute,
    knowTypesRoute,
    knowTypeRoute,
    schoolTypesRoute,
    schoolTypeRoute,
    gradesRoute,
    gradeRoute,
    regionsRoute,
    regionRoute,
    curriculumAnalysisRoute,
    taxonomyImportRoute,
    taxonomyRoute,
    experimentsRoute,
    experimentCreateRoute,
    experimentRoute,
    seoSubjectsRoute,
    seoSubjectCreateRoute,
    seoSubjectRoute,
    seoTopicsCreateRoute,
    seoTopicRoute,
    seoSubTopicRoute,
    seoSubTopicsCreateRoute,
    seoSubjectLiveRoute,
    seoTopicLiveRoute,
    seoSubtopicLiveRoute,
    knowerBonusPayments,
    competitionsRoute,
    competitionRoute,
    competitionCreateRoute,
    moderationKeywordsRoute,
    careerPartnersRoute,
    careerPartnerRoute,
    careerPartnersCreateRoute,
    campaingsRoute,
    campaignRoute,
    campaignCreateRoute,
    schoolsSuggestionsRoute,
    schoolsSuggestionRoute,
    schoolsSuggestionCreateRoute,
    schoolsRoute,
    schoolRoute,
    schoolCreateRoute,
    courseFundraiseRoute,
}

export default routes
