/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useCallback, useRef, useState } from 'react'
import { ButtonLink } from 'components/elements/Button'
import { ReactComponent as AddIcon } from 'assets/icons/add.svg'
import { ApiError } from 'services/ApiService'
import { trackPromise } from 'react-promise-tracker'
import RefactoredTable from 'components/modules/RefactoredTable'
import routes from 'lib/constants/routes'
import { ControlWrapper, FilterFormGrid } from 'style'
import CampaignService from './CampaignService'
import { FormHandles } from '@unform/core'
import { Form } from '@unform/web'
import Search from 'components/elements/Search'
import { FormSelect } from 'components/inputs/Input'

const loadingAreas = {
    container: 'campaignContainer',
}

const Campaigns = () => {
    const [searchQuery, setSearchQuery] = useState<string>()
    const [isActive, setIsActive] = useState<boolean | null>(true)
    const formRef = useRef<FormHandles>(null)

    const activeType = [
        {
            label: 'Show all',
            identifier: 'no-filter',
            data: null,
        },
        {
            label: 'Active campaigns',
            identifier: 'active-campaigns',
            data: true,
        },
        {
            label: `Inactive campaigns`,
            identifier: 'inactive-campaigns',
            data: false,
        },
    ]

    const fetchData = useCallback(
        (page: number) => {
            return trackPromise(
                CampaignService.list(page, isActive, searchQuery)
                    .then((data) => {
                        if (page !== data.page) return

                        return {
                            totalPages: data.totalPages,
                            totalElements: data.totalElements,
                            elements: data.competitions.map((c) => ({
                                id: c.uuid,
                                columns: [c.uuid, c.name, c.trigger, c.isActive ? 'Yes' : 'No'],
                                onClick: routes.campaignRoute(c.uuid),
                            })),
                        }
                    })
                    .catch((error) => {
                        if (error instanceof ApiError) {
                            error.handleUnknown('An error occurred while getting campaign.')
                        } else {
                            throw error
                        }
                    }),
                loadingAreas.container
            )
        },
        [isActive, searchQuery]
    )

    return (
        <section>
            <h2>Campaign System</h2>
            <br />
            <FilterFormGrid gridAmount={3}>
                <ControlWrapper>
                    <ButtonLink to={routes.campaignCreateRoute} fullWidth={false} icon={<AddIcon />}>
                        Create Campaign
                    </ButtonLink>
                </ControlWrapper>
                <Form ref={formRef} onSubmit={() => {}}>
                    <FormSelect
                        placeholder="Filter by Running Campaigns"
                        noMargin
                        options={activeType}
                        onUpdate={(filter) => setIsActive(filter)}
                        name="isActive"
                    />
                </Form>
                <Search onSearch={setSearchQuery} fullWidth label="Search by name" />
            </FilterFormGrid>

            <RefactoredTable
                columns={['#', 'Name', 'Trigger', 'Is Active']}
                loadData={fetchData}
                loadingArea={loadingAreas.container}
                refetchKey={``}
            />
        </section>
    )
}

export default Campaigns
