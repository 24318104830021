import { Knower } from './Knower'

export enum Currency {
    EUR = 'EUR',
    CHF = 'CHF',
    PLN = 'PLN',
    GBP = 'GBP',
    USD = 'USD',
    COP = 'COP',
    TRY = 'TRY',
    MXN = 'MXN',
}

export interface Transaction {
    createdOn: string
    knower: Knower
    amount: number
    type: string
    currency: Currency
}

export interface AdminTransaction extends Transaction {
    description: string | null
    employeeUserUuid: string | null
}

export enum TransactionType {
    Manual = 'MANUAL',
}
