import React, { useCallback, useEffect, useState } from 'react'
import LoadingArea from 'components/elements/LoadingArea'
import { RouteComponentProps } from 'react-router-dom'
import { trackPromise } from 'react-promise-tracker'
import { ApiError } from 'services/ApiService'
import Box from 'components/elements/Box'
import Stack from 'components/elements/Stack'
import styled from 'styled-components'
import { KnowType } from 'interfaces/Know'
import KnowTypeService from 'services/KnowTypeService'
import LanguageService from 'services/LanguageService'
import GoBackButton from 'components/elements/GoBackButton'
import routes from 'lib/constants/routes'
import { SelectOptionWithLabel } from 'components/inputs/Input'
import { LanguageInterface } from 'interfaces/Language'
import Meta from 'components/modules/Head'

interface Props extends RouteComponentProps<{ id: string }> {}

const loadingAreas = {
    container: 'subjectContainer',
}

const Container = styled.section`
    padding-bottom: 25px;
`

const KnowTypesComponent = (props: Props) => {
    const [knowType, setKnowType] = useState<KnowType | null>(null)
    const [languageOptions, setLanguageOptions] = useState<SelectOptionWithLabel<LanguageInterface>[]>()
    const id = props.match.params.id
    const isNew = !id || id === 'create'

    const fetchData = useCallback(() => {
        trackPromise(
            LanguageService.list(true)
                .then((languages) => {
                    const options = languages.map((lang) => ({
                        label: lang.englishName,
                        identifier: lang.id.toString(),
                        data: lang,
                    }))
                    setLanguageOptions(options)
                })
                .catch((error: ApiError) => {
                    error.handleUnknown('An error occurred while getting languages.')
                }),
            loadingAreas.container
        )
        if (id && !isNew) {
            trackPromise(
                KnowTypeService.get(+id)
                    .then((knowType) => {
                        setKnowType(knowType)
                    })
                    .catch((error: ApiError) => {
                        error.handleUnknown('An error occurred while getting know demand.')
                    }),
                loadingAreas.container
            )
        }
    }, [id, isNew])

    useEffect(() => {
        fetchData()
    }, [fetchData])

    return (
        <Container>
            <Meta title="Know Type" />
            <h2>Know Type</h2>
            <GoBackButton route={routes.knowTypesRoute} routeName="Know Type" />
            {!isNew && (
                <Box>
                    <Stack>
                        <h3>Information</h3>
                        <LoadingArea area={loadingAreas.container}>
                            {knowType && (
                                <>
                                    <p>
                                        <strong>Id:</strong>&nbsp;
                                        {knowType.id}
                                    </p>
                                    <p>
                                        <strong>Name:</strong>&nbsp;
                                        {knowType.name}
                                    </p>
                                    <p>
                                        <strong>Language:</strong>&nbsp;
                                        {
                                            languageOptions?.find((language) => language.data.id === knowType.languageId)
                                                ?.label
                                        }
                                    </p>
                                </>
                            )}
                        </LoadingArea>
                    </Stack>
                </Box>
            )}
        </Container>
    )
}

export default KnowTypesComponent
