import { CSVData } from 'components/FileUpload/CSVImport'
import { AutoKnowDemand } from 'interfaces/AutoKnowDemand'
import { Country, CountryCode } from 'interfaces/Country'
import { Grade } from 'interfaces/Grade'
import { KnowType } from 'interfaces/Know'
import { CreateKnowDemand, KnowDemand, KnowDemandFormat, KnowDemandPayoutOptions } from 'interfaces/KnowDemand'
import { LanguageCode } from 'interfaces/Language'
import { SchoolType } from 'interfaces/SchoolType'
import { Subject } from 'interfaces/Subject'

export const KNOW_DEMANDS_EXPIRATION_DAYS_LIMIT = 30

export const STANDARD_HEADERS = [
    'subjectId',
    'title',
    'description',
    'countryId',
    'knowTypeId',
    'gradeIds',
    'schoolTypeId',
    'hasPayout',
    'maxReservationCount',
    'format',
]

export enum HeaderLabels {
    Subject = 'Subject',
    Title = 'Title',
    Description = 'Description',
    Country = 'Country',
    KnowType = 'Know Type',
    Grade = 'Grade',
    SchoolType = 'School Type',
    HasPayout = 'Has Payout',
    MaxReservationCount = 'Max Reservation Count',
    Format = 'Format',
}

export const parseAndValidateSubjectColumn = (subjects: Subject[], cellData: string) =>
    subjects.find((subject) => subject.name === cellData)?.id

export const parseAndValidateKnowTypeColumn = (knowTypes: KnowType[], cellData: string) =>
    knowTypes.find((type) => type.name === cellData)?.id

export const parseAndValidateGradesColumn = (grades: Grade[], cellData: string) => {
    const gradeIds = cellData.split(',').map((grade) => grades.find((g) => g.level === Number(grade.trim()))?.id)
    return gradeIds?.some((x) => !x) ? null : gradeIds
}

export const parseAndValidateSchoolTypeColumn = (schoolTypes: SchoolType[], cellData: string) =>
    schoolTypes.find((type) => type.name === cellData)?.id

export const parseAndValidateKnowFormat = (cellData: string) =>
    cellData?.length && !!(Object.values(KnowDemandFormat) as string[]).includes(cellData as KnowDemandFormat)

const validateKnowDemandPayoutOption = (cellData: string) =>
    Object.values(KnowDemandPayoutOptions).includes(cellData as KnowDemandPayoutOptions)

export const parseAndValidateHasPayoutColumn = (cellData: string) => {
    switch (true) {
        case validateKnowDemandPayoutOption(cellData):
        case cellData === 'BASED_ON_FOLLOWERS_COUNT':
        case cellData === '':
            return true
        default:
            return false
    }
}

export const returnMissingToastMessage = (cellData: string, columnName: string) =>
    `Cell data: (${cellData?.length > 0 ? cellData : 'empty'}) have no mathching values in ${columnName} row`

export const returnMissingPaymentToastMessage = (cellData: string) =>
    `Cell data: (${
        cellData?.length > 0 ? cellData : 'empty'
    }) is wrong. The correct options are: BASED_ON_FOLLOWERS_COUNT, KNOW_DEMAND_TIER_(number) or just leave the cell data empty if you don't want to add payment.`

export const getKnowDemandsDefaultExpirationDateFromToday = () => {
    const date2WeeksInTheFuture = new Date()
    date2WeeksInTheFuture.setDate(date2WeeksInTheFuture.getDate() + KNOW_DEMANDS_EXPIRATION_DAYS_LIMIT)
    return new Date(date2WeeksInTheFuture).toISOString()
}

export const processCSVData = (
    cvsData: CSVData[],
    subjectOptions: Subject[],
    knowTypeOptions: KnowType[],
    gradeOptions: Grade[],
    schoolTypeOptions: SchoolType[],
    country: Country,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    toast: any
) => {
    const array: CreateKnowDemand[] = []
    for (const row of Object.values(cvsData)) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const formattedRow: any = {}

        let i = 0
        for (const [columnName, cellData] of Object.entries(row)) {
            switch (columnName) {
                case HeaderLabels.Subject:
                    if (parseAndValidateSubjectColumn(subjectOptions, cellData)) {
                        formattedRow[STANDARD_HEADERS[i]] = parseAndValidateSubjectColumn(subjectOptions, cellData)
                    } else {
                        toast.error(returnMissingToastMessage(cellData, columnName))
                        return
                    }
                    break
                case HeaderLabels.KnowType:
                    if (parseAndValidateKnowTypeColumn(knowTypeOptions, cellData)) {
                        formattedRow[STANDARD_HEADERS[i]] = parseAndValidateKnowTypeColumn(knowTypeOptions, cellData)
                    } else {
                        toast.error(returnMissingToastMessage(cellData, columnName))
                        return
                    }
                    break
                case HeaderLabels.Country:
                    if (country.id) {
                        formattedRow[STANDARD_HEADERS[i]] = country.id
                    } else {
                        toast.error(returnMissingToastMessage(cellData, columnName))
                        return
                    }
                    break
                case HeaderLabels.Grade:
                    if (parseAndValidateGradesColumn(gradeOptions, cellData)) {
                        formattedRow[STANDARD_HEADERS[i]] = parseAndValidateGradesColumn(gradeOptions, cellData)
                    } else {
                        toast.error(returnMissingToastMessage(cellData, columnName))
                        return
                    }
                    break
                case HeaderLabels.SchoolType:
                    if (parseAndValidateSchoolTypeColumn(schoolTypeOptions, cellData)) {
                        formattedRow[STANDARD_HEADERS[i]] = parseAndValidateSchoolTypeColumn(schoolTypeOptions, cellData)
                    } else {
                        toast.error(returnMissingToastMessage(cellData, columnName))
                        return
                    }
                    break
                case HeaderLabels.HasPayout:
                    if (parseAndValidateHasPayoutColumn(cellData)) {
                        if (validateKnowDemandPayoutOption(cellData)) {
                            //formattedRow[STANDARD_HEADERS[i]] = false
                            formattedRow['payoutTier'] = cellData
                        } else if (cellData === '') {
                            //formattedRow[STANDARD_HEADERS[i]] = false
                        } else {
                            //formattedRow[STANDARD_HEADERS[i]] = true
                        }
                    } else {
                        toast.error(returnMissingPaymentToastMessage(cellData), { autoClose: 10000 })
                        return
                    }

                    break
                case HeaderLabels.Format:
                    if (parseAndValidateKnowFormat(cellData)) {
                        formattedRow[STANDARD_HEADERS[i]] = cellData
                    } else {
                        toast.error(returnMissingToastMessage(cellData, columnName))
                        return
                    }
                    break
                case HeaderLabels.MaxReservationCount:
                    if (cellData?.length > 0 && +cellData > 0) {
                        formattedRow[STANDARD_HEADERS[i]] = +cellData
                    } else {
                        toast.error(returnMissingToastMessage(cellData, columnName))
                        return
                    }
                    break
                default:
                    formattedRow[STANDARD_HEADERS[i]] = cellData.slice(0, 199)
                    break
            }
            i++
        }

        formattedRow['expiresOn'] = getKnowDemandsDefaultExpirationDateFromToday()

        if (Object.values(formattedRow).filter((x) => x).length > 0) {
            array.push(formattedRow)
        }
    }
    return array
}

export const returnDefaultKnowDemandData = (t: AutoKnowDemand) => {
    const knowDemand: KnowDemand = {
        uuid: String(t.id),
        createdOn: '',
        title: t.term,
        description: '',
        subject: {
            id: 0,
            name: '',
            color: '',
            sortId: 0,
            iconUrl: '',
            category: {
                name: '',
                id: 0,
                countryId: 1,
            },
            englishName: '',
            longName: '',
            country: t.country,
            grades: [],
            language: {
                code: LanguageCode.German,
                englishName: 'German',
                id: 1,
                localizedName: 'Deutsch',
                name: 'Deutsch',
            },
            examType: null,
            synonyms: [],
            useForSearchTermFilter: false,
            countryCode: CountryCode.Germany,
        },
        grades: [t.grade],
        schoolType: t.schoolType,
        knowType: {
            id: 0,
            name: '',
            languageId: 0,
        },

        payoutTier: null,
        country: t.country,
        price: null,
        currency: null,
        maxReservationsCount: 1,
        reservationsCount: 1,
        knowDemandReservations: [],
        format: KnowDemandFormat.PDF,
        expiresOn: new Date().toISOString(),
        language: {
            code: LanguageCode.German,
            name: 'Deutsch',
            id: 1,
        },
    }
    return knowDemand
}
